import React, { useState } from "react";
import { Helmet } from "react-helmet";

const CrowdfundingAppDevelopment = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>Effective crowdfunding mobile app solution</title>
        <meta
          name="description"
          content="Transform your ideas with our comprehensive crowdfunding platform solutions. We build robust platforms that connect backers with innovative projects.
"
        />
        <meta
          name="keywords"
          content="best crowdfunding app solutions|
"
        />
        <link
          rel="canonical"
          href="https://www.mannix.in/crowdfunding-app-development-services"
        />

        <meta property="og:locale" content="en_IN" />
        <script type="application/ld+json">
          {`
        "@context": "https://schema.org",
        "@type": "Corporation",
        "name": "Mannix Infotech Solutions Pvt. Ltd.",
        "alternateName": "Mannix Infotech Solutions",
        "url": "https://www.mannix.in",
        "logo": "https://www.mannix.in/logo.png",
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+91 9664053983",
          "contactType": "customer service",
          "areaServed": "IN",
          "availableLanguage": ["en", "Gujarati", "Hindi"]
        },
        "sameAs": [
          "https://www.facebook.com/profile.php?id=61558304945053",
          "https://x.com/mediatech09?t=x-kv93jEG5y0aqezPQ02qw&s=08",
          "https://www.instagram.com/mannixinfotech09",
          "https://www.linkedin.com/company/mannix-infotech-solutions-pvt-ltd/",
          "https://www.mannix.in"
        ]
      `}
        </script>
      </Helmet>
      <div className="hire-dedicated services mt-0">
        <section className="banner-content-1">
          <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            className="position-absolute top-banner-bg"
            viewBox="0 0 1920 761"
          >
            <g>
              <defs>
                <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
              </defs>
              <clipPath id="SVGID_2_">
                <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
              </clipPath>
              <g className="banner-bg-asw">
                <path
                  className="st1"
                  d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                        c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
                ></path>
                <path
                  className="st2"
                  d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                        c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
                ></path>
                <path
                  className="st1"
                  d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
                ></path>
                <path
                  className="st2"
                  d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
                ></path>
                <circle
                  className="st1"
                  cx="1536.8"
                  cy="661.3"
                  r="56.2"
                ></circle>
                <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
              </g>
            </g>
          </svg>
          <div className="py-5 container">
            <div className="row align-items-center justify-content-around mt-4">
              <div className="col-lg-6">
                <div className="">
                  <h1 className="head-title head-title-without-line m-0 py-3">
                    CROWDFUNDING APP DEVELOPMENT
                  </h1>
                  <p className="m-0 py-3">
                    Mobile applications that seamlessly function and should
                    proffer amazing functionalities are pre-eminent. Android App
                    Development Company is a futuristic friendly way of weaving
                    technology for mobile app development that boosts the
                    omnipresence of the audience. Mannix Infotech Solutions
                    Pvt.Ltd. experience and skilled developers can create
                    robust, engaging, high-performance, android app solutions
                    that come with all advanced features and technologies. We
                    have in-house best talents from the industry who proffer
                    end-to-end customization and integration services to bring
                    ideas into reality.{" "}
                  </p>
                  <p className="m-0 py-3">
                    With years of experience in diverse industries, we can craft
                    and implement engaging, scalable, and feature-rich mobile
                    apps that can easily run across multiple platforms.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 prod-section2">
                <div className="">
                  <form
                    className="register-form"
                    id="register-form"
                    method="post"
                    action=""
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="hidden"
                      name="form_type"
                      id="form_type"
                      value="Android App Development"
                    />
                    <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                      <div className="d-flex">
                        <div className="w-100 mr-2">
                          <label className="w-100">
                            <span>Name</span>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Name *"
                              className="form-control"
                              value={formData.name}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                        <div className="w-100">
                          <label className="w-100">
                            <span>Email</span>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Email id *"
                              className="form-control"
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <label className="w-100">
                          <span>Contact</span>
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            placeholder="Contact No *"
                            className="form-control"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Country</span>
                          <input
                            type="text"
                            name="country"
                            id="country"
                            placeholder="Enter your Country"
                            className="form-control"
                            value={formData.country}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Message</span>
                          <textarea
                            name="message"
                            id="message"
                            placeholder="Enter your message *"
                            className="form-control"
                            value={formData.message}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </label>
                      </div>

                      <div className="">
                        <button
                          type="submit"
                          name="submit"
                          className="submit btn w-100 g-recaptcha-btn"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="cp_home_sec mt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="cp_home_text">
                <p className="fs-1">
                  CROWDFUNDING <span>APP DEVELOPMENT</span>
                </p>
                <p className="text-justify">
                  Thinking of owning a crowdfunding platform like Kickstarter,
                  Indiegogo, GoFundMe, Seedrs, and CrowdRise? Mannix Infotech
                  Solutions Pvt.Ltd. is one of the leading crowdfunding platform
                  development companies providing crowdfunding solutions that
                  harness the power of innovative technologies for innovative
                  web and mobile applications in the market.
                </p>
                <p className="text-justify">
                  Whether you are a start-up, a small business, or a business,
                  our world-className online funding platform services provide a
                  wide range of crowdfunding application development solutions.
                  Our white-label crowdfunding software development services
                  support Equity Crowdfunding Platform, Real Estate Crowdfunding
                  Platform, Infrastructure Crowdfunding Platform, and many more.
                  Our crowdfunding developers build phenomenal crowdfunding web
                  and mobile applications with trending technology stacks to
                  your specifications, ideology, and business goals that will
                  help you become market leaders.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 ">
              <div className="cp_home_image">
                <img
                  src="../Images/CrowdFundingAppDevelopment.png"
                  alt="CrowdFundingAppDevelopment"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="best_cross_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="best-cr-img">
                {" "}
                <img
                  src="./CrowdfundingMobileApplication.png"
                  alt="CrowdfundingMobileApplication"
                  align="Mannix Infotech Solutions
                    Pvt.Ltd. Mobile Application Crowdfunding App Development"
                  className="img-fluid"
                />{" "}
              </div>
            </div>
            <div className="col-lg-7">
              <div className="best_cr_text">
                <h2>List of Features of the Crowdfunding Mobile Application</h2>

                <p className="text-justify">
                  Don’t have an Android / IOS mobile app? Look at the
                  crowdfunding app will include the following features -
                </p>
              </div>
              <div className="cross_listing">
                <ul>
                  <li>Fast push notification for potential investors</li>
                  <li>Social connection to better reach your audience</li>
                  <li>Simple and easy registration</li>
                  <li>Integration of the secure payment gateway</li>
                  <li>Sharing on social networks and sponsorship opstem</li>
                  <li>Access a powerful interactive dashboard</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="latest_crs_sec">
        <div className="container">
          <div className="row">
            <div className="latest_boxs_left col">
              <div className="latest_boxs_text">
                <h2>End-user application for crowdfunding</h2>
                <p className="text-justify">
                  Development of a variety of industries that use crowdfunding
                  to generate income and interest for a larger group of people.
                </p>
                <div className="latest_boxs_listing">
                  <ul>
                    <li>Art and Culture Project</li>
                    <li>Campaign for charities / non-profit organizations</li>
                    <li>Medical bills</li>
                    <li>Real estate financing</li>
                    <li>Community-driven projects</li>
                    <li>Space center for museums</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="latest_boxs_right col">
              <div className="amongst_inner">
                <div className="company-offer3">
                  <div className="container">
                    <div className="theme-title">
                      {" "}
                      <strong>
                        Several types of crowdfunding for application
                        development -
                      </strong>
                      <ul className="list-style-blt">
                        <li>Donation-based app</li>
                        <li>Debt Based</li>
                        <li>Based on rewards</li>
                        <li>Based on equity</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cr_features_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="section_title">
                <h2>Services</h2>{" "}
              </div>
            </div>
          </div>
          <div className="cr_group_wrap">
            <ul>
              <li>
                <div className="cr_features_item">
                  <div className="icon_img01">
                    {" "}
                    <img src="../Images/crowd1.png" alt="crowd1" />{" "}
                  </div>
                  <div className="item-heading">
                    <p className="fw-bold fs-6">
                      {" "}
                      Ai-integrated cross-platform applications
                    </p>{" "}
                  </div>
                </div>
                <div className="text_p">
                  <p className="text-justify">
                    We integrate artificial intelligence into our cross-platform
                    applications to improve functionality and user experience.
                    Our developers create perfect apps for our clients.
                  </p>
                </div>
              </li>
              <li>
                <div className="cr_features_item">
                  <div className="icon_img01">
                    {" "}
                    <img src="../Images/crowd2.png" alt="crowd2" />{" "}
                  </div>
                  <div className="item-heading">
                    <p className="fw-bold fs-6">
                      Multiplatform portable applications
                    </p>{" "}
                  </div>
                </div>
                <div className="text_p">
                  <p className="text-justify">
                    Mannix Infotech Solutions Pvt.Ltd. provides hybrid wearable
                    application development services by integrating sensors and
                    cameras to enhance user experience.
                  </p>
                </div>
              </li>
              <li>
                <div className="cr_features_item">
                  <div className="icon_img01">
                    {" "}
                    <img src="../Images/crowd3.png" alt="crowd3" />{" "}
                  </div>
                  <div className="item-heading">
                    <p className="fw-bold fs-6">
                      Multiplatform Web Applications
                    </p>{" "}
                  </div>
                </div>
                <div className="text_p">
                  <p className="text-justify">
                    Don't want an app that overloads users by requiring a
                    download? We got you! We'll help you build world-className
                    web applications.
                  </p>
                </div>
              </li>
              <li>
                <div className="cr_features_item">
                  <div className="icon_img01">
                    {" "}
                    <img src="../Images/crowd4.png" alt="crowd4" />{" "}
                  </div>
                  <div className="item-heading">
                    <p className="fw-bold fs-6">
                      Cross-platform UI / UX design services
                    </p>{" "}
                  </div>
                </div>
                <div className="text_p">
                  <p className="text-justify">
                    Mannix Infotech Solutions Pvt.Ltd. cross-platform apps are
                    known for their excellent user interface (UI / UX) and fast
                    load times. Our designers know the best for your
                    applications.
                  </p>
                </div>
              </li>
              <li>
                <div className="cr_features_item">
                  <div className="icon_img01">
                    {" "}
                    <img src="../Images/crowd5.png" alt="crowd5" />{" "}
                  </div>
                  <div className="item-heading">
                    <p className="fw-bold fs-6">
                      Support and maintenance services
                    </p>{" "}
                  </div>
                </div>
                <div className="text_p">
                  <p className="text-justify">
                    We will never let you dry. We are committed to supporting
                    you even after the delivery of the project.{" "}
                  </p>
                </div>
              </li>
              <li>
                <div className="cr_features_item">
                  <div className="icon_img01">
                    {" "}
                    <img src="../Images/crowd6.png" alt="crowd6" />{" "}
                  </div>
                  <div className="item-heading">
                    <p className="fw-bold fs-6">
                      Support and maintenance services
                    </p>{" "}
                  </div>
                </div>
                <div className="text_p">
                  <p className="text-justify">
                    We will never let you dry. We are committed to supporting
                    you even after the delivery of the project.{" "}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CrowdfundingAppDevelopment;
