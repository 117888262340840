import React, { useState } from "react";
import { Helmet } from "react-helmet";

const LMSManagment = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>
          LMS Software Development Company | Learning Management Solutions |
          Mannix Infotech Solutions in Ahmedabad
        </title>
        <meta
          name="description"
          content="Elevate online learning with Mannix Infotech's custom LMS software development services. Our experts design and develop tailored learning management solutions to enhance engagement, productivity, and knowledge sharing. Discover how!"
        />
        <meta
          name="keywords"
          content="Top LMS Management Software Development Company  in India |Best LMS Management Software Development Company in Gujarat |Best LMS Management Software Development Company in Ahmedabad | List of company LMS Management Software Development services in Ahmedabad | LMS Management Software Development services near me | LMS Management Software Development company near by| LMS Management Software Development company near me| LMS Management Software Development | Top 5 LMS Management Software Development company in Ahmedabad"
        />

        <link
          rel="canonical"
          href="https://www.mannix.in/lms-management-software-services"
        />
      </Helmet>
      <section className="banner-content-1">
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          className="position-absolute top-banner-bg"
          viewBox="0 0 1920 761"
        >
          <g>
            <defs>
              <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
            </defs>
            <clipPath id="SVGID_2_">
              <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
            </clipPath>
            <g className="banner-bg-asw">
              <path
                className="st1"
                d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                            c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
              ></path>
              <path
                className="st2"
                d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                            c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
              ></path>
              <path
                className="st1"
                d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
              ></path>
              <path
                className="st2"
                d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
              ></path>
              <circle className="st1" cx="1536.8" cy="661.3" r="56.2"></circle>
              <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
            </g>
          </g>
        </svg>
        <div className="py-5 container">
          <div className="row align-items-center justify-content-around mt-4">
            <div className="col-lg-6">
              <div className="">
                <h1 className="head-title head-title-without-line m-0 py-3">
                  LMS software Development
                </h1>
                <p className="m-0 py-3">
                  At Mannix Infotech Solutions Pvt.Ltd., we bring our years of
                  e-learning expertise to design and deliver a bespoke LMS
                  software development company solution for your employees,
                  partners or customers. With top-level eLearning experts on
                  board, we're here to help you at any stage of the LMS software
                  development lifecycle. impression your brand creates in person
                  and that's a big deal!
                </p>
              </div>
            </div>
            <div className="col-lg-5 prod-section2">
              <div className="">
                <form
                  className="register-form"
                  id="register-form"
                  method="post"
                  action=""
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="form_type"
                    id="form_type"
                    value="Android App Development"
                  />
                  <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                    <div className="d-flex">
                      <div className="w-100 mr-2">
                        <label className="w-100">
                          <span>Name</span>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name *"
                            className="form-control"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div className="w-100">
                        <label className="w-100">
                          <span>Email</span>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email id *"
                            className="form-control"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className="d-flex">
                      <label className="w-100">
                        <span>Contact</span>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Contact No *"
                          className="form-control"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Country</span>
                        <input
                          type="text"
                          name="country"
                          id="country"
                          placeholder="Enter your Country"
                          className="form-control"
                          value={formData.country}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Message</span>
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Enter your message *"
                          className="form-control"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </label>
                    </div>

                    <div className="">
                      <button
                        type="submit"
                        name="submit"
                        className="submit btn w-100 g-recaptcha-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="breadwrap clearfix mt-5">
        <div className="container fw-bold pt-5 pb-3">
          {" "}
          LMS SOFTWARE DEVELOPMENT
        </div>
      </section>
      <section className="inner-indent clearfix">
        <div className="dot-right clearfix">
          <div className="container">
            <p className="text-justify">
              At Mannix Infotech Solutions Pvt.Ltd., we bring our years of
              e-learning expertise to design and deliver a bespoke LMS software
              development solution for your employees, partners or customers.
              With top-level eLearning experts on board, we're here to help you
              at any stage of the LMS software development lifecycle:
            </p>
            <ul className="list-style-blt">
              <li>Data-driven consulting with on-site and off-site visits</li>
              <li>LMS improvement and development of custom components.</li>
              <li>Developing an eLearning opstem from scratch.</li>
              <li>Seamless integrations with third-party software.</li>
              <li>
                Continuous opstem improvement and end-to-end quality assurance.
              </li>
              <li>Full IT support (L1, L2 and L3).</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="what-we-do">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="theme-title text-left">
                <h2 className="static_css">School Management Software</h2>
                <p className="text-justify">
                  School ERP software could be a robust and proven educational
                  ERP opstem integrated with advanced modules to modify
                  academics and educators to digitalize the daily work processes
                  of educational institutions. Also known as a school management
                  opstem, Smart School ERP software simplifies complex
                  administrative and non-administrative tasks including online
                  admission, online fee management, exam management,
                  assessments, scheduling, teaching-learning, etc. the benefits
                  of the school management opstem are numerous in terms of
                  improving productivity, efficiency and return on investment.
                </p>
                <p className="text-justify">
                  School management software is an data management opstem
                  employed by educational institutions to manage all student
                  records. It is specially designed to manage all the
                  administrative work of schools and institutions. It contains
                  many modules that help teachers and staff to maintain student
                  activities such as daily attendance, schedule management,
                  school records management, expense management, student
                  assessments, etc.
                </p>
                <h2 className="static_css mt-5">
                  Vision Of Our School Management Services
                </h2>
                <p className="text-justify">
                  Mannix Infotech Solutions Pvt.Ltd. is constantly working with
                  the vision to grow and become a leading company in the
                  education sector by providing high quality institutional
                  functionality.Our goal is to create a strong chain of
                  educational institutions to connect and communicate with each
                  other on the single platform. Mannix Infotech Solutions
                  Pvt.Ltd. education management ERP software brings the best
                  ethical values and serves the right purpose of society by
                  providing top-notch educational services using modern
                  technologies without any hurdle of geographic or technical
                  support .In addition, our team of professional developers
                  aspire to establish substantial relationships with educational
                  institutions around the world and aim to establish long term
                  relationships with organizations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="company-offer3 ">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="theme-title">
                {" "}
                <strong>ADVANTAGE OF SCHOOL MANAGEMENT SERVICES -</strong>
                <ul className="list-style-blt">
                  <li>Save the time of teachers and administrators.</li>
                  <li>Close the communication gap.</li>
                  <li>Human resources management.</li>
                  <li>Data on the lifespan of students.</li>
                  <li>Schedule management.</li>
                  <li>Collection of fees.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LMSManagment;
