import React from "react";

const Blog1 = () => {
  return (
    <div>
      <div>
        <section
          className="ops_blog_header"
          style={{
            background: "url('../blogbanner.jpg')",
            height: "270px",
            backgroundSize: "cover !important",
            padding: "80px 0",
          }}
        >
          <div className="container">
            <div className="ops_blog_header_content">
              <h1 className="fw-bold text-danger">
                Unlocking the Secrets: Dive into the World of Mannix Infotech
                Solutions Pvt.Ltd.
              </h1>
            </div>
          </div>
        </section>

        <section>
          <div className=" d-flex flex-wrap p-5">
            <div className="col-lg-8 d-flex flex-wrap justify-content-center">
              <div
                className="mb-3 px-5"
                style={{ width: "100%", margin: "0 auto" }}
              >
                <div
                  className="card"
                  style={{
                    backgroundColor: "#d1d8e0",
                    borderRadius: "20px",
                    width: "100%",
                  }}
                >
                  <img
                    src="../Images/GraphicDesignBlog.jpg"
                    alt="GraphicDesignBlog"
                    style={{ maxWidth: "100%", borderRadius: "20px 20px 0 0" }}
                  />
                  <p className="p-2 text-center" style={{ fontSize: "25px" }}>
                    Graphic Designing: Creating Icons and Applications to Mould
                    the World We Live In
                  </p>
                  <p className="p-2 text-center" style={{ fontSize: "16px" }}>
                    The importance of graphic design has increased in the
                    current digital era. Eye-catching graphics are used by
                    companies and organisations to effectively and clearly
                    communicate their brand and message.
                  </p>

                  <div className="px-4 pb-4" style={{ fontSize: "16px" }}>
                    <div>
                      {" "}
                      <h2 style={{ fontSize: "24px" }}>
                        {" "}
                        What is graphic design?{" "}
                      </h2>
                      <p>
                        Graphic design is a visual language that conveys ideas
                        and messages through the use of layout, typography, and
                        images. It plays a crucial role in shaping the world we
                        live in by creating icons and applications that are not
                        only visually appealing but also functional and
                        user-friendly. Graphic designers use their creativity
                        and technical skills to solve problems and create
                        visually stunning designs that capture the attention of
                        audiences and leave a lasting impression.{" "}
                      </p>
                      <p>
                        The importance of graphic design has increased in the
                        current digital era. Eye-catching graphics are used by
                        companies and organisations to effectively and clearly
                        communicate their brand and message.{" "}
                      </p>
                      <p>
                        Graphic design is all around us. It's the design of your
                        favourite coffee mug, the way this webpage is laid out,
                        the packaging that grabs your attention at the store,
                        and even the user interface (UI) that lets you use your
                        phone. This article will explore the different
                        specializations of graphic design, giving you a broad
                        idea of the many options available in the field of
                        visual communication.{" "}
                      </p>
                      <p>
                        From logo design to web design, packaging design to
                        motion graphics, graphic designers play a crucial role
                        in shaping the visual world around us. Each
                        specialization requires a unique set of skills and
                        expertise, but all ultimately aim to communicate a
                        message effectively through visual elements. Whether
                        it's creating a memorable brand identity or designing an
                        intuitive user experience, graphic designers have the
                        power to influence how we perceive and interact with the
                        world. Let's explore some of the key areas of graphic
                        design and the impact they have on our daily lives.
                      </p>
                    </div>

                    <div className="mt-3">
                      <h2 style={{ fontSize: "24px" }}>
                        The Building Blocks: Logo Design, Flyer Design, Brochure
                        Design, and Packaging Design
                      </h2>
                      <p>
                        • Logo Design: Any brand's identity is built on its
                        logo. It is a graphic representation of a company's core
                        principles. A well-designed logo may be applied to a
                        variety of marketing items and is both versatile and
                        memorable.
                      </p>
                      <p>
                        • Flyer Design: Flyers are a classic marketing tool used
                        to promote events, products, or services. Effective
                        flyer design uses clear visuals, concise messaging, and
                        a call to action to grab attention and get results.
                      </p>
                      <p>
                        • Brochure Design: Brochures offer comprehensive details
                        about a business, item, or service. They often combine
                        text, images, and info graphics to create a compelling
                        and informative piece of marketing collateral.
                      </p>
                      <p>
                        • Packaging Design: Packaging design not only protects a
                        product but also serves as a vital marketing tool. It
                        should be visually appealing, informative, and
                        functional, enticing customers to pick it up from the
                        shelf.
                      </p>
                    </div>

                    <div className="mt-3">
                      <h2 style={{ fontSize: "24px" }}>
                        The Digital Frontier: UI Design
                      </h2>
                      <p>
                        • UI Design (User Interface Design): UI design is
                        important in this digital world. It's the design of the
                        user interface of websites, apps, and software.
                        Effective UI design prioritizes usability, making it
                        easy and intuitive for users to interact with the
                        digital product.
                      </p>
                    </div>

                    <div className="mt-3">
                      <h2 style={{ fontSize: "24px" }}>Beyond the Basics</h2>
                      <p>
                        This is just a taste of the many specializations within
                        graphic design. From motion graphics and environmental
                        design to web design and publication design, the
                        possibilities are endless.
                      </p>
                      <p>
                        No matter your area of interest, the core principles of
                        graphic design remain the same:{" "}
                      </p>
                      <p>• effective communication</p>
                      <p>• visual hierarchy</p>
                      <p>• strong composition</p>
                      <p>
                        By mastering these principles and staying up-to-date
                        with the latest trends, you can create impactful designs
                        that resonate with your audience.
                      </p>
                    </div>

                    <div className="mt-3">
                      <h3 style={{ fontSize: "25px" }}>Conclusion</h3>
                      <p>
                        To sum up, graphic design is a broad discipline that
                        offers countless opportunities for originality and
                        creativity. By continuously learning and honing your
                        skills, you can stay ahead of the curve and produce
                        designs that leave a lasting impression. Whether you are
                        a beginner or a seasoned professional, the key is to
                        always strive for excellence and push the boundaries of
                        what is possible in the world of design. With dedication
                        and passion, you can make a significant impact in the
                        industry and create designs that truly stand out.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div>
                <p className="fs-4">Categories</p>
                <ul>
                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> App & Software Development </span>
                    </a>
                  </li>
                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> Graphic Designing</span>
                    </a>
                  </li>
                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> Web Development </span>
                    </a>
                  </li>
                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> Online Marketing </span>
                    </a>
                  </li>

                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> Digital Marketing </span>
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <p className="fs-4">Recent posts</p>
                <div>
                  <div
                    className="p-1 recent"
                    style={{
                      backgroundColor: "#e7e8e9",
                      borderBottom: "solid 1px #eeeee",
                    }}
                  >
                    <a
                      href="web-development-blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/WebDevelopmentBlog.png"
                          alt="WebDevelopmentBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>Top 10 Web Development Companies </span>
                      </div>
                    </a>
                  </div>
                  <div
                    className="p-1 recent mt-2"
                    style={{ backgroundColor: "#e7e8e9" }}
                  >
                    <a
                      href="digital-marketing-agency-blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/DigitalMarketingBlog.png"
                          alt="DigitalMarketingBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>Top 10 Digital Marketing Agency </span>
                      </div>
                    </a>
                  </div>
                  <div
                    className="p-1 recent mt-2"
                    style={{
                      backgroundColor: "#e7e8e9",
                      borderBottom: "solid 1px #eeeee",
                    }}
                  >
                    <a
                      href="Graphic-Design-Blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/GraphicDesignBlog.jpg"
                          alt="GraphicDesignBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>Graphic Designing</span>
                      </div>
                    </a>
                  </div>
                  <div
                    className="p-1 recent mt-2"
                    style={{
                      backgroundColor: "#e7e8e9",
                      borderBottom: "solid 1px #eeeee",
                    }}
                  >
                    <a
                      href="online-marketing-services-blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/OnlineMarketingBlog.png"
                          alt="OnlineMarketingBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>The Power Of Online Marketing Services</span>
                      </div>
                    </a>
                  </div>
                  <div
                    className="p-1 recent mt-2"
                    style={{
                      backgroundColor: "#e7e8e9",
                      borderBottom: "solid 1px #eeeee",
                    }}
                  >
                    <a
                      href="mobile-app-development-blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/MobileAppDevelopmentBlog.png"
                          alt="MobileAppDevelopmentBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>Top Mobile App Development Companies</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div>
                <p className="fs-4 mt-4">Social links</p>
                <ul className="d-flex flex-wrap gap-3">
                  <li style={{ listStyle: "none" }}>
                    <a
                      href="https://www.facebook.com/profile.php?id=61558304945053"
                      target="_blank"
                      rel="noreferrer"
                      title="Facebook"
                      style={{
                        padding: "9px 8px",
                        display: "block",
                        fontSize: "17px",
                        fontWeight: "300",
                        color: "#fff",
                        borderRadius: "10px",
                        background: "#db150e",
                        width: "36px",
                        height: "36px",
                      }}
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li style={{ listStyle: "none" }}>
                    <a
                      href=" https://x.com/mediatech09?t=x-kv93jEG5y0aqezPQ02qw&s=08"
                      target="_blank"
                      rel="noopener"
                      title="Twitter"
                      style={{
                        padding: "9px 8px",
                        display: "block",
                        fontSize: "17px",
                        fontWeight: "300",
                        color: "#fff",
                        borderRadius: "10px",
                        background: "#db150e",
                        width: "36px",
                        height: "36px",
                      }}
                    >
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </li>

                  <li style={{ listStyle: "none" }}>
                    <a
                      href="https://www.instagram.com/mannixinfotech09/?fbclid=IwAR30ORrOy8gHLBnmw9Nys9CZNYznRUYLyiAXihrlzLH63aVD_y96syb0jJo"
                      target="_blank"
                      rel="noreferrer"
                      title="Instagram"
                      style={{
                        padding: "9px 8px",
                        display: "block",
                        fontSize: "17px",
                        fontWeight: "300",
                        color: "#fff",
                        borderRadius: "10px",
                        background: "#db150e",
                        width: "36px",
                        height: "36px",
                      }}
                    >
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li style={{ listStyle: "none" }}>
                    <a
                      href="https://www.linkedin.com/company/mannix-infotech-solutions-pvt-ltd/"
                      target="_blank"
                      rel="noreferrer"
                      title="Linkedin"
                      style={{
                        padding: "9px 8px",
                        display: "block",
                        fontSize: "17px",
                        fontWeight: "300",
                        color: "#fff",
                        borderRadius: "10px",
                        background: "#db150e",
                        width: "36px",
                        height: "36px",
                      }}
                    >
                      <i className="fa-brands fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Blog1;
