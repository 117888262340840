import React from "react";
import { Helmet } from "react-helmet";

const Blog = () => {
  return (
    <>
      <Helmet>
        <title>
          Application Development | Digital Marketing | Web Development |
          Software Development | Graphic Designing | SEO | Business Developer |
          Blogs of Mannix Infotech Solutions
        </title>
        <meta
          name="description"
          content="Explore are Blog for export insights application development, digital marketing, web
development, software development, graphic designing, seo, business developer .
Stay informed, Stay ahead with Mannix Infotech Solutions"
        />
        <meta
          name="keywords"
          content="mannix infotech solutions Pvt.Ltd.|digital marketing|performance marketing|ai in marketing|ai digital marketing|digital marketing strategy|marketing ai|digital marketing trends 2023|ai in digital marketing|ai for digital marketing|digital marketing meaning|ai and marketing|digital marketing business|marketing trends 2024|about digital marketing|website marketing|marketing strategy|marketing trends|marketing services|digital marketing services|digital marketing website|marketing with ai|learn digital marketing|2024 marketing trends|search engine marketing|digital marketing trends|digital marketing software|marketing web|digital marketing company|digital marketing blogs|marketing company|sem marketing|power digital marketing|digital marketing agency|digital marketing definition|marketing app|business marketing|marketing blogs|digital marketing trends|digital marketing app|market trends 2024|future of digital marketing|latest digital marketing trends|ai marketing strategy|digital transformation in marketing|ai and digital marketing|marketing technologies|all about digital marketing"
        />

        <link rel="canonical" href="https://www.mannix.in/blog" />
      </Helmet>

      <div>
        <section
          className="ops_blog_header"
          style={{
            background: "url('../blogbanner.jpg')",
            height: "270px",
            backgroundSize: "cover !important",
            padding: "80px 0",
          }}
        >
          <div className="container">
            <div className="ops_blog_header_content">
              <h1 className="fw-bold text-danger">
                Unlocking the Secrets: Dive into the World of Mannix Infotech
                Solutions Pvt.Ltd.
              </h1>
            </div>
          </div>
        </section>

        <section className="">
          <div className=" d-flex flex-wrap p-5">
            <div className="col-lg-7  d-flex flex-wrap justify-content-center">
              {blogPosts.map((post, index) => (
                <div
                  key={index}
                  className="mb-3"
                  style={{
                    flex: "1 1 300px",
                    maxWidth: "300px",
                    margin: "0 auto",
                  }}
                >
                  <div
                    className="card"
                    style={{
                      backgroundColor: "#e7e8e9",
                      borderRadius: "20px",
                      width: "330px",
                    }}
                  >
                    <img
                      src={post.image}
                      alt={post.alt}
                      style={{
                        height: "250px",
                        maxWidth: "100%",
                        borderRadius: "20px 20px 0 0",
                      }}
                    />
                    <p
                      className="p-2 text-center fw-bold"
                      style={{ fontSize: "19px" }}
                    >
                      {post.title}
                    </p>
                    <p className="p-2 text-center" style={{ fontSize: "14px" }}>
                      {post.description}
                    </p>
                    <div>
                      <a
                        className="fw-bold"
                        href={post.link}
                        style={{
                          padding: "20px",
                          backgroundColor: "#e84118",
                          color: "white",
                          float: "right",
                          borderRadius: "0 0 20px 20px",
                          minWidth: "151px",
                          textAlign: "center",
                        }}
                      >
                        Continue <i className="fa fa-long-arrow-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="col-lg-5 ">
              <div>
                <p className="fs-4">Categories</p>
                <ul>
                  {categories.map((category, index) => (
                    <li
                      key={index}
                      style={{
                        borderBottom: "solid 1px #eeeeee",
                        marginBottom: "5px",
                        paddingBottom: "5px",
                        lineHeight: "normal",
                        listStyle: "none",
                      }}
                    >
                      <a
                        href={category.link}
                        style={{
                          fontSize: "16px",
                          color: "#555555",
                          padding: "10px 10px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <span>{category.name}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <p className="fs-4">Recent posts</p>
                <div>
                  {recentPosts.map((post, index) => (
                    <div
                      key={index}
                      className="p-1 recent"
                      style={{
                        backgroundColor: "#e7e8e9",
                        borderBottom: "solid 1px #eeeee",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <a
                          href={post.link}
                          style={{ textDecoration: "none", color: "inherit" }}
                        >
                          <img
                            src={post.image}
                            alt={post.alt}
                            width="100px"
                            className="p-2"
                          />
                          <span>{post.title}</span>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <p className="fs-4 mt-4">Social links</p>
                <ul className="d-flex flex-wrap gap-3">
                  {socialLinks.map((link, index) => (
                    <li key={index} style={{ listStyle: "none" }}>
                      <a
                        href={link.url}
                        target="_blank"
                        rel="noreferrer"
                        title={link.name}
                        style={{
                          padding: "9px 8px",
                          display: "block",
                          fontSize: "17px",
                          fontWeight: "300",
                          color: "#fff",
                          borderRadius: "10px",
                          background: "#2980b9",
                          width: "36px",
                          height: "36px",
                        }}
                      >
                        <i className={`fa-brands ${link.icon}`}></i>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

const blogPosts = [
  {
    image: "../Images/WebDevelopmentBlog.png",
    alt: "WebDevelopmentBlog",
    title: "Top 10 Web Development Companies in Ahmedabad.",
    description:
      "In the era of digitalization, having a robust online presence is crucial for any business to thrive. A well-designed and functional website plays a vital role in attracting customers and establishing credibility.",
    link: "/web-development-blog",
  },
  {
    image: "../Images/DigitalMarketingBlog.png",
    alt: "DigitalMarketingBlog",
    title: "Top 10 Digital Marketing Agency in Ahmedabad",
    description:
      "In Ahmedabad, where entrepreneurship and business coexist, having a strong web presence is crucial. As businesses attempted to thrive in the cutthroat online environment by offering success stories in addition to services, a select few agencies rose to prominence in the sector.",
    link: "/digital-marketing-agency-blog",
  },

  {
    image: "../Images/GraphicDesignBlog.jpg",
    alt: "GraphicDesignBlog",
    title:
      "Graphic Designing: Creating Icons and Applications to Mould the World We Live In",
    description:
      "The importance of graphic design has increased in the current digital era. Eye-catching graphics are used by companies and organisations to effectively and clearly communicate their brand and message.",
    link: "/Graphic-Design-Blog",
  },
  {
    image: "../Images/OnlineMarketingBlog.png",
    alt: "OnlineMarketingBlog",
    title: "The Power Of Online Marketing Services",
    description:
      "Digital marketing provides a wide range of advantages for companies of all sizes and sectors, from elevating sales and fostering client relationships to enhancing brand awareness and website traffic.",
    link: "/online-marketing-services-blog",
  },
  {
    image: "../Images/MobileAppDevelopmentBlog.png",
    alt: "MobileAppDevelopmentBlog",
    title: "Top Mobile App Development Companies in Ahmedabad",
    description:
      "Mannix Infotech Solutions Pvt. Ltd., headquartered in Ahmedabad, Gujarat, India, isn't just a web development powerhouse. They're a team of experts passionate about crafting next-level mobile applications.",
    link: "/mobile-app-development-blog",
  },
];

const categories = [
  { name: "App & Software Development", link: "/blog" },
  { name: "Graphic Designing", link: "/blog" },
  { name: "Web Development", link: "/blog" },
  { name: "Online Marketing", link: "/blog" },
  { name: "Digital Marketing", link: "/blog" },
];

const recentPosts = [
  {
    image: "../Images/GraphicDesignBlog.jpg",
    alt: "GraphicDesignBlog",
    title: "Graphic Designing",
    link: "/Graphic-Design-Blog",
  },
  {
    image: "../Images/OnlineMarketingBlog.png",
    alt: "OnlineMarketingBlog",
    title: "The Power Of Online Marketing Services",
    link: "/online-marketing-services-blog",
  },
  {
    image: "../Images/MobileAppDevelopmentBlog.png",
    alt: "MobileAppDevelopmentBlog",
    title: "Top Mobile App Development Companies",
    link: "/mobile-app-development-blog",
  },
];

const socialLinks = [
  {
    name: "Facebook",
    url: "https://www.facebook.com/profile.php?id=61558304945053",
    icon: "fa-facebook-f",
  },
  {
    name: "Twitter",
    url: "https://x.com/mediatech09?t=x-kv93jEG5y0aqezPQ02qw&s=08",
    icon: "fa-twitter",
  },
  {
    name: "Instagram",
    url: "https://www.instagram.com/mannixinfotech09/?fbclid=IwAR30ORrOy8gHLBnmw9Nys9CZNYznRUYLyiAXihrlzLH63aVD_y96syb0jJo",
    icon: "fa-instagram",
  },
  {
    name: "Linkedin",
    url: "https://www.linkedin.com/company/mannix-infotech-solutions-pvt-ltd/",
    icon: "fa-linkedin",
  },
];

export default Blog;
