import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const Home = ({ services, layout, workline }) => {
  const [selectedService, setSelectedService] = useState(services[0]);
  const [expandedIndex, setExpandedIndex] = useState(0);

  const imagePaths = [
    "../check.png",
    "../check.png",
    "../check.png",
    "../check.png",
    "../check.png",
    "../check.png",
    "../check.png",
    "../check.png",
    "../check.png",
    "../check.png",
  ];

  const handleServiceClick = (service, index) => {
    setSelectedService(service);
    setExpandedIndex(index);
  };

  useEffect(() => {
    setExpandedIndex(0);
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMargin: 2,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMargin: 2,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const images = [
    "./Images/main1.png",
    "./Images/main2.png",
    "./Images/main3.png",
    "./Images/main4.png",
    "./Images/main5.png",
    "./Images/main6.png",
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
  });
  const sliderRef = useRef(null);
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const setting3 = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMargin: 2,
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  return (
    <>
      <Helmet>
        <title>
          Web | Website design and development company in Ahmedabad | Mannix
          Infotech Solutions PVT. LTD.
        </title>
        <meta
          name="description"
          content="Mannix infotech solutions is a company specialized in business development,performance marketing service, digital technology services. We help you to grow business around the world"
        />
        <meta
          name="keywords"
          content="Mannix infotech solutions in India|Mannix infotech solutions in gujarat|Mannix infotech solutions in Ahmedabad|Mannix infotech solutions in Ahmedabad|It company in Ahmedabad|Website developer company in Gujarat|Mobile App Developer company in Ahmedabad|Digital Marketing Agency in Ahmedabad|Software Company in Gujarat|Best it Software company in Gujarat|It Software company in India|Top Software company in Ahmedabad|List of bestcompany for Software in Gujarat|it software companies in ahmedabad|service based software companies in ahmedabad|top 10 software companies in ahmedabad|concept infoway software development company in ahmedabad india|Web design company in India|Web designing company in Ahmedabad|Web designing company in India|Application development in India|App development in India|App development company in India|Application developer company in Gujarat|App developer company in Gujarat|App developer company in Ahmedabad|Digital marketing services company in Ahmedabad|Digital marketing company in Ahmedabad|Top digital marketing company in Ahmedabad|Top SEO Service company in India|Top SEO Service company in Gujarat|SEO company in India|SEO company in Gujarat|SEO company in Ahmedabad|How to improve my business|How to Selling products Online|How to Grow my business|Technical SEO company in Gujarat|Paid marketing Services company in India|Paid marketing Services company in Ahmedabad|
"
        />
        <link rel="canonical" href="https://www.mannix.in/" />
      </Helmet>

      <div className="" style={{ minHeight: "100vh" }}>
        {/*---------------------------------------------- //Second1 */}
        {/* flex-column flex-md-row */}
        <div className="d-flex flex-wrap">
          <div
            className="justify-content-between p-4"
            // style={{ flexBasis: "45%" }}
          >
            <div
              className="pt-5"
              style={{ fontWeight: "400", paddingLeft: "10%" }}
            >
              <span className="responsive-font-large">
                <b>Empower Your</b>
              </span>
              <h2
                className="responsive-font-xlarge"
                style={{ color: "#2980b9" }}
              >
                <span className="mt-0">
                  <b>SOLUTIONS</b>
                </span>
              </h2>
              <p
                className="responsive-font-xlarge"
                style={{ color: "#2980b9" }}
              >
                <span>
                  <b>ENTERPRISE</b>
                </span>
              </p>
              <span className="responsive-font-medium">
                <b>With The Latest Digital</b>
              </span>
              <h1
                className="responsive-font-xxlarge"
                style={{ color: "#2980b9" }}
              >
                <b>TECHNOLOGIES</b>
              </h1>
            </div>
            <div className="mt-5" style={{ position: "relative", left: "9%" }}>
              <a
                href="tel:+91 9664053983"
                className="text-decoration-none text-dark"
                title="phone"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="../Images/callnow-1.gif"
                  alt="callnow"
                  style={{ color: "red", maxWidth: "60%" }}
                />
              </a>
            </div>
          </div>
          <div className=" pt-5 d-md-block d-none">
            <div className="container col-lg-12 col-md-12 col-12 px-5 mb-5">
              <img
                src="../laptop.png"
                alt="Laptop"
                className="position-relative img-fluid d-none d-md-block d-lg-block"
                style={{ height: "450px", width: "540px" }}
              />
              <div className="">
                <div
                  className=" position-absolute"
                  style={{
                    height: "313px",
                    width: "494px",
                    top: "31px",
                    left: "71px",
                  }}
                >
                  <Slider {...sliderSettings}>
                    {images.map((image, index) => (
                      <div
                        key={index}
                        className="extra-slider d-none d-md-block d-lg-block"
                      >
                        <img
                          src={image}
                          alt={`Slide ${index}`}
                          className="img-fluid"
                          style={{
                            width: "100%",
                            height: "313px",
                            objectFit: "cover",
                            borderRadius: "8px",
                          }}
                        />
                      </div>
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          <div
            className=" owl-stage-outer responsive-container d-sm-none m-0 p-0"
            style={{ flexBasis: "100%" }}
          >
            <img
              src={images[currentImageIndex]}
              alt="Portfolio"
              className="responsive-img  img-fluid"
            />
            <div></div>
          </div>{" "}
          <div className="web img-fluid"></div>
        </div>

        {/*---------------------------------------------- //Second2 */}

        <div className="container d-flex justify-content-center flex-column flex-md-row  mt-5 mb-5">
          <div className=" mb-md-0 ">
            <img
              src="../Images/aboutus.png"
              className="img-fluid"
              alt="aboutus"
              width="4000px"
            />
          </div>

          <div className="ms-md-4 p-md-5">
            <h2 className="mt-2 fs-2" style={{ lineHeight: "40px" }}>
              <span
                className=""
                style={{
                  borderBottom: "5px solid #e84118",
                }}
              >
                <b>About </b>
              </span>
              <span>
                {" "}
                <b>Mannix Infotech Solutions Pvt. Ltd.</b>
              </span>{" "}
            </h2>

            <br></br>
            <div className="">
              <h2 style={{ fontSize: "20px" }}>
                WELCOME DIGITAL TECH IN YOUR BUSINESSES WITH US:
              </h2>
              <p className="text-left">
                Mannix Infotech Solutions Pvt. Ltd. Company based in Ahmedabad,
                Gujarat, India forwards 360-degree Digital Marketing Solutions
                for businesses across the globe.From designing, marketing,
                strategy, and optimization, we present complete IT solutions
                keeping the client needs under consideration. Mastering several
                niches, our experts offer solutions that can open a whole new
                world for businesses. We make custom software for all sorts of
                businesses, help them with their tech needs like IT consulting
                and cloud services, and boost their online presence through
                digital marketing. Basically, we're here to make businesses
                shine in India and around the world!"
              </p>
            </div>
          </div>
        </div>

        {/*----------------------------- ----------------- //Second3 */}
        <div className="mb-5 " style={{ position: "relative" }}>
          <div className="container text-center">
            <p className="fs-2 fw-bold">
              OUR <span style={{ color: "#2980b9" }}>EXPERTISE</span>
            </p>
            <p className="fs-5 mb-4">
              Mannix Infotech Solutions Pvt.Ltd., a top web development company,
              is ready to take your business on the journey of success. Our
              innovations act as a powerful catalyst for achievements.
            </p>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  {services.map((service, index) => (
                    <div className="col-6" key={index}>
                      <div
                        className="border text-center m-1"
                        style={{
                          background:
                            selectedService === service
                              ? "linear-gradient(to left, #db150e, #f6e58d)"
                              : "#fff",
                          borderRadius: "0 50px 0 50px",
                        }}
                      >
                        <p>
                          <Link
                            className="nav-link"
                            to="#"
                            onClick={() => handleServiceClick(service, index)}
                          >
                            <img
                              src={service.icon}
                              className="mt-3"
                              alt={service.icon}
                              style={{
                                filter:
                                  selectedService === service
                                    ? "brightness(0) invert(1)"
                                    : "none",
                              }}
                            />
                            <h5
                              className="mt-2"
                              style={{
                                color:
                                  selectedService === service
                                    ? "#ffffff"
                                    : "#000000",
                              }}
                            >
                              {service.course}
                            </h5>
                          </Link>
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-md-6">
                <h2 className="fw-bold m-4 " style={{ color: "#e84118" }}>
                  {selectedService.course}
                </h2>
                <p className="m-4" style={{ fontSize: "19px" }}>
                  {selectedService.details}
                </p>

                <ul className="m-4">
                  {selectedService.developmentServices.map((service, index) => (
                    <li key={index}>
                      <img
                        src={imagePaths[index]}
                        alt={service}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginRight: "10px",
                        }}
                      />
                      {service}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/*---------------------------------------------- //Section4 */}
        <div className="bg p-5" style={{ position: "relative" }}>
          <div className="text-center text-dark">
            <h2 className="fs-1">WHAT DO WE OFFER?</h2>
            <p className="p-3 fw-bold">
              Empower your tech needs with Mannix Infotech to give your digital
              dreams the wings of reality.
            </p>
          </div>
          <div className="container mt-5">
            <div className="row ">
              <div className="col-md-4">
                <div className="row">
                  <div
                    className="col p-4 border m-1 experience-item"
                    style={{ borderRadius: "50px 0px 50px 0px" }}
                  >
                    <img
                      src="../Images/transparency.png"
                      className="img-fluid p-1"
                      height="auto"
                      width="40px"
                      alt="transparency Icon"
                    />
                    <b>TRANSPARENCY</b>
                    <hr></hr>
                    <p>
                      Mannix Infotech believes in transparency. We are committed
                      to making business transactions in a cultured and ethical
                      way. We offer a healthy working atmosphere, where our
                      clients can express their needs.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col p-4 border m-1  experience-item1"
                    style={{ borderRadius: "50px 0px 50px 0px" }}
                  >
                    <img
                      src="../Images/experience.png"
                      className="img-fluid p-1"
                      height="auto"
                      width="40px"
                      alt="experience Icon"
                    />{" "}
                    <b>EXCELLENCE</b>
                    <hr></hr>
                    <p>
                      Our team incorporates individuals that have expertise in
                      the same. Our experts offer services considering every
                      little client's details. Their services and strategies
                      have a successful history.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div
                    className="col p-4 border m-1  experience-item1"
                    style={{ borderRadius: "50px 0px 50px 0px" }}
                  >
                    <img
                      src="../Images/process.png"
                      className="img-fluid p-1"
                      height="auto"
                      width="40px"
                      alt="process Icon"
                    />{" "}
                    <b> WORK PROCESS</b>
                    <hr></hr>
                    <p>
                      Our digital expertise knows no bounds and is increasing
                      day by day. Be it your marketing or expansion; Mannix
                      Infotech Solutions delivers excellence in every way. We
                      assure results keeping science and algorithm in mind.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col p-4 border m-1  experience-item"
                    style={{ borderRadius: "50px 0px 50px 0px" }}
                  >
                    <img
                      src="../Images/execution.png"
                      className="img-fluid p-1"
                      height="auto"
                      width="40px"
                      alt="execution Icon"
                    />{" "}
                    <b>WORK APPROACH</b>
                    <hr></hr>
                    <p>
                      Our work approach is in constant search of achievement.
                      Our strategies and modules guarantee business success and
                      have been satisfying clients for quite a few years
                      now.Work content is usually measured at Professionals.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="row">
                  <div
                    className="col p-4 border m-1  experience-item"
                    style={{ borderRadius: "50px 0px 50px 0px" }}
                  >
                    <img
                      src="../Images/soft-skills.png"
                      className="img-fluid p-1"
                      height="auto"
                      width="40px"
                      alt="soft-skills Icon"
                    />{" "}
                    <b>CAPABILITIES</b>
                    <hr></hr>
                    <p>
                      Our capabilities are reflected through our client's
                      success. Every work that we take in hand is well crafted
                      with experience, forefronts, and domain excellence. Such
                      works are fully proven and get delivered to clients.
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col p-4 border m-1  experience-item1"
                    style={{ borderRadius: "50px 0px 50px 0px" }}
                  >
                    <img
                      src="../Images/hand.png"
                      className="img-fluid p-1"
                      height="auto"
                      width="40px"
                      alt="hand Icon"
                    />{" "}
                    <b>CUSTOMIZATION</b>
                    <hr></hr>
                    <p>
                      The most essential service that we offer is customization.
                      We do not offer restricted structures for our clients. Our
                      flexible modules make space for every client's needs.We
                      take our time and consider every detail.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="animated-background"></div>
        </div>

        {/*---------------------------------------------- //Section5 */}
        <div
          className="container slider-container pt-5 mb-5"
          style={{ overflow: "hidden" }}
        >
          <div className="text-center p-2">
            <h2 className="fs-2">
              <b>OUR PROJECTS</b>
            </h2>
            <h4 className="pt-3">
              Mannix Infotech, a top web development company, is ready to take
              your business on the journey of success.
            </h4>
          </div>
          <Slider {...settings} className="px-5 mt-5">
            <div className="slider-item ">
              <img
                src="../Images/minaxipalace.png"
                className="img-fluid"
                alt="minaxipalacelogo"
              />
              <div className="">
                <p
                  className=" bottom-text"
                  style={{
                    backgroundColor: "#2980b9",
                    color: "white",
                  }}
                >
                  <b>Minaxi Palace Hotel</b>
                </p>
              </div>
            </div>
            <div className="slider-item">
              <img
                src="../Images/balaji.png"
                className="img-fluid"
                alt="balajilogo"
              />
              <div className="">
                <p
                  className=" bottom-text"
                  style={{
                    backgroundColor: "#2980b9",
                    color: "white",
                  }}
                >
                  <b>Balaji Manufacturing Company</b>
                </p>
              </div>
            </div>
            <div className="slider-item">
              <img
                src="../Images/nyshahypermart.png"
                className="img-fluid"
                alt="nyshahypermartlogo "
              />
              <div className="">
                <p
                  className=" bottom-text"
                  style={{
                    backgroundColor: "#2980b9",
                    color: "white",
                  }}
                >
                  <b>The Naisha Hypermart</b>
                </p>
              </div>
            </div>
            <div className="slider-item">
              <img
                src="../Images/balajimobilecare.png"
                className="img-fluid"
                alt="balajimobilecarelogo"
              />
              <div className="">
                <p
                  className=" bottom-text"
                  style={{
                    backgroundColor: "#2980b9",
                    color: "white",
                  }}
                >
                  <b>Balaji Mobile Care</b>
                </p>
              </div>
            </div>
            <div className="slider-item">
              <img
                src="../Images/mahaveer.png"
                className="img-fluid"
                alt="mahaveerlogo"
              />
              <div className="">
                <p
                  className=" bottom-text "
                  style={{
                    backgroundColor: "#2980b9",
                    color: "white",
                  }}
                >
                  <b>Mahaveer Lights</b>
                </p>
              </div>
            </div>
          </Slider>
        </div>
        <div className="d-flex justify-content-center mt-6  ">
          <a href="/portfolio">
            <button
              className="p-3 px-5 mt-4 border-0  text-white"
              style={{
                backgroundColor: "#e84118",
                borderRadius: "7px",
                letterSpacing: "1px",
              }}
            >
              VIEW ALL PROJECTS
            </button>
          </a>
        </div>

        {/*---------------------------------------------- //Section6 */}
        <div
          className="text-center mt-5"
          style={{ backgroundSize: "cover", padding: "15px 15px" }}
        >
          <h3 className="fs-2">
            <b> OUR LATEST TECHNOLOGIES</b>
          </h3>
          <p className="fs-4">TECHNOLOGIES - NOW ABOUT OUR WEAPONS</p>
          <div className="row d-flex mt-5">
            <div className="col-lg-6  ">
              <div className="second-bg d-flex flex-wrap justify-content-center">
                <div className="hover-bg border ">
                  <div className="" style={{ height: "100px", width: "160px" }}>
                    <img src="../Images/tec1.png" alt="tec1" />
                    <p>HTML5</p>
                  </div>
                </div>

                <div className=" border">
                  <div
                    className="mb-5"
                    style={{ height: "100px", width: "160px" }}
                  >
                    <img src="../Images/tec2.png" alt="tec2" />
                    <p>CSS3</p>
                  </div>
                </div>

                <div className="border hover-bg">
                  <div
                    className="mb-5"
                    style={{ height: "100px", width: "160px" }}
                  >
                    <img src="../Images/tec3.png" alt="tec3" />
                    <p>JAVASCRIPT</p>
                  </div>
                </div>

                <div className="border ">
                  <div
                    className="mb-5"
                    style={{ height: "100px", width: "160px" }}
                  >
                    <img src="../Images/tec4.png" alt="tec4" />
                    <p>PHP</p>
                  </div>
                </div>

                <div className="border hover-bg">
                  <div
                    className="mb-5"
                    style={{ height: "100px", width: "160px" }}
                  >
                    <img src="../Images/tec5.png" alt="tec5" />
                    <p>REACT</p>
                  </div>
                </div>

                <div className="border">
                  <div
                    className="mb-5"
                    style={{ height: "100px", width: "160px" }}
                  >
                    <img src="../Images/tec6.png" alt="tec6" />
                    <p>NODE.JS</p>
                  </div>
                </div>

                <div className="border hover-bg">
                  <div
                    className="mb-5"
                    style={{ height: "100px", width: "160px" }}
                  >
                    <img src="../Images/tec7.png" alt="tec7" />
                    <p>JAVA</p>
                  </div>
                </div>

                <div className="border">
                  <div
                    className="mb-5"
                    style={{ height: "100px", width: "160px" }}
                  >
                    <img src="../Images/tec8.png" alt="tec8" />
                    <p>PAYTHON</p>
                  </div>
                </div>

                <div className="border hover-bg">
                  <div
                    className="mb-5"
                    style={{ height: "100px", width: "160px" }}
                  >
                    <img src="../Images/tec9.png" alt="tec9" />
                    <p>ANDROID</p>
                  </div>
                </div>

                <div className="border ">
                  <div
                    className="mb-5"
                    style={{ height: "100px", width: "160px" }}
                  >
                    <img src="../Images/tec10.png" alt="tec10" />
                    <p>IPHONE</p>
                  </div>
                </div>

                <div className="border hover-bg">
                  <div
                    className="mb-5"
                    style={{ height: "100px", width: "160px" }}
                  >
                    <img src="../Images/tec11.png" alt="tec11" />
                    <p>POSTGRESQL</p>
                  </div>
                </div>

                <div className="border ">
                  <div
                    className="mb-5"
                    style={{ height: "100px", width: "160px" }}
                  >
                    <img src="../Images/tec11.png" alt="tec11" />
                    <p>MYSQL</p>
                  </div>
                </div>
                <div className="border ">
                  <div
                    className="mb-5"
                    style={{ height: "100px", width: "160px" }}
                  >
                    <img src="../Images/tec12.png" alt="tec12" />
                    <p>MS SQL SERVER</p>
                  </div>
                </div>

                <div className="border ">
                  <div
                    className="mb-5"
                    style={{ height: "100px", width: "160px" }}
                  >
                    <img src="../Images/tec13.png" alt="tec13" />
                    <p>FIREBASE</p>
                  </div>
                </div>
                <div className="border ">
                  <div
                    className="mb-5"
                    style={{ height: "100px", width: "160px" }}
                  >
                    <img src="../Images/tec14.png" alt="tec14" />
                    <p>.NET</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 ">
              <video
                className="img-fluid mt-5"
                height="400px"
                src="../Images/WhatsApp Video 2.mp4"
                autoPlay
                muted
                loop
              />
            </div>
          </div>
        </div>

        {/*---------------------------------------------- //Section8 */}

        <div
          className="slider-container2 pt-5 "
          style={{
            backgroundImage: 'url("../background4.jpg")',
            backgroundSize: "cover",
            backgroundPosition: "center",
            overflow: "hidden",
          }}
        >
          <div className="col-lg-12 col-md-12 col-sm-12 text-center p-2 text-danger">
            <h2 className="fs-2">
              <b>PORTFOLIO - GLIMPSE OF OUR WORK</b>
            </h2>
            <h4 className="fs-5 px-5">LET OUR PORTFOLIO REFLECT US</h4>
          </div>
          <div className="container col-lg-12 col-md-12 col-sm-12 px-5 mb-5">
            <Slider
              {...setting3}
              ref={sliderRef}
              className="owl d-none d-md-block"
            >
              <div className="slider-item2 d-flex">
                <img
                  src="../Images/main1.png"
                  className="mt-1"
                  style={{
                    height: "310px",
                    width: "506px",
                  }}
                  alt="main1"
                />
                <div
                  className="px-5 fs-1"
                  style={{ marginLeft: "20px", marginTop: "60px" }}
                >
                  <p>
                    <span>Education</span>
                    <br />
                    <span>App Development</span>
                  </p>
                  <p></p>
                </div>
              </div>
              <div className="slider-item2 d-flex ">
                <img
                  src="../Images/main2.png"
                  className="mt-1"
                  style={{ height: "310px", width: "506px" }}
                  alt="main2"
                />
                <div
                  className="px-5 fs-1"
                  style={{ marginLeft: "20px", marginTop: "60px" }}
                >
                  <p>
                    <span>Hotels </span>
                    <br />
                    <span>Web & App Development</span>
                  </p>
                </div>
              </div>
              <div className="slider-item2 d-flex">
                <img
                  src="../Images/main3.png"
                  className="mt-1"
                  style={{ height: "310px", width: "506px" }}
                  alt="main3"
                />
                <div
                  className="px-5 fs-1"
                  style={{ marginLeft: "20px", marginTop: "60px" }}
                >
                  <p>
                    <span>Shopping</span>
                    <br />
                    <span>Mobile App Development</span>
                  </p>
                </div>
              </div>
              <div className="slider-item2 d-flex">
                <img
                  src="../Images/main4.png"
                  className="mt-1"
                  style={{ height: "310px", width: "506px" }}
                  alt="main4"
                />
                <div
                  className="px-5 fs-1"
                  style={{ marginLeft: "20px", marginTop: "60px" }}
                >
                  <p>
                    <span>Manufacturing</span>
                    <br />
                    <span>Web Development</span>
                  </p>
                </div>
              </div>
              <div className="slider-item2 d-flex">
                <img
                  src="../Images/main5.png"
                  className="mt-1"
                  style={{ height: "310px", width: "506px" }}
                  alt="main5"
                />
                <div
                  className="px-5 fs-1"
                  style={{ marginLeft: "20px", marginTop: "60px" }}
                >
                  <p>
                    <span>Reparing</span>
                    <br />
                    <span>Web Development</span>
                  </p>
                </div>
              </div>
              <div className="slider-item2 d-flex">
                <img
                  src="../Images/main6.png"
                  className="mt-1 "
                  style={{ height: "310px", width: "506px" }}
                  alt="main6"
                />
                <div
                  className="px-5 fs-1"
                  style={{ marginLeft: "20px", marginTop: "60px" }}
                >
                  <p>
                    <span>LightHouse</span>
                    <br />
                    <span>Web Development</span>
                  </p>
                </div>
              </div>
            </Slider>
            <Slider {...setting3} ref={sliderRef} className="owl d-sm-none">
              <div className="slider-item2 d-flex">
                <img
                  src="../Images/main1.png"
                  className="mt-1"
                  // style={{ height: "310px", width: "506px" }}
                  alt="main1"
                />
                <div
                  className="px-5 fs-1"
                  style={{ marginLeft: "20px", marginTop: "60px" }}
                >
                  <p>
                    <span>Education</span>
                    <br />
                    <span>App Development</span>
                  </p>
                </div>
              </div>
              <div className="slider-item2 d-flex ">
                <img
                  src="../Images/main2.png"
                  className="mt-1"
                  // style={{ height: "310px", width: "506px" }}
                  alt="main2"
                />
                <div
                  className="px-5 fs-1"
                  style={{ marginLeft: "20px", marginTop: "60px" }}
                >
                  <p>
                    <span>Hotels </span>
                    <br />
                    <span>Web & App Development</span>
                  </p>
                </div>
              </div>
              <div className="slider-item2 d-flex">
                <img
                  src="../Images/main3.png"
                  className="mt-1"
                  // style={{ height: "310px", width: "506px" }}
                  alt="main3"
                />
                <div
                  className="px-5 fs-1"
                  style={{ marginLeft: "20px", marginTop: "60px" }}
                >
                  <p>
                    <span>Shopping</span>
                    <br />
                    <span>Mobile App Development</span>
                  </p>
                </div>
              </div>
              <div className="slider-item2 d-flex">
                <img
                  src="../Images/main4.png"
                  className="mt-1"
                  // style={{ height: "310px", width: "506px" }}
                  alt="main4"
                />
                <div
                  className="px-5 fs-1"
                  style={{ marginLeft: "20px", marginTop: "60px" }}
                >
                  <p>
                    <span>Manufacturing</span>
                    <br />
                    <span>Web Development</span>
                  </p>
                </div>
              </div>
              <div className="slider-item2 d-flex">
                <img
                  src="../Images/main5.png"
                  className="mt-1"
                  // style={{ height: "310px", width: "506px" }}
                  alt="main5"
                />
                <div
                  className="px-5 fs-1"
                  style={{ marginLeft: "20px", marginTop: "60px" }}
                >
                  <p>
                    <span>Reparing</span>
                    <br />
                    <span>Web Development</span>
                  </p>
                </div>
              </div>
              <div className="slider-item2 d-flex">
                <img
                  src="../Images/main6.png"
                  className="mt-1 "
                  // style={{ height: "310px", width: "506px" }}
                  alt="main6"
                />
                <div
                  className="px-5 fs-1"
                  style={{ marginLeft: "20px", marginTop: "60px" }}
                >
                  <p>
                    <span>LightHouse</span>
                    <br />
                    <span>Web Development</span>
                  </p>
                </div>
              </div>
            </Slider>
          </div>
          <div className="d-flex justify-content-center d-md-none">
            <div className="text-center m-2" onClick={handlePrev}>
              <i className="fa-solid fa-arrow-left-long fs-5"></i>
            </div>
            <div className="text-center m-2" onClick={handleNext}>
              <i className="fa-solid fa-arrow-right-long fs-5"></i>
            </div>
          </div>
        </div>

        {/*---------------------------------------------- //Section9 */}

        <section style={{ marginTop: "30px" }} className="text-center">
          <h3 className="fs-1">
            <b>Our Clients</b>
          </h3>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              className="sliderfootercontainer pb-5 "
              style={{ overflow: "hidden", maxWidth: "100%", width: "65%" }}
            >
              <Slider {...settings2}>
                <div className="sliderfooter" style={{ margin: "0 auto" }}>
                  <img
                    src="../Images/1.png"
                    alt="minaxipalacehotelclient/1"
                    style={{ width: "100%", padding: "25px" }}
                  />
                </div>
                <div className="sliderfooter" style={{ margin: "0 auto" }}>
                  <img
                    src="../Images/2.png"
                    alt="mahveerlights/2"
                    style={{ width: "100%", padding: "25px" }}
                  />
                </div>
                <div className="sliderfooter" style={{ margin: "0 auto" }}>
                  <img
                    src="../Images/3.png"
                    alt="vidhyabhawanschool/3"
                    style={{ width: "100%", padding: "25px" }}
                  />
                </div>

                <div className="sliderfooter" style={{ margin: "0 auto" }}>
                  <img
                    src="../Images/5.png"
                    alt="balajimanufacturing/5"
                    style={{ width: "100%", padding: "25px" }}
                  />
                </div>
                <div className="sliderfooter mt-5" style={{ margin: "0 auto" }}>
                  <img
                    src="../Images/balajimobile.png"
                    alt="balajimobileclient "
                    style={{ width: "100%", padding: "25px" }}
                  />
                </div>
                <div className="sliderfooter" style={{ margin: "0 auto" }}>
                  <img
                    src="../Images/logodesign8.jpg"
                    alt="hanfeelightsclient/logodesign8"
                    style={{ width: "100%", padding: "25px" }}
                  />
                </div>
                <div className="sliderfooter" style={{ margin: "0 auto" }}>
                  <img
                    src="../Images/logodesign9.png"
                    alt="tconnectclient/logodesign9"
                    style={{ width: "100%", padding: "25px" }}
                  />
                </div>
                <div className="sliderfooter" style={{ margin: "0 auto" }}>
                  <img
                    src="../Images/runisha.jpg"
                    alt="runishaclient"
                    style={{ width: "100%", padding: "25px" }}
                  />
                </div>
              </Slider>
            </div>
          </div>
        </section>

        {/*---------------------------------------------- //Section10 */}

        <div style={{ marginBottom: "-200px" }}>
          <div
            className=""
            style={{ backgroundColor: "#2980b9", height: "400px" }}
          >
            <div className="d-flex flex-column flex-md-row justify-content-center p-2 text-center text-md-left">
              <div className="px-3 mt-5 text-white">
                <h4 style={{ fontSize: "50px" }}>Our Latest Blogs</h4>
                <p style={{ fontSize: "20px" }} className="mb-5">
                  Keep abreast with the latest tools and technologies out there
                  in the digital world
                </p>
              </div>
              <div className="p-3">
                <a href="/blog">
                  <button
                    className="p-3 mt-3 mt-md-5 px-5 border-0 rounded text-dark "
                    style={{ backgroundColor: "#f6e58d" }}
                  >
                    View All <b>Blogs</b>
                    <i className="fa-solid fa-right-long px-2"></i>
                  </button>
                </a>
              </div>
            </div>
          </div>

          <div
            className="img-fluid  d-flex justify-content-center px-5"
            style={{
              flexWrap: "wrap",
              position: "relative",
              top: "-150px",
            }}
          >
            <div
              className=""
              style={{ flex: "1 1 300px", maxWidth: "400px", margin: "0 auto" }}
            >
              <div
                className="card"
                style={{
                  height: "",
                  backgroundColor: "#e7e8e9",
                  borderRadius: "20px",
                }}
              >
                <img
                  src="../Images/GraphicDesignBlog.jpg"
                  alt="GraphicDesignBlog"
                  style={{
                    height: "300px",
                    maxWidth: "100%",
                    borderRadius: "20px 20px 0 0",
                  }}
                  className="d-none d-md-block"
                />
                <img
                  src="../Images/GraphicDesignBlog.jpg"
                  alt="GraphicDesignBlog"
                  style={{
                    height: "200px",
                    maxWidth: "100%",
                    borderRadius: "20px 20px 0 0",
                  }}
                  className="d-md-none d-sm-block"
                />
                <p
                  className="p-2 text-center fw-bold"
                  style={{ fontSize: "19px" }}
                >
                  Graphic Designing: Creating Icons and Applications to Mould
                  the World
                </p>
                <p className="p-2 text-center" style={{ fontSize: "14px" }}>
                  The importance of graphic design has increased in the current
                  digital era. Eye-catching graphics are used by companies and
                  organisations to effectively and clearly communicate their
                  brand and message.
                </p>
                <div>
                  <a
                    href="/Graphic-Design-Blog"
                    className="fw-bold"
                    style={{
                      padding: "20px",
                      backgroundColor: "#e84118",
                      color: "white",
                      float: "right",
                      borderRadius: "0 0 20px 20px",
                      minWidth: "151px",
                      textAlign: "center",
                    }}
                  >
                    Continue <i className="fa fa-long-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className=""
              style={{ flex: "1 1 300px", maxWidth: "400px", margin: "0 auto" }}
            >
              <div
                className="card"
                style={{
                  height: "",
                  backgroundColor: "#e7e8e9",
                  borderRadius: "20px",
                }}
              >
                <img
                  src="../Images/OnlineMarketingBlog.png"
                  alt="OnlineMarketingBlog"
                  style={{
                    height: "300px",
                    maxWidth: "100%",
                    borderRadius: "20px 20px 0 0",
                  }}
                  className="d-none d-md-block"
                />
                <img
                  src="../Images/OnlineMarketingBlog.png"
                  alt="OnlineMarketingBlog"
                  style={{
                    height: "200px",
                    maxWidth: "100%",
                    borderRadius: "20px 20px 0 0",
                  }}
                  className="d-md-none d-sm-block"
                />
                <p
                  className="p-2 text-center fw-bold"
                  style={{ fontSize: "19px" }}
                >
                  The Power Of Online Marketing Services
                </p>
                <p className="p-2 text-center" style={{ fontSize: "14px" }}>
                  Digital marketing provides a wide range of advantages for
                  companies of all sizes and sectors, from elevating sales and
                  fostering client relationships to enhancing brand awareness
                  and website traffic.
                </p>
                <div>
                  <a
                    href="/online-marketing-services-blog"
                    className="fw-bold"
                    style={{
                      padding: "20px",
                      backgroundColor: "#e84118",
                      color: "white",
                      float: "right",
                      borderRadius: "0 0 20px 20px",
                      minWidth: "151px",
                      textAlign: "center",
                    }}
                  >
                    Continue <i className="fa fa-long-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className=""
              style={{ flex: "1 1 300px", maxWidth: "400px", margin: "0 auto" }}
            >
              <div
                className="card"
                style={{
                  height: "",
                  backgroundColor: "#e7e8e9",
                  borderRadius: "20px",
                }}
              >
                <img
                  src="../Images/MobileAppDevelopmentBlog.png"
                  alt="MobileAppDevelopmentBlog"
                  style={{
                    height: "300px",
                    maxWidth: "100%",
                    borderRadius: "20px 20px 0 0",
                  }}
                  className="d-none d-md-block"
                />
                <img
                  src="../Images/MobileAppDevelopmentBlog.png"
                  alt="MobileAppDevelopmentBlog"
                  style={{
                    height: "200px",
                    maxWidth: "100%",
                    borderRadius: "20px 20px 0 0",
                  }}
                  className="d-md-none d-sm-block"
                />
                <p
                  className="p-2 text-center fw-bold"
                  style={{ fontSize: "19px" }}
                >
                  Top Mobile App Development Companies in Ahmedabad
                </p>
                <p className="p-2 text-center" style={{ fontSize: "14px" }}>
                  Mannix Infotech Solutions Pvt. Ltd., headquartered in
                  Ahmedabad, Gujarat, India, isn't just a web development
                  powerhouse. They're a team of experts passionate about
                  crafting next-level mobile applications.
                </p>
                <div>
                  <a
                    href="/mobile-app-development-blog"
                    className="fw-bold"
                    style={{
                      padding: "20px",
                      backgroundColor: "#e84118",
                      color: "white",
                      float: "right",
                      borderRadius: "0 0 20px 20px",
                      minWidth: "151px",
                      textAlign: "center",
                    }}
                  >
                    Continue <i className="fa fa-long-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
