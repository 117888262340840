import React, { useState } from "react";
import { Helmet } from "react-helmet";

const ERPManagment = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>
          ERP Software Development Company | ERP Management Solutions | Mannix
          Infotech solution in Ahmedabad
        </title>
        <meta
          name="description"
          content=" streamline business operations with Mannix Infotech's custom ERP software development services. Our experts design and develop tailored ERP management solutions to enhance efficiency, productivity, and decision-making. Located in Ahmedabad, Gujarat, India."
        />
        <meta
          name="keywords"
          content="Top ERP Management Software Development Company  in India |Best ERP Management Software Development Company in Gujarat |Best ERP Management Software Development Company in Ahmedabad | List of company ERP Management Software Development services in Ahmedabad | ERP Management Software Development services near me | ERP Management Software Development company near by| ERP Management Software Development company near me| ERP Management Software Development | Top 5 ERP Management Software Development company in Ahmedabad"
        />

        <link
          rel="canonical"
          href="https://www.mannix.in/erp-management-software-services"
        />
      </Helmet>
      <section className="banner-content-1">
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          className="position-absolute top-banner-bg"
          viewBox="0 0 1920 761"
        >
          <g>
            <defs>
              <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
            </defs>
            <clipPath id="SVGID_2_">
              <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
            </clipPath>
            <g className="banner-bg-asw">
              <path
                className="st1"
                d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                            c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
              ></path>
              <path
                className="st2"
                d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                            c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
              ></path>
              <path
                className="st1"
                d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
              ></path>
              <path
                className="st2"
                d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
              ></path>
              <circle className="st1" cx="1536.8" cy="661.3" r="56.2"></circle>
              <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
            </g>
          </g>
        </svg>
        <div className="py-5 container">
          <div className="row align-items-center justify-content-around mt-4">
            <div className="col-lg-6">
              <div className="">
                <h1 className="head-title head-title-without-line m-0 py-3">
                  ERP Software Development
                </h1>
                <p className="m-0 py-3">
                  Once there comes a time when entrepreneurs cannot handle all
                  the business issues on their own. Supply chain, accounting,
                  risk management, and alternative operations need high-level
                  management and deep experience within the niche.. This creates
                  a demand for experienced staff and new jobs.
                </p>
              </div>
            </div>
            <div className="col-lg-5 prod-section2">
              <div className="">
                <form
                  className="register-form"
                  id="register-form"
                  method="post"
                  action=""
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="form_type"
                    id="form_type"
                    value="Android App Development"
                  />
                  <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                    <div className="d-flex">
                      <div className="w-100 mr-2">
                        <label className="w-100">
                          <span>Name</span>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name *"
                            className="form-control"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div className="w-100">
                        <label className="w-100">
                          <span>Email</span>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email id *"
                            className="form-control"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className="d-flex">
                      <label className="w-100">
                        <span>Contact</span>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Contact No *"
                          className="form-control"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Country</span>
                        <input
                          type="text"
                          name="country"
                          id="country"
                          placeholder="Enter your Country"
                          className="form-control"
                          value={formData.country}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Message</span>
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Enter your message *"
                          className="form-control"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </label>
                    </div>

                    <div className="">
                      <button
                        type="submit"
                        name="submit"
                        className="submit btn w-100 g-recaptcha-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="hire-dedicated services mt-5">
        <section>
          <div className="hire-dedicated-2 container py-4">
            <div className="pb-4 text-center">
              <h2 className="head-title">Modules of ERP Software</h2>
            </div>
            <div className="row">
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-bars"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">
                        Inventory and warehouse management
                      </h2>
                      <p className="m-0 py-2">
                        This module creates experimental reports concerning
                        non-capitalized stock and assets. It's necessary to
                        determine the health of the availability chain and
                        finance of the record. This module offers a efficient
                        work flow, tracks the stock account, and minimizes
                        complexities.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-bars"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fab fa-searchengin"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">
                        Customer relationship management
                      </h2>
                      <p className="m-0 py-2">
                        This module increases productivity, provides exceptional
                        customer service and provides access to the revenue
                        performance of the company.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fab fa-searchengin"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-child"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">Human Resource Management</h2>
                      <p className="m-0 py-2">
                        This module automates basic HR operations, such as
                        time-tracking and profile filtering. It puts an end to a
                        time-consuming task. It improves operational efficiency
                        and self-service portals streamline the communication
                        and approval process. Tracking performance management
                        also becomes easy.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-child"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-user-friends"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">
                        {" "}
                        Accounting and financial management
                      </h2>
                      <p className="m-0 py-2">
                        {" "}
                        This module helps to increase business productivity and
                        helps you stay organized with your business processes.
                        It automates financial transactions and tracks profits.
                        It generates custom reports at regular intervals to
                        effectively manage business data.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-user-friends"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <section className="why_choose_online why_choose_blockchain">
        <div className="container">
          <div className="why_choose_title why_choose_us">
            Why is ERP software crucial for your business?
          </div>
          <br />
          <div className="row">
            <div className="col-sm-4">
              <div className="choose_food choose_food_2">
                <h3>EFFICIENCY</h3>
                <p className="text-justify">
                  With our ERP development company, you'll be able to eliminate
                  repetitive processes. ERP helps to reduce the need for manual
                  data entry. In this way, the opstem streamlines business
                  processes, which facilitate data collection.
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="choose_food choose_food_2">
                <h3>SECURITY</h3>
                <p className="text-justify">
                  With a new opstem, you get better data accuracy, consistency,
                  and security, thus improving data restrictions. The enterprise
                  software development process includes software testing and
                  quality assurance to ensure data privacy.
                </p>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="choose_food choose_food_2">
                <h3>CUSTOMER SERVICE</h3>
                <p className="text-justify">
                  When you contact us for ERP application development, we
                  provide a opstem that helps you enjoy high-quality customer
                  service, much easier for salespeople and customer service to
                  interact with customers.
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="choose_food choose_food_2">
                <h3>INTEGRATED INFORMATION</h3>
                <p className="text-justify">
                  With our ERP applications, you don’t have to divide the data
                  into many databases and you will find all the information in
                  one place. This way, the data is consistent and up to date.
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="choose_food choose_food_2">
                <h3>REPORTS</h3>
                <p className="text-justify">
                  With enhanced reporting capabilities, it is easy for your
                  business to respond to complex data requests very easily. ERP
                  software developers ensure that users are allowed to run their
                  reports without needing to rely on IT help.
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="choose_food choose_food_2">
                <h3>REDUCED OPERATING COSTS</h3>
                <p className="text-justify">
                  Yes, lower operating costs are among the most immediate
                  benefits of implementing an ERP, such as lower inventory
                  control costs, lower production costs, and lower advertising
                  and marketing costs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="company-offer3">
        <div className="container">
          <div className="theme-title">
            {" "}
            <strong>
              What makes Mannix Infotech Solutions Pvt.Ltd. the best ERP
              Software Development Company?{" "}
            </strong>
            <p className="text-justify">
              At Mannix Infotech Solutions Pvt.Ltd., we have been providing ERP
              solutions for years. We have the goodwill to be one of the best
              ERP software developers. If you are looking for innovative
              features in your ERP software, you have come to the right place.
              We offer solutions that are easy to integrate into your current
              HRIS and can help you streamline your business operations. The
              resource pool is the greatest asset of any organization and its
              proper management requires a lot of patience and effort. With ERP
              application development experts at your service, we make sure your
              resources aren’t wasted for once.
            </p>
            <p className="text-justify">
              Here we share some distinctive points that make us the best in the
              market:
            </p>
            <ul className="list-style-blt">
              <li>Personalized advice.</li>
              <li>On-time delivery.</li>
              <li>Expert team.</li>
              <li>End-to-end services.</li>
              <li>Best price.</li>
              <li>Easy customizations.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ERPManagment;
