import React from "react";
import { Helmet } from "react-helmet";

const Internship = () => {
  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.target;

    if (form.checkValidity() === false) {
      form.classList.add("was-validated");
      return;
    }

    const name = e.target.name.value;
    const email = e.target.email.value;
    const phone = e.target.phone.value;
    const city = e.target.city.value;
    const collegeName = e.target.college_name.value;
    const courseStream = e.target.course_stream.value;
    const internshipField = e.target.internship_field.value;

    const message = `Name: ${name}%0AEmail: ${email}%0APhone: ${phone}%0ACity: ${city}%0ACollege Name: ${collegeName}%0ACourse Stream: ${courseStream}%0AInternship Field: ${internshipField}`;

    const whatsappLink = `https://wa.me/9664053983?text=${message}`;

    window.open(whatsappLink, "_blank");
  };

  return (
    <>
      <Helmet>
        <title>
          Build your Future with Mannix Infotech Solutions | Internship Program
        </title>
        <meta
          name="description"
          content="Explore top - notch internship with the best performance marketing solutions act mannix
infotech solutions, the leading top digital marketing company in ahmedabad. Join now"
        />
        <meta
          name="keywords"
          content="mannix infotech solutions Pvt.Ltd.|web development company in India|web development services Gujarat|web development internship in Ahmedabad|website developer company near by|web solutions company near me|web and app development company in ahmedabad|best web development company|web development india|best website developer|web development company in india|web designing services in ahmedabad|it company website|web designing company in ahmedabad|web design company in ahmedabad|web app development services in ahmedabad|web app development company in ahmedabad|best web development company in india|web and mobile app development near me|web application development company in ahmedabad|professional web development services in ahmedabad|web development in the future|software & web development in ahmedabad|benefits of web development services near me|software development and digital marketing company|mobile web development company in ahmedabad|
"
        />

        <link rel="canonical" href="https://www.mannix.in/internship" />
      </Helmet>

      <div>
        <div className="position-relative">
          <img
            src="../InternBanner.jpg"
            alt="InternBanner"
            className="banner img-fluid"
            style={{ height: "400px", width: "100%" }}
          />
          <div
            className="position-absolute "
            style={{ top: "36%", left: "10%" }}
          >
            <h2 className="fw-bold">
              Build Your Future with Mannix Infotech Solutions Pvt.Ltd. <br />{" "}
              Internship Programme
            </h2>
            <p style={{ marginTop: "20px" }}>
              Professional Internship Program for B.Tech, M.Tech, BCA, MCA,
              Passout and Pursuing Students
            </p>
          </div>
        </div>
        <div className="intern_heading">
          <div className="container">
            <h2>About Mannix Infotech Solutions Pvt.Ltd.</h2>
          </div>
        </div>

        <section className="intern_cnt padding-top">
          <div className="container">
            <div className="intern_para">
              <p>
                Mannix Infotech Solutions Pvt.Ltd. is one of the renowned IT
                Company in India offering a wide variety of IT Services that
                includes Web Development, Mobile App Development, Software
                Development, Game App Development, Graphic Designing, Web
                Designing and Digital Marketing Services to their clients around
                the globe.
              </p>
            </div>
          </div>
        </section>
        <div className="intern_heading">
          <div className="container">
            <h2>Benefits of Joining Our Internship Programme</h2>
          </div>
        </div>

        <div className="career_formus p-3">
          <div className="row d-flex justify-content-center mt-5">
            <div className="col-lg-6 col-md-6 col-sm-6 ">
              <div className="intern_img clearfix">
                <div className="icon">
                  <img src="../int_Programme1.png" alt="int_Programme1" />
                </div>
                <div className="cnt pt-3">
                  <h4>Job experience</h4>
                </div>
              </div>

              <div className="intern_img clearfix ">
                <div className="icon">
                  <img src="../int_Programme2.png" alt="int_Programme2" />
                </div>
                <div className="cnt pt-3">
                  <h4>Research experience</h4>
                </div>
              </div>

              <div className="intern_img clearfix ">
                <div className="icon">
                  <img src="../int_Programme3.png" alt="int_Programme3" />
                </div>
                <div className="cnt pt-3">
                  <h4>Access to a variety of tasks and departments</h4>
                </div>
              </div>

              <div className="intern_img clearfix ">
                <div className="icon">
                  <img src="../int_Programme4.png" alt="int_Programme4" />
                </div>
                <div className="cnt pt-3">
                  <h4>Create a professional network</h4>
                </div>
              </div>

              <div className="intern_img clearfix ">
                <div className="icon">
                  <img src="../int_Programme5.png" alt="int_Programme5" />
                </div>
                <div className="cnt pt-3">
                  <h4>Secure good references and recommendations</h4>
                </div>
              </div>

              <div className="intern_img clearfix ">
                <div className="icon">
                  <img src="../int_Programme6.png" alt="int_Programme6" />
                </div>
                <div className="cnt pt-3">
                  <h4>Build a strong resume</h4>
                </div>
              </div>
              <div className="intern_img clearfix mx-auto">
                <div className="icon">
                  <img src="../int_Programme7.png" alt="int_Programme7" />
                </div>
                <div className="cnt pt-3">
                  <h4>Build confidence</h4>
                </div>
              </div>
            </div>

            <div className="col-lg-5 col-md-6 col-xs-12 mt-5">
              <div className="faq-form-wrapper form-bg">
                <h4>Fill The Following Form</h4>
                <div className="tab-content">
                  <div id="fortab" className="tab-pane fade in active">
                    <div className="faq-form-wrapper-2">
                      <form
                        className="career_form form"
                        id="career_form"
                        action=""
                        method="post"
                        encType="multipart/form-data"
                        noValidate="novalidate"
                        required
                        onSubmit={handleSubmit}
                      >
                        <input
                          type="hidden"
                          name="type"
                          id="type"
                          value="Job"
                          className="form-control"
                          required
                        />
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Your Name*"
                          id="name"
                          name="name"
                          required
                          aria-required="true"
                        />
                        <input
                          className="form-control"
                          type="email"
                          placeholder="Your Email*"
                          id="email"
                          name="email"
                          required
                          aria-required="true"
                        />
                        <input
                          className="form-control"
                          type="tel"
                          placeholder="Your mobile*"
                          id="phone"
                          name="phone"
                          required
                          aria-required="true"
                        />
                        <input
                          className="form-control"
                          type="text"
                          placeholder="City*"
                          id="city"
                          name="city"
                          required
                          aria-required="true"
                        />
                        <input
                          className="form-control"
                          type="text"
                          placeholder="College Name*"
                          id="college_name"
                          name="college_name"
                          required
                          aria-required="true"
                        />
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Course Stream*"
                          id="course_stream"
                          name="course_stream"
                          required
                        />
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Internship Field*"
                          id="internship_field"
                          name="internship_field"
                          required
                        />
                        <div
                          className="g-recaptcha"
                          data-sitekey="6Le4E1UlAAAAANinY7VXkfc-IVoqm_q9bnlhMbO-"
                          style={{ float: "left", marginBottom: "10px" }}
                        ></div>
                        <button
                          type="submit"
                          name="submit"
                          className="p-bg-color hvr-float-shadow "
                        >
                          SUBMIT DETAILS
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Internship;
