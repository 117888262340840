import React, { useState } from "react";
import { Helmet } from "react-helmet";

const SeoSMOService = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>Expert SEO and SMO Services</title>
        <meta
          name="description"
          content="Our digital marketing agency specializes in comprehensive SEO and SMO services to boost your online presence. 
3.IT Consultant Company"
        />
        <meta
          name="keywords"
          content="Online marketing solutions|Social media marketing|SEO services|SMO services|Website optimization"
        />

        <link
          rel="canonical"
          href="https://www.mannix.in/seo-smo-service-provider-services"
        />
      </Helmet>
      <section className="banner-content-1">
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          className="position-absolute top-banner-bg"
          viewBox="0 0 1920 761"
        >
          <g>
            <defs>
              <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
            </defs>
            <clipPath id="SVGID_2_">
              <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
            </clipPath>
            <g className="banner-bg-asw">
              <path
                className="st1"
                d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                            c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
              ></path>
              <path
                className="st2"
                d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                            c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
              ></path>
              <path
                className="st1"
                d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
              ></path>
              <path
                className="st2"
                d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
              ></path>
              <circle className="st1" cx="1536.8" cy="661.3" r="56.2"></circle>
              <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
            </g>
          </g>
        </svg>
        <div className="py-5 container">
          <div className="row align-items-center justify-content-around mt-4">
            <div className="col-lg-6">
              <div className="">
                <h1 className="head-title head-title-without-line m-0 py-3">
                  SEO-SMO Service Provider
                </h1>
                <p className="m-0 py-3">
                  Mannix Infotech Solutions Pvt.Ltd. is a dynamic, versatile,
                  full-service digital marketing agency that doesn’t rely on
                  smoke and mirrors to attract new clients.Instead, Thrive
                  depends on its own Search Engine Optimization Services (SEO)
                  and promoting skills to bring new customers to our web site.
                </p>
                <p className="m-0 py-3">
                  Mannix Infotech Solutions Pvt.Ltd. works with you to produce
                  personalized digital marketing services and a plan that
                  matches your unique business needs. We become an extension of
                  your team, a partner who understands your market and your
                  goals, rather than just an agency executing strategies
                  blindly.
                </p>
              </div>
            </div>
            <div className="col-lg-5 prod-section2">
              <div className="">
                <form
                  className="register-form"
                  id="register-form"
                  method="post"
                  action=""
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="form_type"
                    id="form_type"
                    value="Android App Development"
                  />
                  <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                    <div className="d-flex">
                      <div className="w-100 mr-2">
                        <label className="w-100">
                          <span>Name</span>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name *"
                            className="form-control"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div className="w-100">
                        <label className="w-100">
                          <span>Email</span>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email id *"
                            className="form-control"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className="d-flex">
                      <label className="w-100">
                        <span>Contact</span>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Contact No *"
                          className="form-control"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Country</span>
                        <input
                          type="text"
                          name="country"
                          id="country"
                          placeholder="Enter your Country"
                          className="form-control"
                          value={formData.country}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Message</span>
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Enter your message *"
                          className="form-control"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </label>
                    </div>

                    <div className="">
                      <button
                        type="submit"
                        name="submit"
                        className="submit btn w-100 g-recaptcha-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="common_textbox">
        <div className="container  wow zoomIn" data-wow-delay="0.2s">
          <h2 className="min_title">DIGITAL MARKETING COMPANY-</h2>
          <p className="text-justify">
            Mannix Infotech Solutions Pvt.Ltd. is a dynamic, versatile,
            full-service digital marketing agency that doesn’t rely on smoke and
            mirrors to attract new clients.Instead, Thrive depends on its own
            search engine optimization (SEO) and promoting skills to bring new
            customers to our web site.
          </p>
          <p className="text-justify">
            We are not a static business. We don't limit ourselves to specific
            sectors.. Thrive has the experience and professionals to build a
            personalized website and use multiple digital marketing services to
            help businesses of all sizes in all industries.
          </p>
          <p className="text-justify">
            Mannix Infotech Solutions Pvt.Ltd. works with you to produce
            personalized digital marketing services and a plan that matches your
            unique business needs. We become an extension of your team, a
            partner who understands your market and your goals, rather than just
            an agency executing strategies blindly.
          </p>
        </div>
      </section>
      <section className="common_textbox">
        <div className="container  wow zoomIn" data-wow-delay="0.2s">
          <h2 className="min_title">
            Social Media Optimization Services (SMO)-
          </h2>
          <p className="text-justify">
            Social media platforms like Facebook, Google Plus, and Twitter are
            great places to develop customers and generate leads. At Velocity,
            we work hard to make the interaction between you and your customers
            a rewarding experience. We have housed a team of experts who have
            the skills and experience to add value to your website.
          </p>
        </div>
      </section>
      <div className="company-offer3">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12">
              <div className="theme-title">
                {" "}
                <strong>
                  Advantages of opting for us as an SEO service provider -
                </strong>
                <ul className="list-style-blt">
                  <li>SEO will improve your website.</li>
                  <li>SEO companies have experience.</li>
                  <li>SEO companies get the best results.</li>
                  <li>
                    Outsourcing your SEO allows you to focus on what you do
                    best.
                  </li>
                  <li>
                    Full-Service Companies Can Help You With More Than SEO
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-sm-12">
              <div className="theme-title">
                {" "}
                <strong>Working process of our SEO Services-</strong>
                <ul className="list-style-blt">
                  <li>Research</li>
                  <li>Reporting and goal setting</li>
                  <li>Content building</li>
                  <li>Page optimization</li>
                  <li>Social and link building</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="common_textbox">
        <div className="container  wow zoomIn" data-wow-delay="0.2s">
          <h2 className="min_title">SMO</h2>
          <p className="text-justify">
            Mannix Infotech Solutions Pvt.Ltd. is one of the experienced social
            media optimization companies in India that strives to promote your
            business and brand on top social media platforms and drive most web
            traffic to your website. We have social media optimization experts
            on our team who have helped hundreds of businesses gain brand
            visibility and leads from social media channels.
          </p>
        </div>
      </section>
      <div className="custom_contact_us">
        <div className="container">
          <h3 className="contact-heading">
            To have this working strategy for your business
          </h3>
          <a href=" " className="send_qbtn">
            Let’s have a quick discussion
          </a>
        </div>
      </div>
      <section className="why_choose_online why_choose_blockchain mt-4">
        <div className="container">
          <div className="why_choose_title why_choose_us">
            <span> Why Choose </span> us as digital marketing experts?{" "}
          </div>
          <div className="row">
            <div className="col-sm-4">
              <div className="choose_food">
                {" "}
                <span className="notifications_phone_icon">
                  <img src="../Images/seo1.png" alt="seo1" />
                </span>
                <h3>You talk, we listen</h3>
                <p className="text-justify">
                  As a digital marketing agency, we strive first to understand
                  the business goals of our clients. Then all choices are
                  created with those goals in mind.A shiny new website is
                  good-for-nothing if it doesn’t assist you succeed your goals.
                  You talk, we listen… then we throw out lots of ideas for
                  improvement.
                </p>
                <br />
              </div>
            </div>
            <div className="col-sm-4">
              <div className="choose_food">
                {" "}
                <span className="notifications_phone_icon">
                  <img src="../Images/seo2.png" alt="seo2" />
                </span>
                <h3>We know SEO</h3>
                <p className="text-justify">
                  Search engine optimisation is that the art of being found in
                  search engines like Yahoo,Google, and Bing.Unless your site
                  ranks high on search engines, potential customers will do
                  business elsewhere. Many of our clients pay for their entire
                  website in the first month from new business leads because of
                  our SEO efforts.
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="choose_food">
                {" "}
                <span className="notifications_phone_icon">
                  <img src="../Images/seo3.png" alt="seo3" />
                </span>
                <h3>We create exceptional designs</h3>
                <p className="text-justify">
                  We have chosen to only create unique web designs (no templates
                  here). Our goal is to position your business as a leading
                  player in your industry and to outperform all competitors.
                  professional web design builds credibility with all
                  customers(existing and potential) and ultimately leads to
                  future growth.
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </section>
      ;
    </div>
  );
};

export default SeoSMOService;
