import React from "react";

const Blog2 = () => {
  return (
    <div>
      <div>
        <section
          className="ops_blog_header"
          style={{
            background: "url('../blogbanner.jpg')",
            height: "270px",
            backgroundSize: "cover !important",
            padding: "80px 0",
          }}
        >
          <div className="container">
            <div className="ops_blog_header_content">
              <h1 className="fw-bold text-danger">
                Unlocking the Secrets: Dive into the World of Mannix Infotech
                Solutions Pvt.Ltd.
              </h1>
            </div>
          </div>
        </section>

        <section>
          <div className=" d-flex flex-wrap p-5">
            <div className="col-lg-8 d-flex flex-wrap justify-content-center">
              <div
                className="mb-3 px-5"
                style={{
                  //   flex: "1 1 300px",
                  //   maxWidth: "300px",
                  margin: "0 auto",
                  width: "100%",
                }}
              >
                <div
                  className="card"
                  style={{
                    height: "",
                    backgroundColor: "#d1d8e0",
                    borderRadius: "20px",
                    width: "100%",
                  }}
                >
                  <img
                    src="../Images/OnlineMarketingBlog.png"
                    alt="OnlineMarketingBlog"
                    style={{ maxWidth: "100%", borderRadius: "20px 20px 0 0" }}
                  />
                  <p className="p-2 text-center" style={{ fontSize: "25px" }}>
                    The Power Of Online Marketing Services
                  </p>
                  <p className="p-2 text-center" style={{ fontSize: "16px" }}>
                    Digital marketing provides a wide range of advantages for
                    companies of all sizes and sectors, from elevating sales and
                    fostering client relationships to enhancing brand awareness
                    and website traffic.
                  </p>
                  <div className="p-3" style={{ fontSize: "16px" }}>
                    <p>
                      The importance of successful marketing techniques in the
                      current digital world cannot be emphasized. Businesses
                      must adapt and take use of digital marketing services to
                      stay relevant and competitive in the market, given the
                      growing dependence of consumers and businesses on digital
                      platforms for communication, entertainment, and commerce.
                      With its unmatched potential for development, engagement,
                      and brand visibility, digital marketing has emerged as the
                      key to success for businesses of all sizes, from small
                      startups to global conglomerates.
                    </p>
                    <div>
                      <h2 style={{ fontSize: "24px" }}>
                        The Evolution of Marketing:
                      </h2>
                      <p>
                        In the past, traditional marketing strategies like
                        billboards, television advertisements, and print ads
                        dominated the advertising market. But the development of
                        social media and the internet have completely changed
                        how companies interact with their customers. Digital
                        marketing is a vast field that includes a wide range of
                        strategies and platforms, such as pay-per-click (PPC)
                        advertising, email marketing, content marketing, social
                        media marketing, and search engine optimization (SEO).
                        Important Elements of Services for Digital Marketing:
                      </p>
                    </div>
                    <div className="mt-3">
                      <h2 style={{ fontSize: "24px" }}>
                        Search Engine Optimization :{" "}
                      </h2>
                      <p>
                        Improving a website's exposure and position on search
                        engine results pages (SERPs) is mostly dependent on SEO.
                        Through the optimization of website content, keywords,
                        and metadata, companies can raise their search engine
                        ranking organically and draw in targeted traffic.
                      </p>
                    </div>
                    <div className="mt-3">
                      <h2 style={{ fontSize: "24px" }}>Content Marketing :</h2>
                      <p>
                        In addition to capturing the audience's attention,
                        captivating and educational information builds authority
                        and credibility in the field. Blogs, articles, videos,
                        infographics, and other types of material that are
                        geared toward connecting with the target audience are
                        all included in content marketing.
                      </p>
                    </div>
                    <div className="mt-3">
                      <h2 style={{ fontSize: "24px" }}>
                        Social Media Marketing :
                      </h2>
                      <p>
                        With billions of people using social media every day on
                        a variety of platforms, social media marketing presents
                        businesses with chances never seen before to engage
                        their audience personally. By utilizing targeted
                        advertising, community involvement, and strategic
                        content production, companies may increase website
                        traffic, lead generation, and brand exposure.
                      </p>
                    </div>
                    <div className="mt-3">
                      <h2 style={{ fontSize: "24px" }}>Email Marketing :</h2>
                      <p>
                        Email marketing continues to be an effective strategy
                        for fostering client relationships and increasing
                        conversion rates, even in the face of new communication
                        methods. Using personalized email campaigns, customers
                        can receive updates, promotions, and pertinent material
                        straight from the brand, increasing repeat business and
                        brand loyalty.
                      </p>
                    </div>

                    <div className="mt-3">
                      <h3 style={{ fontSize: "25px" }}>
                        The Impact of Digital Marketing Services:
                      </h3>
                      <h2 style={{ fontSize: "24px" }}>Global Reach:</h2>
                      <p>
                        Because digital marketing transcends geographic
                        boundaries, it enables businesses to reach a global
                        audience, including those in the United Arab Emirates,
                        at a relatively low cost. Whether targeting new markets
                        or local consumers, digital marketing has unparalleled
                        scalability and reach.
                      </p>
                    </div>
                    <div className="mt-3">
                      <h2 style={{ fontSize: "24px" }}>
                        Measurable Results :{" "}
                      </h2>
                      <p>
                        Digital marketing, in contrast to traditional marketing
                        techniques, offers a wealth of measurements and
                        statistics that allow real-time campaign performance
                        tracking. Businesses may obtain important insights into
                        the success of their marketing initiatives and adjust
                        strategies accordingly by monitoring anything from
                        website traffic and engagement metrics to conversion
                        rates and return on investment.
                      </p>
                    </div>
                    <div className="mt-3">
                      <h2 style={{ fontSize: "24px" }}>
                        Enhanced Customer Engagement :{" "}
                      </h2>
                      <p>
                        Digital marketing facilitates bidirectional contact
                        between companies and customers, enabling tailored
                        exchanges and input. Through the utilization of blogs,
                        social media platforms, and other digital channels,
                        organizations can have meaningful conversations with
                        their audience and promptly answer their wants and
                        concerns.
                      </p>
                    </div>
                    <div className="mt-3">
                      <h2 style={{ fontSize: "24px" }}>
                        Cost Effectiveness :{" "}
                      </h2>
                      <p>
                        Digital marketing has a cheaper cost per acquisition and
                        a higher return on investment when compared to
                        traditional advertising methods. Businesses may get more
                        out of their marketing budget and produce better
                        outcomes with fewer resources by utilizing flexible
                        budgeting and targeted advertising alternatives.
                      </p>
                    </div>
                    <div className="mt-3">
                      <h2 style={{ fontSize: "24px" }}>
                        Competitive Advantage :{" "}
                      </h2>
                      <p>
                        A strong digital presence is necessary to keep ahead of
                        the competition in today's cutthroat market. Companies
                        that use digital marketing services can stand out from
                        the competition, develop their brands, and get a
                        competitive advantage.
                      </p>
                    </div>
                    <div className="mt-3">
                      <h3 style={{ fontSize: "25px" }}>Conclusion : </h3>
                      <p>
                        In order for organizations to succeed in the digital
                        age, digital marketing services have become essential
                        tools. Digital marketing provides a wide range of
                        advantages for companies of all sizes and sectors, from
                        elevating sales and fostering client relationships to
                        enhancing brand awareness and website traffic. Within
                        the ever-changing realm of digital marketing,
                        organizations can discover new avenues for expansion,
                        creativity, and success by utilizing SEO, content
                        marketing, social media, email campaigns, and PPC
                        advertising.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div>
                <p className="fs-4">Categories</p>
                <ul>
                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> App & Software Development </span>
                    </a>
                  </li>
                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> Graphic Designing</span>
                    </a>
                  </li>
                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> Web Development </span>
                    </a>
                  </li>
                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> Online Marketing </span>
                    </a>
                  </li>

                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> Digital Marketing </span>
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <p className="fs-4">Recent posts</p>
                <div>
                  <div
                    className="p-1 recent"
                    style={{
                      backgroundColor: "#e7e8e9",
                      borderBottom: "solid 1px #eeeee",
                    }}
                  >
                    <a
                      href="web-development-blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/WebDevelopmentBlog.png"
                          alt="WebDevelopmentBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>Top 10 Web Development Companies </span>
                      </div>
                    </a>
                  </div>
                  <div
                    className="p-1 recent mt-2"
                    style={{ backgroundColor: "#e7e8e9" }}
                  >
                    <a
                      href="digital-marketing-agency-blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/DigitalMarketingBlog.png"
                          alt="DigitalMarketingBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>Top 10 Digital Marketing Agency </span>
                      </div>
                    </a>
                  </div>
                  <div
                    className="p-1 recent mt-2"
                    style={{
                      backgroundColor: "#e7e8e9",
                      borderBottom: "solid 1px #eeeee",
                    }}
                  >
                    <a
                      href="Graphic-Design-Blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/GraphicDesignBlog.jpg"
                          alt="GraphicDesignBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>Graphic Designing</span>
                      </div>
                    </a>
                  </div>
                  <div
                    className="p-1 recent mt-2"
                    style={{
                      backgroundColor: "#e7e8e9",
                      borderBottom: "solid 1px #eeeee",
                    }}
                  >
                    <a
                      href="online-marketing-services-blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/DigitalMarketingBlog.png"
                          alt="DigitalMarketingBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>The Power Of Online Marketing Services</span>
                      </div>
                    </a>
                  </div>
                  <div
                    className="p-1 recent mt-2"
                    style={{
                      backgroundColor: "#e7e8e9",
                      borderBottom: "solid 1px #eeeee",
                    }}
                  >
                    <a
                      href="mobile-app-development-blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/MobileAppDevelopmentBlog.png"
                          alt="MobileAppDevelopmentBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>Top Mobile App Development Companies</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div>
                <p className="fs-4 mt-4">Social links</p>
                <ul className="d-flex flex-wrap gap-3">
                  <li style={{ listStyle: "none" }}>
                    <a
                      href="https://www.facebook.com/profile.php?id=61558304945053"
                      target="_blank"
                      rel="noreferrer"
                      title="Facebook"
                      style={{
                        padding: " 9px 8px",
                        display: "block",
                        fontSize: "17px",
                        fontWeight: "300",
                        color: "#fff",
                        borderRadius: "10px",
                        background: "#db150e",
                        width: "36px",
                        height: "36px",
                      }}
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li style={{ listStyle: "none" }}>
                    <a
                      href=" https://x.com/mediatech09?t=x-kv93jEG5y0aqezPQ02qw&s=08"
                      target="_blank"
                      rel="noopener"
                      title="Twitter"
                      style={{
                        padding: " 9px 8px",
                        display: "block",
                        fontSize: "17px",
                        fontWeight: "300",
                        color: "#fff",
                        borderRadius: "10px",
                        background: "#db150e",
                        width: "36px",
                        height: "36px",
                      }}
                    >
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </li>

                  <li style={{ listStyle: "none" }}>
                    <a
                      href="https://www.instagram.com/mannixinfotech09/?fbclid=IwAR30ORrOy8gHLBnmw9Nys9CZNYznRUYLyiAXihrlzLH63aVD_y96syb0jJo"
                      target="_blank"
                      rel="noreferrer"
                      title="Instagram"
                      style={{
                        padding: " 9px 8px",
                        display: "block",
                        fontSize: "17px",
                        fontWeight: "300",
                        color: "#fff",
                        borderRadius: "10px",
                        background: "#db150e",
                        width: "36px",
                        height: "36px",
                      }}
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li style={{ listStyle: "none" }}>
                    <a
                      href="https://www.linkedin.com/company/mannix-infotech-solutions-pvt-ltd/"
                      target="_blank"
                      rel="noreferrer"
                      title="Linkedin"
                      style={{
                        padding: " 9px 8px",
                        display: "block",
                        fontSize: "17px",
                        fontWeight: "300",
                        color: "#fff",
                        borderRadius: "10px",
                        background: "#db150e",
                        width: "36px",
                        height: "36px",
                      }}
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Blog2;
