import React, { useState } from "react";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  const [activeTab, setActiveTab] = useState("tab1");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  return (
    <>
      <Helmet>
        <title>
          About Digital Marketing Company, Business Growth | Mannix Infotech
          Solutions PVT. LTD.
        </title>
        <meta
          name="description"
          content="Mannix infotech solutions is a company that specialize in digital marketing, business
developer and provide digital technology services to help business grow around the
world.
"
        />
        <meta
          name="keywords"
          content="mannix infotech solutions Pvt.Ltd.|web development agency in ahmedabad|web designing company in Gujarat|web design services in Ahmedabad|web solutions company in ahmedabad|web development services in india|mobile app development company in ahmedabad|app development company in ahmedabad|it company website|best app developers|best web developers in ahmedabad|website software in Ahmedabad|web design and development|best web design software|best web development company in ahmedabad|app development agencies|application development company in ahmedabad|software development company near me|web agencies|best mobile app developers in ahmedabad|best app developers in the us|best mobile app development company|it services website in Ahmedabad|mobile development company|website vendors|it development company|app creating company in ahmedabad|corporate website|web app developers near me|best web development software|digital solutions company|mobile app company in ahmedabad|web software|app development company in Ahmedabad|app agencies in Ahmedabad|india it company|India app developers|mobile app development agencies in ahmedabad|
"
        />
        <link rel="canonical" href="https://www.mannix.in/about-us" />
      </Helmet>

      <div>
        <div className="d-flex justify-content-center">
          <div className="row m-4">
            <div className="col-12 col-md-6 p-3 mb-5">
              <div
                className="img-box-container"
                style={{
                  height: "auto",
                  position: "relative",
                  padding: "2px",
                  background: "linear-gradient(to right, #db150e, #f6e58d)",
                  width: "80%",
                }}
              >
                <img
                  src="./aboutCEO.png"
                  className="img-fluid"
                  alt="aboutCEO"
                  style={{
                    position: "relative",
                    maxWidth: "100%",
                    height: "auto",
                    display: "block",
                  }}
                />
                <div
                  className="label d-none d-md-block"
                  style={{
                    position: "absolute",
                    bottom: "-50px",
                    right: "10px",
                    backgroundColor: "white",
                    padding: "10px",
                    borderRadius: "5px",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                    width: "250px",
                    height: "100px",
                  }}
                >
                  <h4>
                    <b>MS. MANSI JAIN</b>
                  </h4>
                  <p>
                    <b>DIRECTOR</b>
                  </p>
                  <a href="/" className=" ">
                    <span>
                      <i
                        className="fa-brands fa-linkedin fs-2 "
                        style={{
                          position: "relative",
                          left: "200px",
                          top: "-30px",
                        }}
                      ></i>
                    </span>
                  </a>
                </div>
                <div
                  className="box d-md-none d-sm-block"
                  style={{
                    position: "absolute",
                    top: "300px",
                    left: "100px",
                    width: "200px",
                    height: "100px",
                    backgroundColor: "#ffff",
                    border: "0px",
                    padding: "20px",
                    borderRadius: "10px",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  }}
                >
                  <h4 className="fs-5">
                    <b>MS. MANSI JAIN</b>
                  </h4>
                  <p>
                    <b>DIRECTOR</b>
                  </p>
                  <a
                    href="/"
                    style={{
                      position: "relative",
                      left: "150px",
                      top: "-30px",
                    }}
                    className=" "
                  >
                    <span>
                      <i className="fa-brands fa-linkedin fs-2"></i>
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 p-md-3">
              <div className="fs-5 mt-5" style={{ overflow: "hidden" }}>
                <p className="fw-bold fs-2" style={{ color: "#2980b9" }}>
                  About Mannix Infotech Solutions Pvt.Ltd.
                </p>
                <p>
                  We are a global provider of high-quality software development
                  and aim to build and implement data-driven solutions that
                  empower client's businesses and enhance their lives through
                  innovative enterprise solutions. Our team is ingrained with
                  creativity, knowledge, and experience that we foster to bring
                  each project with tangible results.
                </p>
                <p>
                  Incubated in Ahmedabad with a simple vision of providing
                  360-degree digital solutions under one roof. Our mission is to
                  provide the best possible Mobile App development, Enterprise
                  Mobility, Software development, Digital marketing, PhoneGap
                  Development services that help you sustain with evolving
                  markets. We collaborate with senior technical developers,
                  managers, quality analysts, designers, and strategists that
                  imbibe various contributing factors with the approaches and
                  solutions for business to embark on a new journey and
                  accelerate the success.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className=" mt-5">
          <div className="row mx-0 d-flex justify-content-center">
            <div className="col-12  col-md-6 text-center p-5 left">
              <h2 className="textweb">History</h2>
              <h3>We are here to IT Solutions with 1+ years of experience</h3>

              <div className="menu d-flex justify-content-center">
                <ul className="tab-list">
                  <li>
                    <button
                      style={{ border: "none", borderRadius: "5px" }}
                      type="button"
                      className={`tab-a ${
                        activeTab === "tab1" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("tab1")}
                    >
                      <button
                        className="d-flex"
                        style={{ background: "none", border: "none" }}
                      >
                        <img src="../vision.png" alt="vision" width="30px" />
                        <p className="px-3 fs-4">Vision</p>
                      </button>
                    </button>
                  </li>
                  <li>
                    <button
                      style={{ border: "none", borderRadius: "5px" }}
                      type="button"
                      className={`tab-a ${
                        activeTab === "tab2" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("tab2")}
                    >
                      <button
                        className="d-flex"
                        style={{ background: "none", border: "none" }}
                      >
                        <img src="../mission.png" alt="mission" width="30px" />
                        <p className="px-2 fs-4"> Mission</p>
                      </button>
                    </button>
                  </li>
                </ul>
              </div>
              <div className="tab-content">
                {activeTab === "tab1" && (
                  <div id="tab1">
                    <p>
                      {" "}
                      Our Vision is to be a leading Web Solution company in the
                      IT sector and progress in our current position in the
                      market. We know that Customer’s growth is our growth, so
                      we commit our customers to help in achieving their
                      business goals. We believe in work with accuracy and the
                      best quality.
                    </p>
                  </div>
                )}
                {activeTab === "tab2" && (
                  <div id="tab2">
                    <p>
                      Mannix Infotech Solutions Pvt. Ltd. mission is to apply
                      Branding, Digital Development, and Design capabilities to
                      entitle Startups and Brands to meet our client needs.
                      Exceed client's expectations by going beyond software to
                      provide best Web solutions that transform data into
                      knowledge, enabling them to solve their problems.
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div className="col-12 col-md-6 right">
              <img src="./vector.jpg" className="img-fluid" alt="vector" />
            </div>
          </div>
        </div>
        <section className="section2 mt-5 ">
          <div className="container">
            <div className="ops_aboutUs_process_content">
              <div className="ops_aboutUs_process_top">
                <span className="fs-3">What is Our</span>
                <h2>Development Process</h2>
              </div>
              <ul>
                <li>
                  <div className="ops_aboutUs_process_outer">
                    <div className="ops_aboutUs_process_text">
                      <span>1</span>
                      <div>
                        <h2>Research</h2>
                        <p>
                          To analyze the requirement through in-depth research
                          with the help of brilliant tools
                        </p>
                      </div>
                    </div>
                    <div className="ops_aboutUs_process_img">
                      <img
                        className=" lazyloaded"
                        loading="lazy"
                        src="..//reserch.png"
                        height="50"
                        width="50"
                        alt="reserch"
                      />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="ops_aboutUs_process_outer">
                    <div className="ops_aboutUs_process_img">
                      <img
                        className=" lazyloaded"
                        loading="lazy"
                        height="50"
                        width="50"
                        src="../deliver.png"
                        alt="deliver"
                      />
                    </div>
                    <div className="ops_aboutUs_process_text">
                      <span>6</span>
                      <div>
                        <h2>Deliver</h2>
                        <p>
                          We make delivery for the solutions and assist our
                          clients to control and administer solutions.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="ops_aboutUs_process_outer">
                    <div className="ops_aboutUs_process_text">
                      <span>2</span>
                      <div>
                        <h2>Design</h2>
                        <p>
                          To draft the design that is related to the research in
                          order to check the feasibility
                        </p>
                      </div>
                    </div>
                    <div className="ops_aboutUs_process_img">
                      <img
                        className=" lazyloaded"
                        loading="lazy"
                        src="../design.png"
                        height="50"
                        width="50"
                        alt="design"
                      />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="ops_aboutUs_process_outer">
                    <div className="ops_aboutUs_process_img">
                      <img
                        className=" lazyloaded"
                        loading="lazy"
                        height="50"
                        width="50"
                        src="../testing.png"
                        alt="testing"
                      />
                    </div>
                    <div className="ops_aboutUs_process_text">
                      <span>5</span>
                      <div>
                        <h2>Testing</h2>
                        <p>
                          In testing phase, we test every component make sure
                          that our solution fit the requirement
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="ops_aboutUs_process_outer">
                    <div className="ops_aboutUs_process_text">
                      <span>3</span>
                      <div>
                        <h2>Feasibility</h2>
                        <p>
                          After the completing feasibility test, a final design
                          is prepared by using smart tools
                        </p>
                      </div>
                    </div>
                    <div className="ops_aboutUs_process_img">
                      <img
                        className=" lazyloaded"
                        loading="lazy"
                        height="50"
                        width="50"
                        src="../Feasibility.png"
                        alt="Feasibility"
                      />
                    </div>
                  </div>
                </li>
                <li>
                  <div className="ops_aboutUs_process_outer">
                    <div className="ops_aboutUs_process_img">
                      <img
                        className=" lazyloaded"
                        loading="lazy"
                        height="50"
                        width="50"
                        src="../develop.png"
                        alt="develop"
                      />
                    </div>
                    <div className="ops_aboutUs_process_text">
                      <span>4</span>
                      <div>
                        <h2>Develop</h2>
                        <p>
                          Now our development team starts developing the
                          solutions according to the design
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AboutUs;
