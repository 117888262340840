import React, { useState } from "react";
import { Helmet } from "react-helmet";

const SericeLandingDevlopment = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>Effective Landing page Design for lead generation</title>
        <meta
          name="description"
          content="Get the most out of your marketing campaigns with our professional landing page designs. Our pages are tailored to your target audience and optimized for conversions.
"
        />
        <meta
          name="keywords"
          content="landing page design|High-converting landing page|responsive and optimization page
"
        />
        <link
          rel="canonical"
          href="https://www.mannix.in/landingpage-website-development-services"
        />
      </Helmet>
      <div className="hire-dedicated services mt-0">
        <section className="banner-content-1">
          <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            className="position-absolute top-banner-bg"
            viewBox="0 0 1920 761"
          >
            <g>
              <defs>
                <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
              </defs>
              <clipPath id="SVGID_2_">
                <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
              </clipPath>
              <g className="banner-bg-asw">
                <path
                  className="st1"
                  d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                        c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
                ></path>
                <path
                  className="st2"
                  d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                        c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
                ></path>
                <path
                  className="st1"
                  d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
                ></path>
                <path
                  className="st2"
                  d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
                ></path>
                <circle
                  className="st1"
                  cx="1536.8"
                  cy="661.3"
                  r="56.2"
                ></circle>
                <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
              </g>
            </g>
          </svg>
          <div className="py-5 container">
            <div className="row align-items-center justify-content-around mt-4">
              <div className="col-lg-6">
                <div className="">
                  <h1 className="head-title head-title-without-line m-0 py-3">
                    “What is a landing page?”
                  </h1>
                  <p className="m-0 py-3">
                    The landing page plays an important role in selling your
                    products. Whether your website finder becomes a new customer
                    or fails to attract, it all depends on what they get there.
                    Are your landing pages capable of turning visitors into
                    leads or do they lead to high rejection? Here at Mannix
                    Infotech Solutions Pvt.Ltd., Our professionals are committed
                    to supporting you in this matter and transforming your
                    landing pages into a highly Effective Web Development
                    Landing Page that bring more leads and conversions for your
                    business.
                  </p>
                  <p className="m-0 py-3">
                    Landing page is a dedicated page on a website that you
                    simply access directly from an external source like a email.
                    The landing page is the digital version of the marketing
                    letter that is focused and highly targetedThe goal of the
                    landing page is to stimulate a planned action by using
                    targeted copy to connect with a user and persuade them to
                    take required action.Landing pages can be built for SEO
                    purposes to capture organic search traffic. They can also be
                    used with paid advertising through search engines or social
                    networks.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 prod-section2">
                <div className="">
                  <form
                    className="register-form"
                    id="register-form"
                    method="post"
                    action=""
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="hidden"
                      name="form_type"
                      id="form_type"
                      value="Android App Development"
                    />
                    <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                      <div className="d-flex">
                        <div className="w-100 mr-2">
                          <label className="w-100">
                            <span>Name</span>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Name *"
                              className="form-control"
                              value={formData.name}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                        <div className="w-100">
                          <label className="w-100">
                            <span>Email</span>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Email id *"
                              className="form-control"
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <label className="w-100">
                          <span>Contact</span>
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            placeholder="Contact No *"
                            className="form-control"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Country</span>
                          <input
                            type="text"
                            name="country"
                            id="country"
                            placeholder="Enter your Country"
                            className="form-control"
                            value={formData.country}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Message</span>
                          <textarea
                            name="message"
                            id="message"
                            placeholder="Enter your message *"
                            className="form-control"
                            value={formData.message}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </label>
                      </div>

                      <div className="">
                        <button
                          type="submit"
                          name="submit"
                          className="submit btn w-100 g-recaptcha-btn"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="container d-flex flex-wrap">
        <div className="col-md-6 col-sm-6 ">
          <div className="theme-title theme_web">
            <h3>
              Let stunning and interactive landing page benefit your business -
            </h3>
            <ul className="list-style-blt">
              <li>
                From individual startups to giant corporations, online success
                depends heavily on the ability of businesses to attract leads
                and establish a strong online presence. This is where the
                landing pages, which is the page on your site that your users
                first land on to get them to buy your required product.
              </li>
              <li>
                See your business website as the hub of all your online business
                activities. This is where you provide the vital information
                people need to become paying customers. This includes things
                like detailed information about a product or service, customer
                support vehicles, purchasing options, all of which are factors
                that drive visitors to convert.
              </li>
              <li>
                Landing pages are like the messengers that go out into the world
                and raise awareness of what you have to offer. The more complex
                your business operations, the more diverse your landing pages
                should be. That's why nearly 70% of B2B companies use them to
                generate new business leads.
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-6 col-sm-6 p-4">
          <div className="theme-title theme_solution">
            <h4>
              Let's see how these landing pages can help you support and grow
              your business -
            </h4>
            <ul>
              <li>
                {" "}
                <strong>1. We will help you grab attention -</strong> You can
                have the service or best product on the planet. But, if no one
                knows that it exists, it is not doing you or the public any
                good. Landing pages are crucial in bringing people to the top of
                the sales funnel.{" "}
              </li>
              <li>
                <strong>
                  2. We will Strategically support business goals -
                </strong>
                Perhaps the most important benefit of using landing pages is
                that they provide you the opportunity to diversify your strategy
                around completely different goals. Whether promoting a new
                product or service, reaching a new market, or closing sales, you
                can design these pages to cater to the most sensitive audience.
              </li>
              <li>
                <strong>
                  3.We will Improve the effectiveness of content marketing -
                </strong>{" "}
                Content marketing is an extremely hot topic in the modern
                business landscape. Businesses in all fields are dramatically
                increasing their investments in this area with no signs of
                slowing down.
              </li>
              <li>
                <strong>4. We will Build your credibility -</strong> In the
                online business world, credibility is more valuable than money.
                Without it, money is virtually non-existent, and opportunities
                are scarce. Your ability to showcase your expertise in the
                industry is a huge factor in presenting your unique selling
                proposition and in consolidating buying decisions.
              </li>
            </ul>
          </div>
        </div>
        <div className="row align-items-center">
          <div className="col-sm-6 col-md-6">
            <div className="">
              {" "}
              <img
                className="img-fluid"
                src="../Images/landingpage.png"
                alt="landingpage"
              />{" "}
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="theme-title text-left">
              <h2>
                Our landing pages should communicate your credibility at first
                glance.
              </h2>
              <p className="text-justify">
                Everyone is aware of how necessary third-party validations, like
                testimonials and online reviews, are when it comes to
                influencing choices. With that in mind, creating your Landing
                Page Development to feature a positive reinforcement quote or
                word can be very powerful in establishing your credibility.
                Social proof works to make your landing pages compelling.
              </p>
              <p className="text-justify">
                If you go this route, be sure to keep it short and to the point.
                If designed correctly, you can have multiple testimonials
                present on your landing pages. Plus, a positive photo is a great
                touch.
              </p>
              <p className="text-justify">
                Personalization is another fantastic way to boost the
                credibility of your landing pages. Effective copy speaks
                directly to the reader.When a user glances at your page, they
                should be able to feel a direct connection with it. More so, he
                should really understand their weak points and concerns.
                Achieving this is the key to marketing. All of this can be
                attributed to your research and your ability to capitalize on
                industry trends.
              </p>
              <p className="text-justify">
                For example, there were a ton of companies whose search engine
                rankings took a hit following Google's latest algorithm updates.
                For an SEO agency looking for potential clients, designing
                landing pages to address these concerns and showcase unique
                solutions would be a phenomenal way to attract viewers looking
                for answers.
              </p>
              <p className="text-justify">
                Competition in the Internet age is fiercer than ever. When
                things are going well, credibility is what sets brands apart. In
                terms of landing pages, it needs to be presented as quickly and
                clearly as possible.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SericeLandingDevlopment;
