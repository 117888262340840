import React, { useState } from "react";
import { Helmet } from "react-helmet";

const Career = () => {
  const [openFaq, setOpenFaq] = useState("");

  const toggleFaq = (faq) => {
    setOpenFaq(openFaq === faq ? null : faq);
  };
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    city: "",
    job: "",
    company: "",
    experience: "",
    ctc: "",
    file: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      file: e.target.files[0],
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <>
      <Helmet>
        <title>
          Explore Career opportunities in Development, Designing & Digital
          Marketing Skills with Mannix Infotech Solutions, Ahmedabad
        </title>
        <meta
          name="description"
          content="Join mannix infotech solutions for a rewarding career journey. Explore opportunities in Application Development, Digital Marketing, Web Development, Software Development,Graphic Designing, SEO, Business Developer. Shape your future with us"
        />
        <meta
          name="keywords"
          content="mannix infotech solutions Pvt.Ltd.|online marketing expert in ahmedabad|best digital marketing services in Ahmedabad|digital marketing company in Ahmedabad|digital marketing website in Ahmedabad|digital marketing company in gujarat|online marketing company in india|marketing company near by|best marketing company near me|top 5 marketing company in ahmedabad|best application development company in ahmedabad|top 5 App development company in Ahmedabad|App Development company in gujarat|App development services company in ahmedabad|mobile application development services company|android application development services company near me|IOS application development company in gujarat|desktop website development company in ahmedabad|website development company in ahmedabad|top 5 website development services in gujarat|best website development services in india|best graphic designing company in ahmedabad|top 5 graphic designing services in ahmedabad|best logo designing services in ahmedabad|top logo designing company near me|performance marketing company in ahmedabad|best ppc marketing company in ahmedabad"
        />

        <link rel="canonical" href="https://www.mannix.in/career" />
      </Helmet>

      <div>
        <section className="ops_blog_header position-relative">
          <img
            className=" img-fluid"
            src="../CareerBanner.png"
            alt="CareerBanner"
            style={{
              width: "100%",
              height: "auto",
              // objectFit: "cover",
            }}
          />

          <div className="text-below d-none d-md-block">
            <a href="/">
              <p>www.mannix.in</p>
            </a>
          </div>
          <div className="text-below1  d-md-none d-sm-block">
            <a href="/">
              <p>www.mannix.in</p>
            </a>
          </div>
        </section>

        <div className="career_formus mt-5">
          <div className="container faq-page fq_page_d">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-xs-12">
                <div className="faq-content-wrapper">
                  <h4>We’re Hiring! Your skills are valued</h4>
                  <p>
                    Choose your area of interest and create something people
                    love to use every day
                  </p>
                  <div className="faq-container">
                    <div className="faq">
                      <div
                        className="question-container"
                        onClick={() => toggleFaq("Android/ios Developer")}
                      >
                        <h2>Android/ios Developer</h2>
                        <div className="toggle-btn">
                          <p
                            className={
                              openFaq === "Android/ios Developer"
                                ? "rotate"
                                : ""
                            }
                          >
                            +
                          </p>
                        </div>
                      </div>
                      <div
                        className={`answer ${
                          openFaq === "Android/ios Developer" ? "visible" : ""
                        }`}
                      >
                        <div
                          className={`panel-body ${
                            openFaq === "Android/ios Developer" ? "dark-bg" : ""
                          }`}
                        >
                          <p className="fw-bold">2 years of experience</p>
                        </div>
                      </div>
                    </div>
                    <div className="faq">
                      <div
                        className="question-container"
                        onClick={() => toggleFaq("Web Developer")}
                      >
                        <h2>Web Developer</h2>
                        <div className="toggle-btn">
                          <p
                            className={
                              openFaq === "Web Developer" ? "rotate" : ""
                            }
                          >
                            +
                          </p>
                        </div>
                      </div>
                      <div
                        className={`answer ${
                          openFaq === "Web Developer" ? "visible" : ""
                        }`}
                      >
                        <div
                          className={`panel-body ${
                            openFaq === "Web Developer" ? "dark-bg" : ""
                          }`}
                        >
                          <p className="fw-bold">2 years of experience</p>
                        </div>
                      </div>
                    </div>
                    <div className="faq">
                      <div
                        className="question-container"
                        onClick={() => toggleFaq("Digital Mraketing Expert")}
                      >
                        <h2>Digital Mraketing Expert</h2>
                        <div className="toggle-btn">
                          <p
                            className={
                              openFaq === "Digital Mraketing Expert"
                                ? "rotate"
                                : ""
                            }
                          >
                            +
                          </p>
                        </div>
                      </div>
                      <div
                        className={`answer ${
                          openFaq === "Digital Mraketing Expert"
                            ? "visible"
                            : ""
                        }`}
                      >
                        <div
                          className={`panel-body ${
                            openFaq === "Digital Mraketing Expert"
                              ? "dark-bg"
                              : ""
                          }`}
                        >
                          <p className="fw-bold">2 years of experience</p>
                        </div>
                      </div>
                    </div>
                    <div className="faq">
                      <div
                        className="question-container"
                        onClick={() => toggleFaq("SEO Expert")}
                      >
                        <h2>SEO Expert</h2>
                        <div className="toggle-btn">
                          <p
                            className={openFaq === "SEO Expert" ? "rotate" : ""}
                          >
                            +
                          </p>
                        </div>
                      </div>
                      <div
                        className={`answer ${
                          openFaq === "SEO Expert" ? "visible" : ""
                        }`}
                      >
                        <div
                          className={`panel-body ${
                            openFaq === "SEO Expert" ? "dark-bg" : ""
                          }`}
                        >
                          <p className="fw-bold">2 years of experience</p>
                        </div>
                      </div>
                    </div>
                    <div className="faq">
                      <div
                        className="question-container"
                        onClick={() => toggleFaq("Graphic Designer")}
                      >
                        <h2>Graphic Designer</h2>
                        <div className="toggle-btn">
                          <p
                            className={
                              openFaq === "Graphic Designer" ? "rotate" : ""
                            }
                          >
                            +
                          </p>
                        </div>
                      </div>
                      <div
                        className={`answer ${
                          openFaq === "Graphic Designer" ? "visible" : ""
                        }`}
                      >
                        <div
                          className={`panel-body ${
                            openFaq === "Graphic Designer" ? "dark-bg" : ""
                          }`}
                        >
                          <p className="fw-bold">2 years of experience</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-xs-12 mt-5">
                <div className="faq-form-wrapper p-5">
                  <h4>
                    <b>Fill The Following Form</b>
                  </h4>
                  <div className="tab-content">
                    <div id="fortab" className="tab-pane fade in active">
                      <div className="faq-form-wrapper-2">
                        <form
                          className="career_form form"
                          id="career_form"
                          onSubmit={handleSubmit}
                        >
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Your Name*"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                          <input
                            className="form-control"
                            type="email"
                            placeholder="Your Email*"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                          <input
                            className="form-control"
                            type="tel"
                            placeholder="Your mobile*"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                          />
                          <input
                            className="form-control"
                            type="text"
                            placeholder="City*"
                            id="city"
                            name="city"
                            value={formData.city}
                            onChange={handleChange}
                            required
                          />
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Job Title*"
                            id="job"
                            name="job"
                            value={formData.job}
                            onChange={handleChange}
                            required
                          />
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Current Company*"
                            id="company"
                            name="company"
                            value={formData.company}
                            onChange={handleChange}
                          />
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Current Experience*"
                            id="experience"
                            name="experience"
                            value={formData.experience}
                            onChange={handleChange}
                          />
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Expected CTC*"
                            id="ctc"
                            name="ctc"
                            value={formData.ctc}
                            onChange={handleChange}
                            required
                          />
                          <div className="file-upload">
                            <div className="file-select">
                              <input
                                type="file"
                                name="career_img"
                                id="career_img"
                                onChange={handleFileChange}
                                required
                              />
                            </div>
                          </div>
                          <div
                            className="g-recaptcha"
                            data-sitekey="6Le4E1UlAAAAANinY7VXkfc-IVoqm_q9bnlhMbO-"
                            style={{ float: "left", marginBottom: "10px" }}
                          ></div>
                          <button
                            type="submit"
                            name="submit"
                            className="p-bg-color hvr-float-shadow btn"
                          >
                            SUBMIT DETAILS
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Career;
