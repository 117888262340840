import React from "react";
import { Helmet } from "react-helmet";

const OurGallary = () => {
  return (
    <>
      <Helmet>
        <title>
          Mannix Infotech Solutions | Some Clips of Digital Marketing Company
        </title>
        <meta
          name="keywords"
          content="mannix infotech solutions Pvt.Ltd.|digital marketing company in Ahmedabad|marketing services in india|digital marketing company|marketing company|digital marketing company near me|digital marketing agency near me|digital marketing services|digital marketing company in Ahmedabad|agency marketing|digital marketing business|digital marketing agency Ahmedabad|marketing agency services|start a digital marketing agency|digital marketing company in ahmedabad|digital marketing firms near me|digital agency services|digital marketing agency Ahmedabad|agency digital|marketing agency in Ahmedabad|marketing company services|it marketing agency|marketing digital company|digital marketing agency pricing|agency marketing digital|cheap digital marketing agency|digital marketing in Ahmedabad|digital marketing agency packages|it marketing company|digital marketing company how to start|digital marketing agency pricing packages|covered digital marketing agency|marketing agency for therapists|low cost digital marketing agency|marketing agency company|online marketing"
        />
        <meta
          name="description"
          content="Team Camaraderie, Office Highlights, and the essence of our Success Story"
        />
        <link rel="canonical" href="https://www.mannix.in/gallery" />=
      </Helmet>

      <div>
        <div
          className=""
          style={{
            background: "url('../GallaryBanner.jpeg')no-repeat",
            // width: "100vw",
            backgroundSize: "cover",
            height: "290px",

            padding: "119px 0",
          }}
        >
          <div className="intern_heading2">
            <div className="container">
              <h1 className="gallery-head text-center">
                "A Gallery Of Artistry And Passion."
              </h1>
            </div>
          </div>
        </div>

        <div className="container mt-5 d-none d-md-block">
          <div className="aboutUs_large_title">
            <h2 className="aboutUs_large_culture">Mannix Family</h2>
          </div>
          <div className="ops_aboutUs_culture_content">
            <div className="col-md-12">
              <img
                className=" lazyloaded img-fluid"
                loading="lazy"
                // height="500px"
                // width="100%"
                src="../gallary.png"
                alt="gallary"
              />
              <h2 className="gallery-head pb-5">One for all. All for one!</h2>
            </div>
          </div>
        </div>

        <div className="container mt-5 d-md-none d-sm-block">
          <div className="aboutUs_large_title">
            <h2 className="aboutUs_large_culturesm">Mannix Family</h2>
          </div>
          <div className="ops_aboutUs_culture_content">
            <div className="col-md-12">
              <img
                className=" lazyloaded img-fluid"
                loading="lazy"
                // height="500px"
                // width="100%"
                src="../gallary.png"
                alt="gallary"
              />
              <h2 className="gallery-head pb-5">One for all. All for one!</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurGallary;
