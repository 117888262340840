import React, { useState } from "react";
import { Helmet } from "react-helmet";

const HybridAppDevelopment = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>top hybrid app development company</title>
        <meta
          name="description"
          content="we specialize in creating hybrid app created with experienced devlopers and meet business goals across multiple platforms.
"
        />
        <meta
          name="keywords"
          content="custom hybrid app|top hybrid app developers|top hybrid app development services|best hybrid mobile app
"
        />
        <link
          rel="canonical"
          href="https://www.mannix.in/hybrid-app-development-services"
        />
      </Helmet>
      <div className="hire-dedicated services mt-0">
        <section className="banner-content-1">
          <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            className="position-absolute top-banner-bg"
            viewBox="0 0 1920 761"
          >
            <g>
              <defs>
                <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
              </defs>
              <clipPath id="SVGID_2_">
                <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
              </clipPath>
              <g className="banner-bg-asw">
                <path
                  className="st1"
                  d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                        c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
                ></path>
                <path
                  className="st2"
                  d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                        c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
                ></path>
                <path
                  className="st1"
                  d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
                ></path>
                <path
                  className="st2"
                  d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
                ></path>
                <circle
                  className="st1"
                  cx="1536.8"
                  cy="661.3"
                  r="56.2"
                ></circle>
                <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
              </g>
            </g>
          </svg>
          <div className="py-5 container">
            <div className="row align-items-center justify-content-around mt-4">
              <div className="col-lg-6">
                <div className="">
                  <h1 className="head-title head-title-without-line m-0 py-3">
                    Hybrid App Development
                  </h1>
                  <p className="m-0 py-3">
                    Mobile applications that seamlessly function and should
                    proffer amazing functionalities are pre-eminent. Android App
                    Development Company is a futuristic friendly way of weaving
                    technology for mobile app development that boosts the
                    omnipresence of the audience. Mannix Infotech Solutions
                    Pvt.Ltd. experience and skilled developers can create
                    robust, engaging, high-performance, android app solutions
                    that come with all advanced features and technologies. We
                    have in-house best talents from the industry who proffer
                    end-to-end customization and integration services to bring
                    ideas into reality.{" "}
                  </p>
                  <p className="m-0 py-3">
                    With years of experience in diverse industries, we can craft
                    and implement engaging, scalable, and feature-rich mobile
                    apps that can easily run across multiple platforms.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 prod-section2">
                <div className="">
                  <form
                    className="register-form"
                    id="register-form"
                    method="post"
                    action=""
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="hidden"
                      name="form_type"
                      id="form_type"
                      value="Android App Development"
                    />
                    <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                      <div className="d-flex">
                        <div className="w-100 mr-2">
                          <label className="w-100">
                            <span>Name</span>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Name *"
                              className="form-control"
                              value={formData.name}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                        <div className="w-100">
                          <label className="w-100">
                            <span>Email</span>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Email id *"
                              className="form-control"
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <label className="w-100">
                          <span>Contact</span>
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            placeholder="Contact No *"
                            className="form-control"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Country</span>
                          <input
                            type="text"
                            name="country"
                            id="country"
                            placeholder="Enter your Country"
                            className="form-control"
                            value={formData.country}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Message</span>
                          <textarea
                            name="message"
                            id="message"
                            placeholder="Enter your message *"
                            className="form-control"
                            value={formData.message}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </label>
                      </div>

                      <div className="">
                        <button
                          type="submit"
                          name="submit"
                          className="submit btn w-100 g-recaptcha-btn"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="cp_home_sec mt-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 col-sm-9">
              <div className="development_page_d">
                <p className="fs-1">Hybrid App Development</p>
                <p className="text-justify">
                  Thinking of owning a crowdfunding platform like Kickstarter,
                  Indiegogo, GoFundMe, Seedrs, and CrowdRise? Mannix Infotech
                  Solutions Pvt.Ltd. is one of the leading crowdfunding platform
                  development companies providing crowdfunding solutions that
                  harness the power of innovative technologies for innovative
                  web and mobile applications in the market.
                </p>
              </div>
            </div>
            <div className="col-md-5 col-sm-3 m-5">
              <div className="cp_home_image">
                <img
                  src="	../Images/HybridAppDevelopment.png
"
                  style={{ height: "350px" }}
                  alt="HybridAppDevelopment"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="company-offer3">
        <div className="container">
          <div className="theme-title">
            {" "}
            <strong>
              How can we add worth to hybrid application development?
            </strong>
            <ul className="list-style-blt">
              <li>Created do we powerful HTML5, JS, and CSS3 languages.</li>
              <li>
                Knowledge of open-source platforms like Appcelerator, PhoneGap,
                Adobe Air, QT Sencha, Kendo UI, and plenty of others.
              </li>
              <li>Download and install without browsing.</li>
              <li>Interactive interface.</li>
              <li>Multiplatform / multiplatform development.</li>
              <li>Improved access to devise features and capabilities.</li>
              <li>High performance.</li>
            </ul>
          </div>
        </div>
      </div>
      <section>
        <div className="hire-dedicated-2 container py-4">
          <div className="pb-4 text-center">
            <h2 className="head-title">
              Our Package of Hybrid App Development Services
            </h2>
          </div>
          <div className="row">
            <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fab fa-react"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">React Native Development</h2>
                    <p className="m-0 py-2">
                      {" "}
                      Mannix Infotech Solutions Pvt.Ltd. services use this
                      eminent hybrid mobile app framework for app development to
                      take advantage of its exemplary fast development and
                      robust performance Our developers can take your business
                      to a spanking new height by creating a Hybrid app with
                      React Native Framework.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fab fa-react"></i>
                </div>
              </div>
            </div>
            <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fab fa-searchengin"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Maintenance and support</h2>
                    <p className="m-0 py-2">
                      We provide exceptional hybrid application maintenance and
                      support services to ensure peak application performance
                      even at peak times. Our developers resolve any issues you
                      have, even after launching the app. We also provide 24*7
                      responsive maintenance support.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fab fa-searchengin"></i>
                </div>
              </div>
            </div>
            <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fas fa-child"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Flutter App development</h2>
                    <p className="m-0 py-2">
                      Our developers aim to develop a hybrid mobile app with the
                      help of Flutter to deliver efficient cross-platform apps
                      to the customer. Our developers can take your business to
                      a brand new height by making a Hybrid app with React
                      Native Framework.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fas fa-child"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb_90">
        <div className="hire-dedicated-2 container py-4">
          <div className="pb-4 text-center">
            <h2 className="head-title">
              Why choose Mannix Infotech for a hybrid application development
              company?
            </h2>
          </div>
          <div className="row">
            <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fas fa-bars"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Efficient integration</h2>
                    <p className="m-0 py-2">
                      Our programmers are informed about the latest technology
                      update and inspire them to develop an advanced hybrid
                      application by our coding professionals. We can offer our
                      services in a seamless integration that will help you work
                      with different devices like iOS, Android, desktop, and
                      laptop.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fas fa-bars"></i>
                </div>
              </div>
            </div>
            <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fab fa-searchengin"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Results-oriented</h2>
                    <p className="m-0 py-2">
                      Whether you want to establish an online presence from
                      scratch or expand its expansion across multiple platforms,
                      One pixel soft will provide efficient cross-platform
                      application development that fits your budget.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fab fa-searchengin"></i>
                </div>
              </div>
            </div>
            <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fas fa-child"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Competitor prices</h2>
                    <p className="m-0 py-2">
                      Whether you want to establish an online presence from
                      scratch or expand its expansion across multiple platforms,
                      One pixel soft provides efficient cross-platform
                      application development that fits your budget.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fas fa-child"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HybridAppDevelopment;
