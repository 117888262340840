import React from "react";

const Blog3 = () => {
  return (
    <div>
      <div>
        <section
          className="ops_blog_header"
          style={{
            background: "url('../blogbanner.jpg')",
            height: "270px",
            backgroundSize: "cover !important",
            padding: "80px 0",
          }}
        >
          <div className="container">
            <div className="ops_blog_header_content">
              <h1 className="fw-bold text-danger">
                Unlocking the Secrets: Dive into the World of Mannix Infotech
                Solutions Pvt.Ltd.
              </h1>
            </div>
          </div>
        </section>

        <section>
          <div className=" d-flex flex-wrap p-5">
            <div className="col-lg-8 d-flex flex-wrap justify-content-center ">
              <div
                className="mb-3 px-5"
                style={{
                  //   flex: "1 1 300px",
                  //   maxWidth: "300px",
                  margin: "0 auto",
                  width: "100%",
                }}
              >
                <div
                  className="card"
                  style={{
                    height: "",
                    backgroundColor: "#d1d8e0",
                    borderRadius: "20px",
                    width: "100%",
                  }}
                >
                  <img
                    src="../Images/MobileAppDevelopmentBlog.png"
                    alt="MobileAppDevelopmentBlog"
                    style={{ maxWidth: "100%", borderRadius: "20px 20px 0 0" }}
                  />
                  <p className="p-2 text-center" style={{ fontSize: "25px" }}>
                    Top Mobile App Development Companies in Ahmedabad
                  </p>
                  <p className="p-2 text-center" style={{ fontSize: "16px" }}>
                    Mannix Infotech Solutions Pvt. Ltd., headquartered in
                    Ahmedabad, Gujarat, India, isn't just a web development
                    powerhouse. They're a team of experts passionate about
                    crafting next-level mobile applications.
                  </p>

                  <div className="p-3" style={{ fontSize: "16px" }}>
                    <div>
                      <h4 style={{ fontSize: "24px" }}>
                        1) Mannix Infotech (Best Mobile app Development company
                      </h4>

                      <p>
                        Mannix Infotech Solutions Pvt. Ltd.: Crafting
                        Cutting-Edge Mobile Apps for Your Business (India,
                        Canada, US)
                      </p>
                      <p>
                        Mannix Infotech Solutions Pvt. Ltd., headquartered in
                        Ahmedabad, Gujarat, India, isn't just a web development
                        powerhouse. They're a team of experts passionate about
                        crafting next-level mobile applications that elevate
                        your business and empower your users.
                      </p>
                      <h4 style={{ fontSize: "24px" }}>
                        Why Choose Mannix Infotech for Your Mobile App?
                      </h4>
                      <p>
                        • Native App Development: They specialize in building
                        native apps for iOS and Android, ensuring a seamless
                        user experience optimized for each platform. • Focus on
                        User Experience (UX): They recognise the value of
                        user-friendly interfaces and intuitive design. Mannix
                        Infotech creates mobile apps that are not just
                        functional but a joy to use. • Global Reach, Local
                        Expertise: Located in India, they serve clients across
                        borders – India, Canada, and the US. This experience
                        allows them to navigate diverse market needs and build
                        apps that resonate with a global audience.
                      </p>
                      <h4 style={{ fontSize: "24px" }}>
                        Would you like a smartphone app that can?
                      </h4>
                      <p>
                        • Increase customer engagement and loyalty? • Boost
                        sales and conversions? • Simplify complex tasks for your
                        users? • Streamline communication and collaboration? •
                        Mannix Infotech Solutions has the knowledge and
                        experience to design and develop a mobile app that
                        delivers real-world results.
                      </p>
                      <h4 style={{ fontSize: "24px" }}>
                        Considering a Mobile App for Your Business?
                      </h4>
                      <p>
                        Whether you're a startup in India or an established
                        company in North America, Mannix Infotech Solutions can
                        be your trusted partner. They'll collaborate with you to
                        understand your target audience, define your app's
                        functionalities, and craft a mobile solution that
                        perfectly complements your overall business strategy.
                      </p>
                      <p>
                        <b>
                          Get in touch with Mannix Infotech Solutions today and
                          discuss your mobile app vision. Let their team of
                          experts turn your ideas into a powerful mobile
                          application that takes your business to the next
                          level.
                        </b>
                      </p>
                      <p>
                        E-mail:
                        <a
                          className=""
                          href="mailto:info@mannix.in"
                          title="mailto"
                          target="_blank"
                          rel="noreferrer"
                        >
                          info@mannix.in
                        </a>{" "}
                      </p>
                      <p>
                        Contact no:{" "}
                        <a
                          href="tel:+91 9664053983"
                          className=""
                          title="phone"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          +91 9664053983
                        </a>
                      </p>
                    </div>

                    <div className="mt-3">
                      <h4 style={{ fontSize: "24px" }}>
                        2) Fusion Informatics (top mobile app development
                        company)
                      </h4>
                      <p>
                        Fusion Informatics, one of Ahmedabad's leading mobile
                        app development companies, employs more than 200
                        outstanding app developers who deliver the best IT
                        solutions to businesses. As professionals in the
                        organization engaged in the development of the
                        application, we ensure that it functions without a
                        hitch. We identify the highest-quality services that our
                        clients require, assess their needs and opportunities
                        for improvement, formulate a clear plan of action, and
                        offer to handle these areas.
                      </p>
                      <p>
                        Our prior projects and commitment to customer
                        satisfaction have allowed us to establish ourselves as
                        one of Ahmedabad's leading mobile app development
                        businesses.
                      </p>
                      <p>
                        <b>Expertise-</b>
                      </p>
                      <p>
                        The services offered include Android and iPhone app
                        development, blockchain development, IoT app
                        development, artificial intelligence development, and
                        enterprise mobile app development.
                      </p>
                    </div>
                    <div className="mt-3">
                      <h4 style={{ fontSize: "24px" }}>
                        3) Hyperlinkinfosystems (best mobile app development
                        company)
                      </h4>
                      <p>
                        They are one of the top mobile apps development
                        companies in Ahmedabad, The company allows customized
                        apps based on the requirements of the customers and the
                        product will be performed with great quality with
                        affordable rates. Their focus on custom designs that
                        they commit and solid increase as the base provides
                        various enhancements in the mobile app development
                        services.
                      </p>
                      <p>
                        <b>Expertise-</b>
                      </p>
                      <p>
                        Android App Development Services,iPhone App Development
                        Services, and services for developing mobile apps for
                        enterprises
                      </p>
                    </div>
                    <div className="mt-3">
                      <h4 style={{ fontSize: "24px" }}>
                        4) 21Twelve Interactive (app development company)
                      </h4>
                      <p>
                        They are a leading app development company with their
                        headquarters located in Ahmedabad. They have
                        professional iPhone and Android app developers on staff
                        that are dedicated to creating unique mobile apps. Since
                        they use cutting-edge techniques to create original apps
                        for users, they have a thorough understanding of market
                        expectations.{" "}
                      </p>
                      <p>
                        <b>Expertise-</b>
                      </p>
                      <p>
                        Services for developing mobile apps for Android, iPhone,
                        and enterprise applications
                      </p>
                    </div>
                    <div className="mt-3">
                      <h4 style={{ fontSize: "24px" }}>
                        5) 7Stacks (Mobile Appliation app development company)
                      </h4>
                      <p>
                        With a staff of professionals that includes software
                        specialists and app developers, it is among the best
                        Android app development companies in Ahmedabad. The
                        company's primary goal is to offer the greatest mobility
                        services to clients that want to create mobile
                        applications for their companies. Their area of
                        expertise is creating mobile applications for iOS and
                        Android platforms.
                      </p>
                      <p>
                        <b>Expertise-</b>
                      </p>
                      <p>
                        Services for developing mobile apps for Android, iPhone,
                        and enterprise applications
                      </p>
                    </div>
                    <div className="mt-3">
                      <h4 style={{ fontSize: "24px" }}>
                        6) IndiaNIC Infotech Limited (leading mobile app
                        development company)
                      </h4>
                      <p>
                        With a staff of more than 250 professionals, including
                        software engineers and team leaders, they rank among the
                        top mobile app development companies in Ahmedabad. They
                        are excellent at offering their clients first-rate
                        services. They've developed successful mobile apps with
                        a focus on results for a range of businesses.
                      </p>
                      <p>
                        <b>Expertise-</b>
                      </p>
                      <p>
                        Android App Development Services, iPhone App Development
                        Services, and services for developing mobile apps for
                        enterprises.
                      </p>
                    </div>
                    <div className="mt-3">
                      <h4 style={{ fontSize: "24px" }}>
                        7) Hidden Brains (Android app development company)
                      </h4>
                      <p>
                        They are an award-winning software company that offers
                        mobile apps for businesses, and they are among the
                        finest app development companies in Ahmedabad. They
                        offer mobile and web application solutions. They have
                        more than 30 nations under their belt and more than 13
                        years of experience.
                      </p>
                      <p>
                        <b>Expertise-</b>
                      </p>
                      <p>
                        Services for developing mobile apps for Android, iPhone,
                        and enterprise applications
                      </p>
                    </div>
                    <div className="mt-3">
                      <h4 style={{ fontSize: "24px" }}>
                        8) Excellent WebWorld (mobile app development company)
                      </h4>
                      <p>
                        They are pros at creating smartphone apps with an IoT
                        foundation for different companies. For clients
                        searching for dependable app solutions, they create
                        lively, responsive apps. The greatest option is
                        Excellent WebWorld.
                      </p>
                      <p>
                        <b>Expertise-</b>
                      </p>
                      <p>
                        Mobile application development services for Android,
                        iPhone, and enterprise applications
                      </p>
                    </div>
                    <div className="mt-3">
                      <h4 style={{ fontSize: "24px" }}>
                        9) OpenXcell (leading top mobile app development
                        company)
                      </h4>
                      <p>
                        It is one of Ahmedabad, India's top companies for
                        developing mobile apps. They provide a mobile app that
                        increases consumer acquisition for your company and
                        improves return on investment. They use affordable,
                        risk-free, and scalable services to implement app
                        solutions.
                      </p>
                      <h4>Expertise-</h4>
                      <p>
                        Services for developing mobile apps for Android, iPhone,
                        and enterprise platforms
                      </p>
                    </div>
                    <div className="mt-3">
                      <h4 style={{ fontSize: "24px" }}>
                        10) Peerbits (best mobile app development company)
                      </h4>
                      <p>
                        It is Ahmedabad, India's top mobile app development
                        firm. Their primary goal is to offer cutting-edge mobile
                        app solutions to businesses of all sizes. Custom mobile
                        app development for all sectors is their area of
                        expertise. They are experts at fusing cutting-edge
                        technology that suit any industry and satisfy client
                        demands.{" "}
                      </p>
                      <p>
                        <b>Expertise-</b>
                      </p>
                      <p>
                        Services for developing mobile apps for Android, iPhone,
                        and enterprise platforms
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div>
                <p className="fs-4">Categories</p>
                <ul>
                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> App & Software Development </span>
                    </a>
                  </li>
                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> Graphic Designing</span>
                    </a>
                  </li>
                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> Web Development </span>
                    </a>
                  </li>
                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> Online Marketing </span>
                    </a>
                  </li>

                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> Digital Marketing </span>
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <p className="fs-4">Recent posts</p>
                <div>
                  <div
                    className="p-1 recent"
                    style={{
                      backgroundColor: "#e7e8e9",
                      borderBottom: "solid 1px #eeeee",
                    }}
                  >
                    <a
                      href="web-development-blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/WebDevelopmentBlog.png"
                          alt="WebDevelopmentBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>Top 10 Web Development Companies </span>
                      </div>
                    </a>
                  </div>
                  <div
                    className="p-1 recent mt-2"
                    style={{ backgroundColor: "#e7e8e9" }}
                  >
                    <a
                      href="digital-marketing-agency-blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/DigitalMarketingBlog.png"
                          alt="DigitalMarketingBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>Top 10 Digital Marketing Agency </span>
                      </div>
                    </a>
                  </div>
                  <div
                    className="p-1 recent mt-2"
                    style={{
                      backgroundColor: "#e7e8e9",
                      borderBottom: "solid 1px #eeeee",
                    }}
                  >
                    <a
                      href="Graphic-Design-Blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/GraphicDesignBlog.jpg"
                          alt="GraphicDesignBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>Graphic Designing</span>
                      </div>
                    </a>
                  </div>
                  <div
                    className="p-1 recent mt-2"
                    style={{
                      backgroundColor: "#e7e8e9",
                      borderBottom: "solid 1px #eeeee",
                    }}
                  >
                    <a
                      href="online-marketing-services-blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/OnlineMarketingBlog.png"
                          alt="OnlineMarketingBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>The Power Of Online Marketing Services</span>
                      </div>
                    </a>
                  </div>
                  <div
                    className="p-1 recent mt-2"
                    style={{
                      backgroundColor: "#e7e8e9",
                      borderBottom: "solid 1px #eeeee",
                    }}
                  >
                    <a
                      href="mobile-app-development-blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/MobileAppDevelopmentBlog.png"
                          alt="MobileAppDevelopmentBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>Top Mobile App Development Companies</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div>
                <p className="fs-4 mt-4">Social links</p>
                <ul className="d-flex flex-wrap gap-3">
                  <li style={{ listStyle: "none" }}>
                    <a
                      href="https://www.facebook.com/profile.php?id=61558304945053"
                      target="_blank"
                      rel="noreferrer"
                      title="Facebook"
                      style={{
                        padding: " 9px 8px",
                        display: "block",
                        fontSize: "17px",
                        fontWeight: "300",
                        color: "#fff",
                        borderRadius: "10px",
                        background: "#db150e",
                        width: "36px",
                        height: "36px",
                      }}
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li style={{ listStyle: "none" }}>
                    <a
                      href=" https://x.com/mediatech09?t=x-kv93jEG5y0aqezPQ02qw&s=08"
                      target="_blank"
                      rel="noopener"
                      title="Twitter"
                      style={{
                        padding: " 9px 8px",
                        display: "block",
                        fontSize: "17px",
                        fontWeight: "300",
                        color: "#fff",
                        borderRadius: "10px",
                        background: "#db150e",
                        width: "36px",
                        height: "36px",
                      }}
                    >
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </li>

                  <li style={{ listStyle: "none" }}>
                    <a
                      href="https://www.instagram.com/mannixinfotech09/?fbclid=IwAR30ORrOy8gHLBnmw9Nys9CZNYznRUYLyiAXihrlzLH63aVD_y96syb0jJo"
                      target="_blank"
                      rel="noreferrer"
                      title="Instagram"
                      style={{
                        padding: " 9px 8px",
                        display: "block",
                        fontSize: "17px",
                        fontWeight: "300",
                        color: "#fff",
                        borderRadius: "10px",
                        background: "#db150e",
                        width: "36px",
                        height: "36px",
                      }}
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li style={{ listStyle: "none" }}>
                    <a
                      href="https://www.linkedin.com/company/mannix-infotech-solutions-pvt-ltd/"
                      target="_blank"
                      rel="noreferrer"
                      title="Linkedin"
                      style={{
                        padding: " 9px 8px",
                        display: "block",
                        fontSize: "17px",
                        fontWeight: "300",
                        color: "#fff",
                        borderRadius: "10px",
                        background: "#db150e",
                        width: "36px",
                        height: "36px",
                      }}
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Blog3;
