import React, { useState } from "react";
import { Helmet } from "react-helmet";

const LogoDesign = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>Professional Logo Design Services</title>
        <meta
          name="description"
          content="Our team of expert designers specializes in creating unique, custom logos that represent your IT company's vision and values. Let us help you stand out in the digital world with a powerful and memorable logo.
"
        />
        <meta
          name="keywords"
          content="Logo Design|IT Company Logo|Professional Logo Design|Custom Logo Creation|Brand Identity|Graphic Design Services|IT Company Branding
"
        />
        <link
          rel="canonical"
          href="https://www.mannix.in/logo-design-services"
        />
      </Helmet>
      <section className="banner-content-1 pb-5">
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          className="position-absolute top-banner-bg"
          viewBox="0 0 1920 761"
        >
          <g>
            <defs>
              <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
            </defs>
            <clipPath id="SVGID_2_">
              <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
            </clipPath>
            <g className="banner-bg-asw">
              <path
                className="st1"
                d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                        c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
              ></path>
              <path
                className="st2"
                d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                        c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
              ></path>
              <path
                className="st1"
                d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
              ></path>
              <path
                className="st2"
                d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
              ></path>
              <circle className="st1" cx="1536.8" cy="661.3" r="56.2"></circle>
              <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
            </g>
          </g>
        </svg>
        <div className="py-5 container">
          <div className="row align-items-center justify-content-around mt-4">
            <div className="col-lg-6">
              <div className="">
                <h1 className="head-title head-title-without-line m-0 py-3">
                  Logo Design
                </h1>
                <p className="m-0 py-3">
                  There are hundreds of logo design services that want your
                  money's worth - and some even claim to be free. But not all of
                  these services will give you a logo that looks professional.
                  You don't want a logo that appears as a child created it, and
                  you do not want to have to go through a complex and lengthy
                  process to get a final product that meets all of your
                  requirements. We provide high-quality professional logo design
                  for you at the best price.
                </p>
              </div>
            </div>
            <div className="col-lg-5 prod-section2">
              <div className="">
                <form
                  className="register-form"
                  id="register-form"
                  method="post"
                  action=""
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="form_type"
                    id="form_type"
                    value="Android App Development"
                  />
                  <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                    <div className="d-flex">
                      <div className="w-100 mr-2">
                        <label className="w-100">
                          <span>Name</span>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name *"
                            className="form-control"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div className="w-100">
                        <label className="w-100">
                          <span>Email</span>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email id *"
                            className="form-control"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className="d-flex">
                      <label className="w-100">
                        <span>Contact</span>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Contact No *"
                          className="form-control"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Country</span>
                        <input
                          type="text"
                          name="country"
                          id="country"
                          placeholder="Enter your Country"
                          className="form-control"
                          value={formData.country}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Message</span>
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Enter your message *"
                          className="form-control"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </label>
                    </div>

                    <div className="">
                      <button
                        type="submit"
                        name="submit"
                        className="submit btn w-100 g-recaptcha-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="phone_home_sec mt-5 mb-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="text-center">
                {" "}
                <img
                  src="../Images/LogoDesign.png"
                  alt="LogoDesign"
                  width="80%"
                />{" "}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="p_s_home_text">
                <p className="animate__animated animate__bounce fs-1">
                  {" "}
                  Logo <span> Design</span>{" "}
                </p>
                <p className="text-justify">
                  {" "}
                  There are hundreds of logo design services that want your
                  money's worth - and some even claim to be free. But not all of
                  these services will give you a logo that looks professional.
                  You don't want a logo that appears as a child created it, and
                  you do not want to have to go through a complex and lengthy
                  process to get a final product that meets all of your
                  requirements. We provide high-quality professional logo design
                  for you at the best price.
                </p>
                <div className="pg_home_btn">
                  {" "}
                  <a href="/">Contact now</a>{" "}
                </div>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="hire-dedicated-2 container py-4">
          <div className="pb-4 text-center">
            <h2 className="head-title">
              Our Mannix Infotech Solutions Pvt.Ltd. Logo design service
              includes
            </h2>
          </div>
          <div className="row justify-content-center">
            <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fas fa-user-circle"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Logo design</h2>
                    <p className="m-0 py-2">
                      Our graphic design team is efficient in creating logo
                      icons designed using attractive images or symbols. This is
                      one of the best ways to make the customer's brand
                      recognizable while having a more profound meaning.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fas fa-user-circle"></i>
                </div>
              </div>
            </div>
            <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fas fa-cube"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">3D Logo design</h2>
                    <p className="m-0 py-2">
                      Each logo designed by our artists is exclusive and based
                      on the specific necessities of our consumers. Our
                      designers can create neat but attractive 3D logos that can
                      build brand awareness while promoting brand recall.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fas fa-cube"></i>
                </div>
              </div>
            </div>
            <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fas fa-chart-area"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Illustration Logo development</h2>
                    <p className="m-0 py-2">
                      Our designers create exceptional illustration logos by
                      understanding the client's company culture and audience.
                      This is usually the setting up of an illustrated character
                      to represent a business.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fas fa-chart-area"></i>
                </div>
              </div>
            </div>
            <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fas fa-palette"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Monogram Logo design</h2>
                    <p className="m-0 py-2">
                      We make the best monogram logo designs for our clients
                      using the letters or initials of the respective company.
                      This helps them create a unique identity and make their
                      brand stand out from other competitors in the industry.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fas fa-palette"></i>
                </div>
              </div>
            </div>
            <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fas fa-ad"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">
                      Brand Marketing &amp; Advertising
                    </h2>
                    <p className="m-0 py-2">
                      Our Website Development Team takes care of branding and
                      marketing your business logo and uses various techniques
                      like social media to advertise your brand to the targeted
                      customers. They constantly analyze your competitive brands
                      and ensure your brand availability to your customers.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fas fa-ad"></i>
                </div>
              </div>
            </div>
            <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fas fa-icons"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Minimalist Logo design</h2>
                    <p className="m-0 py-2">
                      Nowadays, companies use minimalist and contemporary
                      designs that instill a sense of elegance and simple
                      appeal. These logos can have a significant impact on the
                      minds of potential targets and improve brand recall.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fas fa-icons"></i>
                </div>
              </div>
            </div>
            <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fas fa-address-card"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Flat Logo design</h2>
                    <p className="m-0 py-2">
                      Our graphic designers cleverly use the art and tools to
                      create flat logos that reflect the elegant combination of
                      colors, patterns, and text. Their simplistic yet prominent
                      appearance distinguishes flat logos.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fas fa-address-card"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="hire-dedicated-3 container py-4">
          <div className="pb-4 text-center">
            <h2 className="head-title">Additional Services</h2>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
              <div className="d-flex align-items-center justify-content-sm-start">
                <div>
                  <i className="fas fa-file-powerpoint"></i>
                </div>
                <div>
                  <h2 className="m-0 py-2">Powerpoint Design</h2>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process ">
              <div className="d-flex align-items-center justify-content-sm-start">
                <div>
                  <i className="fas fa-camera-retro"></i>
                </div>
                <div>
                  <h2 className="m-0 py-2">Photoshop Designs </h2>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
              <div className="d-flex align-items-center justify-content-sm-start">
                <div>
                  <i className="fas fa-chart-area"></i>
                </div>
                <div>
                  <h2 className="m-0 py-2">Infographic Design</h2>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
              <div className="d-flex align-items-center justify-content-sm-start">
                <div>
                  <i className="fas fa-cube"></i>
                </div>
                <div>
                  <h2 className="m-0 py-2">3D Design</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="container">
        <div class="theme-title theme_solution padding-why2 ">
          <h4>Why should you hire us instead of others?</h4>
          <ul class="list-style-blt">
            <li> The lowest prices for a professional design.</li>
            <li> Experienced designers to work with directly.</li>
            <li> The best way to get a unique logo.</li>
            <li> Work with a designer one-on-one.</li>
            <li> Premium service available for agencies.</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LogoDesign;
