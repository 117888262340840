import React, { useState } from "react";
import { Helmet } from "react-helmet";

const HotelManagment = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>
          Hotel Management Software Company | Hospitality Management Solutions |
          Mannix Infotech Solutions, Ahmedabad
        </title>
        <meta
          name="description"
          content=" Efficiently manage your hotel operations with Mannix Infotech's custom hotel management software solutions. Our experts design and develop tailored systems to enhance guest experiences, streamline bookings, and increase revenue."
        />
        <meta
          name="keywords"
          content="Top Hotel Management Software company in India |Best Hotel Management Software Services in Gujarat |List of company in Ahmedabad Hotel Management Software in Gujarat | Hotel Management Software services in Ahmedabad | Top 10 Hotel Management Software company in Gujarat |Top 5 Hotel Management Software Company in Ahmedabad | Hotel Management Software Near by | Hotel Management Software near me | Hotel Management Software | Hotel Management Software | Hotel Management Software Services | Hotel Management Software Development"
        />

        <link
          rel="canonical"
          href="https://www.mannix.in/hotelmanagement-software-services"
        />
      </Helmet>
      <section className="banner-content-1 pb-5">
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          className="position-absolute top-banner-bg"
          viewBox="0 0 1920 761"
        >
          <g>
            <defs>
              <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
            </defs>
            <clipPath id="SVGID_2_">
              <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
            </clipPath>
            <g className="banner-bg-asw">
              <path
                className="st1"
                d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                            c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
              ></path>
              <path
                className="st2"
                d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                            c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
              ></path>
              <path
                className="st1"
                d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
              ></path>
              <path
                className="st2"
                d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
              ></path>
              <circle className="st1" cx="1536.8" cy="661.3" r="56.2"></circle>
              <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
            </g>
          </g>
        </svg>
        <div className="py-5 container">
          <div className="row align-items-center justify-content-around mt-4">
            <div className="col-lg-6">
              <div className="">
                <h1 className="head-title head-title-without-line m-0 py-3">
                  Hotel Management Software
                </h1>
                <p className="m-0 py-3">
                  In today's disruptive environment, there are many software
                  vendors offering hotel solutions.The majority of the latest
                  suppliers offer a cheap product with minimal options and
                  practicality, making it challenging to manage your hotel.
                  Mannix Infotech Solutions Pvt.Ltd. is among the few hotel
                  software opstem suppliers to supply cloud-based and on-premise
                  solutions that cover all essential functionalities like an
                  online booking engine, reception, work, maintenance,
                  invoicing, reports, and analysis.
                </p>
              </div>
            </div>
            <div className="col-lg-5 prod-section2">
              <div className="">
                <form
                  className="register-form"
                  id="register-form"
                  method="post"
                  action=""
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="form_type"
                    id="form_type"
                    value="Android App Development"
                  />
                  <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                    <div className="d-flex">
                      <div className="w-100 mr-2">
                        <label className="w-100">
                          <span>Name</span>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name *"
                            className="form-control"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div className="w-100">
                        <label className="w-100">
                          <span>Email</span>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email id *"
                            className="form-control"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className="d-flex">
                      <label className="w-100">
                        <span>Contact</span>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Contact No *"
                          className="form-control"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Country</span>
                        <input
                          type="text"
                          name="country"
                          id="country"
                          placeholder="Enter your Country"
                          className="form-control"
                          value={formData.country}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Message</span>
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Enter your message *"
                          className="form-control"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </label>
                    </div>

                    <div className="">
                      <button
                        type="submit"
                        name="submit"
                        className="submit btn w-100 g-recaptcha-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="col-md-12 col-sm-12 pt-5">
        <div className="theme-title text-left">
          <h2 className="text-center ">Hotel Management Software</h2>
          <p className="text-justify p-3">
            In today's disruptive environment, there are many software vendors
            offering hotel solutions.The majority of the latest suppliers offer
            a cheap product with minimal options and practicality, making it
            challenging to manage your hotel. Mannix Infotech Solutions Pvt.Ltd.
            is among the few hotel software opstem suppliers to supply
            cloud-based and on-premise solutions that cover all essential
            functionalities like an online booking engine, reception, work,
            maintenance, invoicing, reports, and analysis.
          </p>
        </div>
      </div>
      <section className="hotelbook_software">
        <div className="container">
          <h2>
            Variety of <span>hotel management</span> opstem software.
          </h2>
          <div className="hotelbook_cover">
            <div className="row">
              <div className="col-sm-6">
                <div className="hotelbook_inner">
                  <div className="hotelbook_top">
                    <span>A</span>
                  </div>
                  <div className="hotelbook_bottom">
                    <h3>
                      Cloud <span>software</span> solution
                    </h3>
                    <p className="text-justify pb-2">
                      Cloud-based hotel solutions provide sleek, easy-to-learn
                      interfaces that permit your employees to specialize in the
                      guest rather than fighting excessively complicated and
                      typically redundant software opstems. As the industry
                      develops and more and more hotels adopt cloud-based hotel
                      software, developers are incorporating more features.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="hotelbook_inner hotelbook_inner01">
                  <div className="hotelbook_top">
                    <span>B</span>
                  </div>
                  <div className="hotelbook_bottom">
                    <h3>
                      On-site <span>hotel</span> solution
                    </h3>
                    <p className="text-justify pb-2">
                      The on-premise hotel software opstem has been around for
                      an extended time. That is why its options and practicality
                      have been refined over the years to reflect the
                      hospitality industry's requirements directly. This often
                      results in an organic patchwork of features that are more
                      complex to use, especially for staff members.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="modules_hotel  mb_90">
        <div className="container">
          <div className="why_choose_title text-white">
            Hotel Management Software - A Necessity for every business
          </div>
          <div className="row align-items-center">
            <div className="col-sm-4">
              <div className="moduleshotel_left">
                <ul>
                  <li>
                    <h3>
                      Flexible Mannix Infotech Solutions Pvt.Ltd. Hotel PMS -
                      Designed to Help You Succeed
                    </h3>
                    <p className="text-justify">
                      The Mannix Infotech Solutions Pvt.Ltd., cloud-based Hotel
                      Property Management Systems is packed with next-generation
                      capabilities to help you automate and streamline daily
                      operations, improve overall efficiency, and maximize
                      revenue.
                    </p>
                  </li>
                  <li>
                    <h3>
                      A simple hotel reservation opstem for direct online
                      reservations
                    </h3>
                    <p className="text-justify">
                      A pixelated hotel booking engine can be added to your
                      existing website, allowing guests to book rooms directly
                      from your hotel website. The Mannix Infotech Solutions
                      Pvt.Ltd. web booking engine is optimized for mobile -
                      allows visitors to book rooms from their smartphone or
                      tablet.{" "}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-sm-4 text-center">
              <canter>
                <img
                  src="./HotelManagementSoftware.png"
                  className="img-fluid"
                  alt="HotelManagementSoftware"
                />
              </canter>
            </div>
            <div className="col-sm-4">
              <div className="moduleshotel_left moduleshotel_right">
                <ul>
                  <li>
                    <h3>Maximize Market Reach with Channel Manager</h3>
                    <p className="text-justify">
                      Mannix Infotech Solutions Pvt.Ltd's. Hotel Property
                      Management Systems (PMS) provides fully integrated channel
                      management software that allows you to target a global
                      audience without double-booking issues.{" "}
                    </p>
                  </li>
                  <li>
                    <h3>
                      Choose Mannix Infotech Solutions Pvt.Ltd. as your trusted
                      hotel software provider
                    </h3>
                    <p className="text-justify">
                      We tend to invite you to consult Mannix Infotech Solutions
                      Pvt.Ltd. for a feature-rich Hotel Property Management
                      Systems that will merge all of your knowledge and mix all
                      of your opstems so your business will speed up its growth.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HotelManagment;
