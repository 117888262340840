import React from "react";

const Blog5 = () => {
  return (
    <div>
      <div>
        <section
          className="ops_blog_header"
          style={{
            background: "url('../blogbanner.jpg')",
            height: "270px",
            backgroundSize: "cover !important",
            padding: "80px 0",
          }}
        >
          <div className="container">
            <div className="ops_blog_header_content">
              <h1 className="fw-bold text-danger">
                Unlocking the Secrets: Dive into the World of Mannix Infotech
                Solutions Pvt.Ltd.
              </h1>
            </div>
          </div>
        </section>

        <section>
          <div className=" d-flex flex-wrap p-5">
            <div className="col-lg-8 d-flex flex-wrap justify-content-center ">
              <div
                className="mb-3 px-5"
                style={{
                  //   flex: "1 1 300px",
                  //   maxWidth: "300px",
                  margin: "0 auto",
                  width: "100%",
                }}
              >
                <div
                  className="card"
                  style={{
                    height: "",
                    backgroundColor: "#d1d8e0",
                    borderRadius: "20px",
                    width: "100%",
                  }}
                >
                  <img
                    src="../Images/DigitalMarketingBlog.png"
                    alt="DigitalMarketingBlog"
                    style={{ maxWidth: "100%", borderRadius: "20px 20px 0 0" }}
                  />
                  <p className="p-2 text-center" style={{ fontSize: "25px" }}>
                    Top 10 Digital Marketing Agency in Ahmedabad
                  </p>
                  <p className="p-2 text-center" style={{ fontSize: "16px" }}>
                    In Ahmedabad, where entrepreneurship and business coexist,
                    having a strong web presence is crucial. As businesses
                    attempted to thrive in the cutthroat online environment by
                    offering success stories in addition to services, a select
                    few agencies rose to prominence in the sector. Come along as
                    we take you on a journey where strategy and creativity
                    collide and businesses locate their online spaces as you
                    discover the top 10 digital marketing agencies in Ahmedabad.
                  </p>
                  <div className="p-3" style={{ fontSize: "16px" }}>
                    <p>
                      In Ahmedabad, a city brimming with entrepreneurial spirit,
                      there is a growing need for experts with experience in
                      digital marketing. In this era of digital supremacy,
                      businesses are acknowledging the need of having a strong
                      online presence. As we navigate the digital realm, it
                      becomes increasingly evident that selecting the right
                      agency can have a significant impact. These are the top
                      ten digital marketing agencies in Ahmedabad, each with a
                      unique narrative to tell.
                    </p>
                    <div>
                      <h3 style={{ fontSize: "24px" }}>
                        1.Mannix infotech Solutoins Pvt. Ltd.
                      </h3>
                      <p>
                        The top digital marketing agency in Ahmedabad is Mannix
                        Infotech. They offer top-notch website design and
                        digital marketing services all under one roof.
                      </p>
                      <p>
                        Mannix Infotech is dedicated to transforming your ideas
                        into reality and offers an extensive array of services
                        designed to enhance our business.
                      </p>
                      <p>1.Graphic designing :</p>
                      <div className="mx-4 mb-2">
                        <p> a. Branding material Graphics</p>
                        <p>b. Social Media Marketing </p>
                        <p>c. Customise Graphics</p>
                      </div>

                      <p>2.Social Media Marketing :</p>
                      <div className="mx-4 mb-2">
                        <p> a. Social Media Management</p>
                        <p>b. Social Media Advertising</p>
                        <p>c. Quality Lead Generation</p>
                      </div>

                      <p>3. Web Design and Development :</p>
                      <div className="mx-4 mb-2">
                        <p>a. WordPress Based Responsive Website</p>
                        <p>b. Ecommerce Website </p>
                        <p>c. Customise Website</p>
                      </div>

                      <p>4. SEO :</p>
                      <div className="mx-4 mb-2">
                        <p>a. On Page SEO</p>
                        <p>b. Off Page SEO</p>
                        <p>c. Technical SEO</p>
                        <p>d. Local SEO</p>
                      </div>

                      <p>5. PPC Advertising :</p>
                      <div className="mx-4 mb-2">
                        <p>a. Google Search Ad</p>
                        <p>b. Google Display Ad</p>
                        <p>c. Google Shopping Ad</p>
                        <p>d. Google Video Ad</p>
                      </div>

                      <p>
                        Our success at Mannix Infotech is driven by our
                        enthusiasm for innovation and creativity. Their
                        committed group of experts takes great satisfaction in
                        meeting and exceeding our expectations with their work.
                      </p>
                      <p>
                        They have a proven track record of accomplishment and
                        have worked with several clients in a variety of
                        industries. Accompany us on a path of development and
                        adjustment as they assist you in accomplishing our
                        corporate objectives.
                      </p>
                      <p>
                        {" "}
                        Website :<a href="/"> www.mannix.in</a>
                      </p>
                      <p>
                        Phone :{" "}
                        <a
                          href="tel:+91 9664053983"
                          className=""
                          title="phone"
                          target="_blank"
                          rel="noreferrer"
                        >
                          +91 96640 53983
                        </a>
                      </p>
                      <p>
                        E-mail:{" "}
                        <a
                          className=""
                          href="mailto:info@mannix.in"
                          title="mailto"
                          target="_blank"
                          rel="noreferrer"
                        >
                          info@mannix.in
                        </a>
                      </p>
                    </div>

                    <div className="mt-3">
                      <h3 style={{ fontSize: "24px" }}>
                        2. dp digital marketing agency
                      </h3>
                      <p>
                        With services including Google Ads, SEO, Social Media
                        Marketing, Google My Business, Lead Generations,
                        Facebook Ads, and many more, DP Digital Marketing
                        Agency, one of the most well-known digital marketing
                        agencies in Ahmedabad, has set a subtle benchmark in the
                        creation of sales multiplier strategies. We've
                        communicated with over ten clients thus far.
                      </p>
                    </div>

                    <div className="mt-3">
                      <h3 style={{ fontSize: "24px" }}>
                        3. Treevent : Digital Marketing Agency
                      </h3>
                      <p>
                        The top digital marketing firm is Treevent. Digital
                        marketing and branding are our areas of competence.
                        Whether you are a startup or an established brand, we
                        help you bring your dreams to life. Creating ideas and
                        making them a reality to appeal to the target market is
                        what excites us. You are in capable hands with over
                        three years of expertise.
                      </p>
                    </div>

                    <div className="mt-3">
                      <h3 style={{ fontSize: "24px" }}>
                        4. Backlinker- Digital Marketing Agency
                      </h3>
                      <p>
                        Backlinkers is a digital marketing company that offers
                        products and services. Worldwide We at Backlinker are
                        your strategic partners in navigating the always
                        changing digital landscape—we're not simply another
                        digital marketing agency. Our team comprises both
                        creative and seasoned experts, and we specialize in
                        creating custom digital marketing solutions that improve
                        your brand's visibility, increase engagement, and
                        increase conversions.{" "}
                      </p>
                      <p>
                        Using the newest tools and technology, we create
                        immersive content, run data-driven SEO campaigns, and
                        employ cutting edge social media techniques to offer
                        quantifiable results that go above and beyond. We are
                        here to increase your digital footprint and spur
                        significant growth, whether you are an established
                        company trying to stay ahead of the curve or a start-up
                        hoping to make waves.{" "}
                      </p>
                      <p>
                        Come along with us on the path to digital success by
                        getting in touch with us to learn more about how
                        Backlinker can strengthen your business online.
                      </p>
                    </div>

                    <div className="mt-3">
                      {" "}
                      <h3 style={{ fontSize: "24px" }}>
                        5. Think Work Media - Digital Marketing Agency
                      </h3>
                      <p>
                        assisting a start-up with brand development Paid Social
                        Media and Digital Marketing Consultant PPC on Social
                        Media
                      </p>
                    </div>

                    <div className="mt-3">
                      <h3 style={{ fontSize: "24px" }}>
                        6. Creative digital marketing agency
                      </h3>
                      <p>
                        Creative is a digital marketing firm that assists
                        companies in reaching their target audience and
                        enhancing their online presence. Search engine
                        optimization (SEO), pay-per-click (PPC) advertising,
                        social media marketing, content marketing, corporate
                        video, product video, and website design and development
                        are just a few of the many services that our team of
                        skilled digital marketing specialists provides.
                      </p>
                    </div>

                    <div className="mt-3">
                      <h3 style={{ fontSize: "24px" }}>
                        7. Prefix Digital Marketing Agency
                      </h3>
                      <p>
                        Prefix Digital Marketing Agency is a top Indian company
                        that specializes in mobile app development, SEO, site
                        design and development, and digital marketing.
                      </p>
                    </div>

                    <div className="mt-3">
                      <h3 style={{ fontSize: "24px" }}>
                        8. Deep Digital Marketing Agency
                      </h3>
                      <p>
                        We offer professional strategies and solutions for
                        digital marketing services that will help you expand and
                        market your company.
                      </p>
                    </div>

                    <div className="mt-3">
                      <h3 style={{ fontSize: "24px" }}>
                        9. Shivani Digital Marketing Agency
                      </h3>
                      <p>
                        an award-winning media company with a focus on online PR
                        and digital marketing. Search Engine Optimization (SEO),
                        Lead Generation, Social Media Marketing (SMM), and
                        Content Marketing are just a few of the Digital
                        Marketing services we offer.
                      </p>
                    </div>

                    <div className="mt-3">
                      <h3 style={{ fontSize: "24px" }}>
                        10. JP Digital Marketing Agency
                      </h3>
                      <p>
                        Managing digital marketing initiatives, mostly on
                        Amazon, falls within the purview of the Digital
                        Marketing Specialist. Project management, marketing
                        program management, performance analysis, and reporting
                        are all highly valued in this position.
                      </p>
                    </div>

                    <div className="nt-3">
                      <h2 style={{ fontSize: "25px" }}>Conclusion </h2>
                      <p>
                        One thing is evident as we examine the range of services
                        provided by Ahmedabad's top 10 digital marketing
                        agencies: the influence of digital transformation.
                        Businesses have countless options to reach their target
                        audience and experience limitless growth in this era of
                        innovation and connectivity.
                      </p>
                      <p>
                        We at Mannix are aware of Ahmedabad's commercial
                        climate. Our emphasis on return on investment guarantees
                        that every digital strategy is intended to produce
                        results right away, launching your company into new
                        heights. We are dedicated to providing excellence,
                        whether it be through eye-catching graphic designs,
                        interesting social media campaigns, or SEO tactics that
                        raise your profile.
                      </p>
                      <p>
                        A digital marketing agency plays a critical role in a
                        city where innovation and tradition collide and
                        aspirations are realized. One question still stands as
                        we see Ahmedabad's business sector develop: how will
                        your company adapt to the digital revolution?
                      </p>
                      <p>
                        Are you ready to embark on a digital journey that
                        transforms your business? Reach out to us at Mannix
                        Infotech, where artistry meets strategy, and let’s
                        create digital success stories together.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div>
                <p className="fs-4">Categories</p>
                <ul>
                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> App & Software Development </span>
                    </a>
                  </li>
                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> Graphic Designing</span>
                    </a>
                  </li>
                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> Web Development </span>
                    </a>
                  </li>
                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> Online Marketing </span>
                    </a>
                  </li>

                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> Digital Marketing </span>
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <p className="fs-4">Recent posts</p>
                <div>
                  <div
                    className="p-1 recent"
                    style={{
                      backgroundColor: "#e7e8e9",
                      borderBottom: "solid 1px #eeeee",
                    }}
                  >
                    <a
                      href="web-development-blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/WebDevelopmentBlog.png"
                          alt="WebDevelopmentBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>Top 10 Web Development Companies </span>
                      </div>
                    </a>
                  </div>
                  <div
                    className="p-1 recent mt-2"
                    style={{ backgroundColor: "#e7e8e9" }}
                  >
                    <a
                      href="digital-marketing-agency-blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/DigitalMarketingBlog.png"
                          alt="DigitalMarketingBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>Top 10 Digital Marketing Agency </span>
                      </div>
                    </a>
                  </div>
                  <div
                    className="p-1 recent mt-2"
                    style={{
                      backgroundColor: "#e7e8e9",
                      borderBottom: "solid 1px #eeeee",
                    }}
                  >
                    <a
                      href="Graphic-Design-Blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/GraphicDesignBlog.jpg"
                          alt="GraphicDesignBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>Graphic Designing</span>
                      </div>
                    </a>
                  </div>
                  <div
                    className="p-1 recent mt-2"
                    style={{
                      backgroundColor: "#e7e8e9",
                      borderBottom: "solid 1px #eeeee",
                    }}
                  >
                    <a
                      href="online-marketing-services-blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/OnlineMarketingBlog.png"
                          alt="OnlineMarketingBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>The Power Of Online Marketing Services</span>
                      </div>
                    </a>
                  </div>
                  <div
                    className="p-1 recent mt-2"
                    style={{
                      backgroundColor: "#e7e8e9",
                      borderBottom: "solid 1px #eeeee",
                    }}
                  >
                    <a
                      href="mobile-app-development-blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/MobileAppDevelopmentBlog.png"
                          alt="MobileAppDevelopmentBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>Top Mobile App Development Companies</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div>
                <p className="fs-4 mt-4">Social links</p>
                <ul className="d-flex flex-wrap gap-3">
                  <li style={{ listStyle: "none" }}>
                    <a
                      href="https://www.facebook.com/profile.php?id=61558304945053"
                      target="_blank"
                      rel="noreferrer"
                      title="Facebook"
                      style={{
                        padding: " 9px 8px",
                        display: "block",
                        fontSize: "17px",
                        fontWeight: "300",
                        color: "#fff",
                        borderRadius: "10px",
                        background: "#db150e",
                        width: "36px",
                        height: "36px",
                      }}
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li style={{ listStyle: "none" }}>
                    <a
                      href=" https://x.com/mediatech09?t=x-kv93jEG5y0aqezPQ02qw&s=08"
                      target="_blank"
                      rel="noopener"
                      title="Twitter"
                      style={{
                        padding: " 9px 8px",
                        display: "block",
                        fontSize: "17px",
                        fontWeight: "300",
                        color: "#fff",
                        borderRadius: "10px",
                        background: "#db150e",
                        width: "36px",
                        height: "36px",
                      }}
                    >
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </li>

                  <li style={{ listStyle: "none" }}>
                    <a
                      href="https://www.instagram.com/mannixinfotech09/?fbclid=IwAR30ORrOy8gHLBnmw9Nys9CZNYznRUYLyiAXihrlzLH63aVD_y96syb0jJo"
                      target="_blank"
                      rel="noreferrer"
                      title="Instagram"
                      style={{
                        padding: " 9px 8px",
                        display: "block",
                        fontSize: "17px",
                        fontWeight: "300",
                        color: "#fff",
                        borderRadius: "10px",
                        background: "#db150e",
                        width: "36px",
                        height: "36px",
                      }}
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li style={{ listStyle: "none" }}>
                    <a
                      href="https://www.linkedin.com/company/mannix-infotech-solutions-pvt-ltd/"
                      target="_blank"
                      rel="noreferrer"
                      title="Linkedin"
                      style={{
                        padding: " 9px 8px",
                        display: "block",
                        fontSize: "17px",
                        fontWeight: "300",
                        color: "#fff",
                        borderRadius: "10px",
                        background: "#db150e",
                        width: "36px",
                        height: "36px",
                      }}
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Blog5;
