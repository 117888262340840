import React, { useState } from "react";
import { Helmet } from "react-helmet";

const StaticDevlopment = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div className="">
      <Helmet>
        <title>Professional Static Website Development Services</title>
        <meta
          name="description"
          content="We create high-performance, secure, and scalable static websites designed to boost your brand’s online visibility. Ideal for businesses seeking a reliable and cost-effective web solution.
"
        />
        <meta
          name="keywords"
          content="Custom Static Website Development|Secure & Fast Websites|static site services|responsive static design
"
        />
        <link
          rel="canonical"
          href="https://www.mannix.in/static-development-services"
        />
      </Helmet>
      <div className="hire-dedicated services mt-0">
        <section className="banner-content-1">
          <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            className="position-absolute top-banner-bg"
            viewBox="0 0 1920 761"
          >
            <g>
              <defs>
                <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
              </defs>
              <clipPath id="SVGID_2_">
                <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
              </clipPath>
              <g className="banner-bg-asw">
                <path
                  className="st1"
                  d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                        c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
                ></path>
                <path
                  className="st2"
                  d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                        c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
                ></path>
                <path
                  className="st1"
                  d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
                ></path>
                <path
                  className="st2"
                  d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
                ></path>
                <circle
                  className="st1"
                  cx="1536.8"
                  cy="661.3"
                  r="56.2"
                ></circle>
                <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
              </g>
            </g>
          </svg>
          <div className="py-5 container">
            <div className="row align-items-center justify-content-around mt-4">
              <div className="col-lg-6">
                <div className="">
                  <h1 className="head-title head-title-without-line m-0 py-3">
                    Static Website Development
                  </h1>
                  <p className="m-0 py-3">
                    Static website design is a web design that has fixed content
                    and web pages in HTML code that are stored on the webserver.
                    Until and unless the website developer makes any changes in
                    the website nothing changes on the web page. Mannix Infotech
                    Solutions Pvt.Ltd. is one of the best Static Web Development
                    company in India that help many businesses in building their
                    online presence. Our team of experts has experience in
                    developing high end static website design and development
                    that requires the least programming and database design.
                  </p>
                  <p className="m-0 py-3">
                    Static website design is simple and can be considered as a
                    brochure for the business that serves the purpose of
                    demonstrating the business and products and services
                    provided by that organization. This kind of website displays
                    the same information to all the visitors and can't be
                    changed by themselves. It is best suitable for the business
                    that wants to create an online presence and develop contact
                    with the clients online.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 prod-section2">
                <div className="">
                  <form
                    className="register-form"
                    id="register-form"
                    method="post"
                    action=""
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="hidden"
                      name="form_type"
                      id="form_type"
                      value="Android App Development"
                    />
                    <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                      <div className="d-flex">
                        <div className="w-100 mr-2">
                          <label className="w-100">
                            <span>Name</span>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Name *"
                              className="form-control"
                              value={formData.name}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                        <div className="w-100">
                          <label className="w-100">
                            <span>Email</span>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Email id *"
                              className="form-control"
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <label className="w-100">
                          <span>Contact</span>
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            placeholder="Contact No *"
                            className="form-control"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Country</span>
                          <input
                            type="text"
                            name="country"
                            id="country"
                            placeholder="Enter your Country"
                            className="form-control"
                            value={formData.country}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Message</span>
                          <textarea
                            name="message"
                            id="message"
                            placeholder="Enter your message *"
                            className="form-control"
                            value={formData.message}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </label>
                      </div>

                      <div className="">
                        <button
                          type="submit"
                          name="submit"
                          className="submit btn w-100 g-recaptcha-btn"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="container-fluid row align-items-center">
        <div className="col-md-6 col-sm-6">
          <div className="theme-title theme_web">
            <h3 className="static_css m-4">Static Website Design Services -</h3>
            <ul className="list-style-blt m-4">
              <li> Understand the requirements of the business.</li>
              <li> Examine the requirements of the client. </li>
              <li>
                {" "}
                Making or designing a proper layout of the static website.
              </li>
              <li>
                {" "}
                Positioning the ideal images and brochures of the business on
                the layout of the website.
              </li>
              <li>
                {" "}
                Designing a website that is easy to navigate on all browsers.
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-6 col-sm-6">
          <div className="theme-title theme_solution m-4">
            <h4>Why should you go for only Static Website Design?</h4>
            <ul className="list-style-blt m-4">
              <li> Simple to design and effortless to host.</li>
              <li> Google prefers and loads static websites very easily.</li>
              <li>
                {" "}
                Easy and quick when downloading brochures and images of
                businesses and products.
              </li>
              <li> Extraordinary navigation for search engines.</li>
              <li>
                {" "}
                Security configurations are easy to set up for static websites
                making them more secure.
              </li>
              <li>
                {" "}
                Complete management of the content of the website and web pages
                and less content and scripting required.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaticDevlopment;
