import React, { useState } from "react";
import { Helmet } from "react-helmet";

const PackagingDesign = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>Innovative Packaging Design Services</title>
        <meta
          name="description"
          content="We provide packaging design services and solutions for both brands and product manufacturing. Get a quote today and build the best box possible.
"
        />
        <meta
          name="keywords"
          content="Packaging Design|IT Product Packaging|Custom Packaging|Professional Packaging Design|Product Presentation|Graphic Design Services|IT Company Packaging Solutions
"
        />
        <link
          rel="canonical"
          href="https://www.mannix.in/packaging-design-services"
        />
      </Helmet>
      <section className="banner-content-1 pb-5">
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          className="position-absolute top-banner-bg"
          viewBox="0 0 1920 761"
        >
          <g>
            <defs>
              <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
            </defs>
            <clipPath id="SVGID_2_">
              <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
            </clipPath>
            <g className="banner-bg-asw">
              <path
                className="st1"
                d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                            c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
              ></path>
              <path
                className="st2"
                d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                            c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
              ></path>
              <path
                className="st1"
                d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
              ></path>
              <path
                className="st2"
                d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
              ></path>
              <circle className="st1" cx="1536.8" cy="661.3" r="56.2"></circle>
              <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
            </g>
          </g>
        </svg>
        <div className="py-5 container">
          <div className="row align-items-center justify-content-around mt-4">
            <div className="col-lg-6">
              <div className="">
                <h1 className="head-title head-title-without-line m-0 py-3">
                  Packaging Design
                </h1>
                <p className="m-0 py-3">
                  Product packaging is an integral part of the shipping and
                  fulfilment process as it protects your products from impact
                  and bruising or environmental damage. Most importantly, it's
                  the first thing your customer sees when they come to their
                  door or when they look for a product at the mall or
                  supermarket. The packaging design is the first impression your
                  brand creates in person and that's a big deal!
                </p>
              </div>
            </div>
            <div className="col-lg-5 prod-section2">
              <div className="">
                <form
                  className="register-form"
                  id="register-form"
                  method="post"
                  action=""
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="form_type"
                    id="form_type"
                    value="Android App Development"
                  />
                  <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                    <div className="d-flex">
                      <div className="w-100 mr-2">
                        <label className="w-100">
                          <span>Name</span>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name *"
                            className="form-control"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div className="w-100">
                        <label className="w-100">
                          <span>Email</span>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email id *"
                            className="form-control"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className="d-flex">
                      <label className="w-100">
                        <span>Contact</span>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Contact No *"
                          className="form-control"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Country</span>
                        <input
                          type="text"
                          name="country"
                          id="country"
                          placeholder="Enter your Country"
                          className="form-control"
                          value={formData.country}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Message</span>
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Enter your message *"
                          className="form-control"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </label>
                    </div>

                    <div className="">
                      <button
                        type="submit"
                        name="submit"
                        className="submit btn w-100 g-recaptcha-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="phone_home_sec mt-5 mb-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="text-center">
                {" "}
                <img
                  src="../Images/PackagingDesign.png"
                  alt="PackagingDesign"
                  width="80%"
                />{" "}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="p_s_home_text">
                <p className="animate__animated animate__bounce fs-1">
                  {" "}
                  Packaging <span>Design</span>{" "}
                </p>
                <p className="text-justify">
                  {" "}
                  There are hundreds of logo design services that want your
                  money's worth - and some even claim to be free. But not all of
                  these services will give you a logo that looks professional.
                  You don't want a logo that appears as a child created it, and
                  you do not want to have to go through a complex and lengthy
                  process to get a final product that meets all of your
                  requirements. We provide high-quality professional logo design
                  for you at the best price.
                </p>
                <div className="pg_home_btn">
                  {" "}
                  <a href="/">Contact now</a>{" "}
                </div>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="hire_us">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <div className="hire_us_inner">
                <h2>Our Packaging Design Services include-</h2>
                <ul>
                  <li className="color_list_1">
                    <h4>Paper / Pocket</h4>
                    <p className="text-justify">
                      Paper-based pouches are great for holding different types
                      of consumables, and there are a number of different kinds
                      of pouches. They can be simple, resealable or even contain
                      a valve. (This allows roasters to immediately pack fresh
                      roasted coffee without worrying about the bag opening due
                      to trapped gases. It also prevents air from entering and
                      retains freshness for a long time.) These types of
                      packaging can be designed in different ways, they can be
                      labeled by self-adhesive stickers, or they can be directly
                      printed or screen printed. Paper bags are the cheapest
                      media.
                    </p>
                  </li>
                  <li className="color_list_2 list_extra">
                    <h4>Cardboard box</h4>
                    <p className="text-justify">
                      One of the best solutions to keep your goods safe
                      Cardboard boxes are industrially prefabricated boxes,
                      primarily used for the packaging of products and
                      materials. They have several options to make the product
                      packaging attractive, from screen printing to direct
                      printing with more than 4 colors and with special coatings
                      and finishes (like metal, matt, etc.) There is a huge
                      range. types of boxes with regard to folding and material.
                      also type. The designs can be very diverse according to
                      customers' needs. Heavy industrial packaging is often
                      screen printed in one color, with the material color brown
                      by default, electrical appliances are more luxurious, with
                      special coatings and vibrant and vibrant colors.
                    </p>
                  </li>
                  <li className="color_list_3">
                    <h4>Metal box</h4>
                    <p className="text-justify">
                      Metal cans often offer consumables, such as tea leaves or
                      coffee beans, in some cases food specialties. Metal boxes
                      can be embossed, engraved and also shrink wrapped, and
                      they can be visually stunning, if designed well. Modern
                      printing technologies make our designs vibrant and
                      eye-catching. If you choose to decide on this kind of
                      packaging for your product, you won’t be disappointed. We
                      also have experience in the design of this type of
                      container. They can be well designed, using “white
                      spaces”, where the raw material can be clearly identified,
                      adding a unique and additional appearance to the existing
                      design.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <div className="theme-title theme_solution padding-why2 pb_90">
          <h4>Why should you hire us instead of others?</h4>
          <ul className="list-style-blt">
            <li>
              {" "}
              Our custom company understands that packaging is an important part
              of productive product delivery, regulatory compliance, protection,
              storage, and more. When product integrity should be maintained at
              all times, selecting the right packaging company is particularly
              important.
            </li>
            <li>
              {" "}
              Mannix Infotech Solutions Pvt.Ltd. industry-leading packaging
              design team has decades of expertise in coming up with extremely
              reliable solutions for the diverse packaging desires of the many
              industries. Whether you need a custom design for life-saving
              medical and pharmaceutical equipment and products or a custom
              carrying case for maximum protection on the road, our experts will
              be up to the task.
            </li>
            <li>
              {" "}
              After carefully listening to your specific needs, our team will
              thoroughly analyze the products you ship to recommend the best
              solution. From product fragility to shock absorption and
              temperature control, Mannix Infotech Solutions Pvt.Ltd. has the
              experience to find an optimized and reliable solution for each
              unique situation.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PackagingDesign;
