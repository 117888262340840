import React from "react";

const Blog4 = () => {
  return (
    <div>
      <div>
        <section
          className="ops_blog_header"
          style={{
            background: "url('../blogbanner.jpg')",
            height: "270px",
            backgroundSize: "cover !important",
            padding: "80px 0",
          }}
        >
          <div className="container">
            <div className="ops_blog_header_content">
              <h1 className="fw-bold text-danger">
                Unlocking the Secrets: Dive into the World of Mannix Infotech
                Solutions Pvt.Ltd.
              </h1>
            </div>
          </div>
        </section>

        <section>
          <div className=" d-flex flex-wrap p-5">
            <div className="col-lg-8 d-flex flex-wrap justify-content-center ">
              <div
                className="mb-3 px-5"
                style={{
                  //   flex: "1 1 300px",
                  //   maxWidth: "300px",
                  margin: "0 auto",
                  width: "100%",
                }}
              >
                <div
                  className="card"
                  style={{
                    height: "",
                    backgroundColor: "#d1d8e0",
                    borderRadius: "20px",
                    width: "100%",
                  }}
                >
                  <img
                    src="../Images/WebDevelopmentBlog.png"
                    alt="WebDevelopmentBlog"
                    style={{ maxWidth: "100%", borderRadius: "20px 20px 0 0" }}
                  />
                  <p className="p-2 text-center" style={{ fontSize: "25px" }}>
                    Top 10 Web Development Companies in Ahmedabad.
                  </p>
                  <p className="p-2 text-center" style={{ fontSize: "16px" }}>
                    In the era of digitalization, having a robust online
                    presence is crucial for any business to thrive. A
                    well-designed and functional website plays a vital role in
                    attracting customers and establishing credibility. For
                    businesses in Ahmedabad, a city known for its
                    entrepreneurial spirit, choosing the right web development
                    company is paramount. In this blog post, we present the top
                    20 web development companies in Ahmedabad, renowned for
                    their expertise, reliability, and ability to deliver
                    exceptional results.
                  </p>
                  <div className="p-3" style={{ fontSize: "16px" }}>
                    <div>
                      <h4 style={{ fontSize: "24px" }}>
                        1) Mannix Infotech Solutions Pvt, Ltd.
                      </h4>
                      <p>
                        Mannix Infotech Solutions Pvt. Ltd., headquartered in
                        Ahmedabad, Gujarat, India, is a company dedicated to
                        crafting innovative software solutions for businesses of
                        all sizes. Their expertise lies in building custom web
                        applications that cater to your specific needs and
                        goals.
                      </p>
                      <p>
                        <b>Here's what sets Mannix Infotech Solutions apart:</b>
                      </p>
                      <p>
                        • Custom Web Application Development: They don't offer
                        one-size-fits-all solutions. Mannix Infotech works
                        closely with you to understand your unique requirements
                        and develop a web application that seamlessly integrates
                        into your existing workflows.
                      </p>
                      <p>
                        • Focus on Client Needs: Their approach is
                        collaborative, ensuring clear communication and a deep
                        understanding of your project vision. This translates
                        into web applications that deliver tangible results and
                        exceed expectations.
                      </p>
                      <p>
                        • Experience Across Borders: While located in India,
                        Mannix Infotech caters to a global clientele. They have
                        experience working with clients in India, Canada, and
                        the US, demonstrating their ability to adapt to diverse
                        business environments.
                      </p>
                      <p>
                        <b>Whether you're looking to:</b>{" "}
                      </p>
                      <p>• Streamline internal operations</p>
                      <p>• Enhance customer engagement</p>
                      <p>• Automate complex processes</p>
                      <p>
                        • Develop a data-driven platform With its experience,
                        Mannix Infotech Solutions can make your vision a
                        reality.
                      </p>
                      <p>
                        <b>Considering a Custom Web Application?</b>
                      </p>
                      <p>
                        If you're a business based in India or anywhere around
                        the world, and you're looking for a reliable partner to
                        develop a custom web application that propels your
                        business forward, look no further than Mannix Infotech
                        Solutions. Speak with their staff right now to go over
                        your project and find out how they can support you in
                        achieving your objectives.
                      </p>
                      <p>
                        {""}
                        E-mail:
                        <a
                          className=""
                          href="mailto:info@mannix.in"
                          style={{ textDecoration: "none" }}
                          title="mailto"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          info@mannix.in
                        </a>{" "}
                      </p>

                      <p>
                        {" "}
                        Contact no:
                        <a
                          href="tel:+91 9664053983"
                          className=""
                          title="phone"
                          target="_blank"
                          rel="noreferrer"
                        >
                          +91 9664053983
                        </a>
                      </p>
                    </div>
                    <div className="mt-3">
                      <h4 style={{ fontSize: "24px" }}>
                        2) eSparkBiz Technologies Pvt.Ltd
                      </h4>
                      <p>
                        eSparkBiz Technologies is a top Ahmedabad web
                        development firm that offers cutting-edge online
                        solutions to customers all over the world. They offer a
                        variety of services, including digital marketing,
                        e-commerce solutions, and custom web and mobile app
                        development.
                      </p>
                    </div>
                    <div className="mt-3">
                      <h4 style={{ fontSize: "24px" }}>
                        3) OpenXcell Technolabs
                      </h4>
                      <p>
                        Having developed websites and apps for clients for more
                        than ten years, OpenXcell is a well-known business. They
                        provide cutting-edge solutions in web development,
                        mobile app development, and emerging technologies with a
                        strong emphasis on quality.
                      </p>
                    </div>
                    <div className="mt-3">
                      <h4 style={{ fontSize: "24px" }}>4) Hidden Brains</h4>
                      <p>
                        An impressive portfolio of completed projects makes
                        Hidden Brains an award-winning web development firm in
                        Ahmedabad. Artificial intelligence, IoT solutions,
                        enterprise software development, and online and mobile
                        app development are their areas of expertise.
                      </p>
                    </div>
                    <div className="mt-3">
                      <h4 style={{ fontSize: "24px" }}>5) WPWeb Infotech</h4>
                      <p>
                        In the web development sector, Dev Technosys is a
                        reputable brand that is well-known for its ability to
                        provide scalable and personalized web solutions. They
                        offer web development, eCommerce development, mobile app
                        development, and more.
                      </p>
                    </div>
                    <div className="mt-3">
                      <h4 style={{ fontSize: "24px" }}>
                        6) Theoddcoders Technologies
                      </h4>
                      <p>
                        Theoddcoders Technologies is a reputable web development
                        business that has provided outstanding online solutions
                        to many clients. Digital marketing, mobile app
                        development, e-commerce development, and web development
                        are among their offerings.
                      </p>
                    </div>
                    <div className="mt-3">
                      <h4 style={{ fontSize: "24px" }}>7) Zealous System</h4>
                      <p>
                        Zealous System is a full-service web development
                        business that serves customers in a range of sectors.7
                        Zealous System{" "}
                      </p>
                    </div>

                    <div className="mt-3">
                      <h4 style={{ fontSize: "24px" }}>
                        8) Techuz InfoWeb Pvt. Ltd
                      </h4>
                      <p>
                        Techuz InfoWeb is a customer-focused web development
                        business with an excellent track record of providing web
                        solutions. Their areas of competence are cloud
                        computing, product engineering, mobile app development,
                        and web development.
                      </p>
                    </div>
                    <div className="mt-3">
                      <h4 style={{ fontSize: "24px" }}>
                        9) Groovy Technoweb Pvt. Ltd.
                      </h4>
                      <p>
                        In addition to providing IT services, Groovy Web acts as
                        a springboard for companies in various industries to
                        succeed in a cutthroat industry.
                      </p>
                    </div>
                    <div className="mt-3">
                      <h4 style={{ fontSize: "24px" }}>
                        10) Bacancy Technology
                      </h4>
                      <p>
                        In the field of web development, Bacancy Technology is
                        well-known for its proficiency with cutting-edge
                        technologies. They provide services like product
                        engineering, block chain solutions, IoT development, and
                        the creation of online and mobile applications.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 ">
              <div>
                <p className="fs-4">Categories</p>
                <ul>
                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> App & Software Development </span>
                    </a>
                  </li>
                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> Graphic Designing</span>
                    </a>
                  </li>
                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> Web Development </span>
                    </a>
                  </li>
                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> Online Marketing </span>
                    </a>
                  </li>

                  <li
                    style={{
                      borderBottom: "solid 1px #eeeeee",
                      marginBottom: "5px",
                      paddingBottom: "5px",
                      lineHeight: "normal",
                      listStyle: "none",
                    }}
                  >
                    <a
                      href="/blog"
                      style={{
                        fontSize: "16px",
                        color: "#555555",
                        padding: "10px 10px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span> Digital Marketing </span>
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <p className="fs-4">Recent posts</p>
                <div>
                  <div
                    className="p-1 recent"
                    style={{
                      backgroundColor: "#e7e8e9",
                      borderBottom: "solid 1px #eeeee",
                    }}
                  >
                    <a
                      href="web-development-blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/WebDevelopmentBlog.png"
                          alt="WebDevelopmentBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>Top 10 Web Development Companies </span>
                      </div>
                    </a>
                  </div>
                  <div
                    className="p-1 recent mt-2"
                    style={{ backgroundColor: "#e7e8e9" }}
                  >
                    <a
                      href="digital-marketing-agency-blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/DigitalMarketingBlog.png"
                          alt="DigitalMarketingBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>Top 10 Digital Marketing Agency </span>
                      </div>
                    </a>
                  </div>
                  <div
                    className="p-1 recent mt-2"
                    style={{
                      backgroundColor: "#e7e8e9",
                      borderBottom: "solid 1px #eeeee",
                    }}
                  >
                    <a
                      href="Graphic-Design-Blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/GraphicDesignBlog.jpg"
                          alt="GraphicDesignBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>Graphic Designing</span>
                      </div>
                    </a>
                  </div>
                  <div
                    className="p-1 recent mt-2"
                    style={{
                      backgroundColor: "#e7e8e9",
                      borderBottom: "solid 1px #eeeee",
                    }}
                  >
                    <a
                      href="online-marketing-services-blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/OnlineMarketingBlog.png"
                          alt="OnlineMarketingBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>The Power Of Online Marketing Services</span>
                      </div>
                    </a>
                  </div>
                  <div
                    className="p-1 recent mt-2"
                    style={{
                      backgroundColor: "#e7e8e9",
                      borderBottom: "solid 1px #eeeee",
                    }}
                  >
                    <a
                      href="mobile-app-development-blog"
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <div className="">
                        <img
                          src="../Images/MobileAppDevelopmentBlog.png"
                          alt="MobileAppDevelopmentBlog"
                          width="100px"
                          className="p-2"
                        />
                        <span>Top Mobile App Development Companies</span>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div>
                <p className="fs-4 mt-4">Social links</p>
                <ul className="d-flex flex-wrap gap-3">
                  <li style={{ listStyle: "none" }}>
                    <a
                      href="https://www.facebook.com/profile.php?id=61558304945053"
                      target="_blank"
                      rel="noreferrer"
                      title="Facebook"
                      style={{
                        padding: " 9px 8px",
                        display: "block",
                        fontSize: "17px",
                        fontWeight: "300",
                        color: "#fff",
                        borderRadius: "10px",
                        background: "#db150e",
                        width: "36px",
                        height: "36px",
                      }}
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                  </li>
                  <li style={{ listStyle: "none" }}>
                    <a
                      href=" https://x.com/mediatech09?t=x-kv93jEG5y0aqezPQ02qw&s=08"
                      target="_blank"
                      rel="noopener"
                      title="Twitter"
                      style={{
                        padding: " 9px 8px",
                        display: "block",
                        fontSize: "17px",
                        fontWeight: "300",
                        color: "#fff",
                        borderRadius: "10px",
                        background: "#db150e",
                        width: "36px",
                        height: "36px",
                      }}
                    >
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </li>

                  <li style={{ listStyle: "none" }}>
                    <a
                      href="https://www.instagram.com/mannixinfotech09/?fbclid=IwAR30ORrOy8gHLBnmw9Nys9CZNYznRUYLyiAXihrlzLH63aVD_y96syb0jJo"
                      target="_blank"
                      rel="noreferrer"
                      title="Instagram"
                      style={{
                        padding: " 9px 8px",
                        display: "block",
                        fontSize: "17px",
                        fontWeight: "300",
                        color: "#fff",
                        borderRadius: "10px",
                        background: "#db150e",
                        width: "36px",
                        height: "36px",
                      }}
                    >
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li style={{ listStyle: "none" }}>
                    <a
                      href="https://www.linkedin.com/company/mannix-infotech-solutions-pvt-ltd/"
                      target="_blank"
                      rel="noreferrer"
                      title="Linkedin"
                      style={{
                        padding: " 9px 8px",
                        display: "block",
                        fontSize: "17px",
                        fontWeight: "300",
                        color: "#fff",
                        borderRadius: "10px",
                        background: "#db150e",
                        width: "36px",
                        height: "36px",
                      }}
                    >
                      <i class="fa-brands fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Blog4;
