import React, { useState } from "react";
import { Helmet } from "react-helmet";

const HRMDevelopment = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>
          HRM Software Development Company | HRMS Solutions | Mannix Infotech
        </title>
        <meta
          name="description"
          content="Simplify HR management with Mannix Infotech's custom HRM software development services in India. Our experts design and develop tailored HRMS solutions to automate workflows, enhance productivity, and improve employee engagement. located in Ahmedabad, Gujarat, India"
        />
        <meta
          name="keywords"
          content="Top HRM Software Development Company  in India |Best HRM Software Development Company in Gujarat |Best HRM Software Development Company in Ahmedabad | List of company HRM Software Development services in Ahmedabad | HRM Software Development services near me | HRM Software Development company near by| HRM Software Development company near me| HRM Software Development | Top 5 HRM Software Development company in Ahmedabad"
        />

        <link
          rel="canonical"
          href="https://www.mannix.in/hrm-development-services"
        />
      </Helmet>
      <section className="banner-content-1 pb-5">
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          className="position-absolute top-banner-bg"
          viewBox="0 0 1920 761"
        >
          <g>
            <defs>
              <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
            </defs>
            <clipPath id="SVGID_2_">
              <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
            </clipPath>
            <g className="banner-bg-asw">
              <path
                className="st1"
                d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                            c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
              ></path>
              <path
                className="st2"
                d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                            c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
              ></path>
              <path
                className="st1"
                d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
              ></path>
              <path
                className="st2"
                d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
              ></path>
              <circle className="st1" cx="1536.8" cy="661.3" r="56.2"></circle>
              <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
            </g>
          </g>
        </svg>
        <div className="py-5 container">
          <div className="row align-items-center justify-content-around mt-4">
            <div className="col-lg-6">
              <div className="">
                <h1 className="head-title head-title-without-line m-0 py-3">
                  HRM software Development
                </h1>
                <p className="m-0 py-3">
                  Each small or large company has the most important asset in
                  its organization, which is the management of human resources.
                  HR or human resource management opstems are very common in any
                  organization. The process of recruiting, managing, and
                  training within the organization is significant. The human
                  resource management team comprises small, manageable units
                  that carry out any task. This department performs several
                  tasks, such as recruiting new staff, keeping existing
                  employees, training and controlling the existing workforce,
                  supervising. Below are the requirements, features, benefits,
                  and costs of developing HRMS Solutions.
                </p>
              </div>
            </div>
            <div className="col-lg-5 prod-section2">
              <div className="">
                <form
                  className="register-form"
                  id="register-form"
                  method="post"
                  action=""
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="form_type"
                    id="form_type"
                    value="Android App Development"
                  />
                  <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                    <div className="d-flex">
                      <div className="w-100 mr-2">
                        <label className="w-100">
                          <span>Name</span>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name *"
                            className="form-control"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div className="w-100">
                        <label className="w-100">
                          <span>Email</span>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email id *"
                            className="form-control"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className="d-flex">
                      <label className="w-100">
                        <span>Contact</span>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Contact No *"
                          className="form-control"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Country</span>
                        <input
                          type="text"
                          name="country"
                          id="country"
                          placeholder="Enter your Country"
                          className="form-control"
                          value={formData.country}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Message</span>
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Enter your message *"
                          className="form-control"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </label>
                    </div>

                    <div className="">
                      <button
                        type="submit"
                        name="submit"
                        className="submit btn w-100 g-recaptcha-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="what-we-do pt-5">
        <div className="container">
          <div className="theme-title text-left">
            <h2 className="static_css">HRM Software Development</h2>
            <p className="text-justify">
              Each small or large company has the most important asset in its
              organization, which is the management of human resources. HR or
              human resource management opstems are very common in any
              organization. The process of recruiting, managing, and training
              within the organization is significant. The human resource
              management team comprises small, manageable units that carry out
              any task. This department performs several tasks, such as
              recruiting new staff, keeping existing employees, training and
              controlling the existing workforce, supervising. Below are the
              requirements, features, benefits, and costs of developing HR
              software.
            </p>
          </div>
        </div>
      </section>
      <div className="company-offer3">
        <div className="container">
          <div className="theme-title">
            {" "}
            <strong>
              How Mannix Infotech Solutions Pvt.Ltd. benefits your business -
            </strong>
            <ul className="list-style-blt">
              <li>
                Time tracking{" "}
                <p className="text-justify">
                  HRMS is a single place to observe the attendance and time of
                  every worker within the group, saving you time.
                </p>
              </li>
              <li>
                Evaluation of performances{" "}
                <p className="text-justify">
                  HRMS will monitor the success of each employee and help HR
                  track the assessment of each individual.
                </p>
              </li>
              <li>
                Employee management{" "}
                <p className="text-justify">
                  Managing employees in HRMS enables employees to perform their
                  best day-to-day work, helping a business to grow.
                </p>
              </li>
              <li>
                More control{" "}
                <p className="text-justify">
                  The recurring and monitoring process can be more efficient
                  when reports and data are stored in one place.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <section className="case-study mb-0">
        <div className="container">
          <div className="theme-title text-center">
            <h2>Why choose a custom HRM software solution </h2>
            <p style={{ textAlign: "center!important", paddingTop: "10px" }}>
              Custom HRM software from single-pixel software
            </p>
          </div>
          <div className="">
            <div className="row">
              <div className="col-sm-3 col-xs-12">
                <div className="single-case" style={{ paddingBottom: "56px" }}>
                  <h5 className="fw-bold">Target Your Business</h5>
                  <p className="text-justify">
                    Designed and developed by targeting only your business and
                    its needs
                  </p>
                </div>
              </div>
              <div className="col-sm-3 col-xs-12">
                <div className="single-case" style={{ paddingBottom: "56px" }}>
                  <h5 className="fw-bold">Software Solution</h5>
                  <p className="text-justify">
                    Get full control over your software solution
                  </p>
                  <br />
                </div>
              </div>
              <div className="col-sm-3 col-xs-12">
                <div
                  className="single-case fix"
                  style={{ paddingBottom: "33px" }}
                >
                  <h5 className="fw-bold">Ready-to-use software solution</h5>
                  <p className="text-justify">
                    Cheaper than a plug-and-play software solution. Only the
                    cost of development.
                  </p>
                </div>
              </div>
              <div className="col-sm-3 col-xs-12">
                <div className="single-case fix">
                  <h5 className="fw-bold">Custom HRM software</h5>
                  <p className="text-justify">
                    Customized HRM software from Space-O Technologies adapts to
                    your needs and can be changed and updated accordingly.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="company-offer3">
        <div className="container">
          <div className="theme-title">
            {" "}
            <strong>
              HRMS development process we follow at Mannix Infotech Solutions
              Pvt.Ltd.-
            </strong>
            <ul className="list-style-blt">
              <li>Gather Requirements</li>
              <li>Creating a frame</li>
              <li>Identify the technology</li>
              <li>Deploy the product</li>
              <li>Testing the product</li>
              <li>Design and development</li>
            </ul>
          </div>
        </div>
      </div>
      ;
    </div>
  );
};

export default HRMDevelopment;
