import React, { useState } from "react";
import { Helmet } from "react-helmet";

const ITConsultancy = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>
          Streamline Your Business with Expert IT Consulting Services
        </title>
        <meta
          name="description"
          content="mannix infotech solution gives solutions and expert guidance to streamline your business operations. From  digital transformation to comprehensive business IT services, we optimize your technology infrastructure for efficiency and growth."
        />
        <meta
          name="keywords"
          content="Digital transformation|Technology solutions|Business IT services|IT consulting"
        />

        <link
          rel="canonical"
          href="https://www.mannix.in/it-consultancy-services"
        />
      </Helmet>
      <section className="banner-content-1">
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          className="position-absolute top-banner-bg"
          viewBox="0 0 1920 761"
        >
          <g>
            <defs>
              <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
            </defs>
            <clipPath id="SVGID_2_">
              <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
            </clipPath>
            <g className="banner-bg-asw">
              <path
                className="st1"
                d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                            c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
              ></path>
              <path
                className="st2"
                d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                            c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
              ></path>
              <path
                className="st1"
                d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
              ></path>
              <path
                className="st2"
                d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
              ></path>
              <circle className="st1" cx="1536.8" cy="661.3" r="56.2"></circle>
              <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
            </g>
          </g>
        </svg>
        <div className="py-5 container">
          <div className="row align-items-center justify-content-around mt-4">
            <div className="col-lg-6">
              <div className="">
                <h1 className="head-title head-title-without-line m-0 py-3">
                  IT Consultant Company
                </h1>
                <p className="m-0 py-3">
                  Use the expertise and in-depth technical knowledge of Mannix
                  Infotech Solutions Pvt.Ltd. best minds to create a
                  comprehensive IT strategy for a digital and technological
                  transformation of your organization that aligns with your
                  business goals. Our strategic IT Services and IT Consulting
                  assist you modify and alter operations, optimize the package
                  portfolio and implement the newest technologies.
                </p>
              </div>
            </div>
            <div className="col-lg-5 prod-section2">
              <div className="">
                <form
                  className="register-form"
                  id="register-form"
                  method="post"
                  action=""
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="form_type"
                    id="form_type"
                    value="Android App Development"
                  />
                  <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                    <div className="d-flex">
                      <div className="w-100 mr-2">
                        <label className="w-100">
                          <span>Name</span>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name *"
                            className="form-control"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div className="w-100">
                        <label className="w-100">
                          <span>Email</span>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email id *"
                            className="form-control"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className="d-flex">
                      <label className="w-100">
                        <span>Contact</span>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Contact No *"
                          className="form-control"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Country</span>
                        <input
                          type="text"
                          name="country"
                          id="country"
                          placeholder="Enter your Country"
                          className="form-control"
                          value={formData.country}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Message</span>
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Enter your message *"
                          className="form-control"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </label>
                    </div>

                    <div className="">
                      <button
                        type="submit"
                        name="submit"
                        className="submit btn w-100 g-recaptcha-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="what-we-do pt-5">
        <div className="container">
          <div className="theme-title text-left">
            <p className="text-justify">
              Use the expertise and in-depth technical knowledge of Mannix
              Infotech Solutions Pvt.Ltd. best minds to create a comprehensive
              IT strategy for a digital and technological transformation of your
              organization that aligns with your business goals. Our strategic
              IT consulting will assist you modify and alter operations,
              optimize the package portfolio and implement the newest
              technologies.{" "}
            </p>
          </div>
        </div>
      </section>

      <div className="hire-dedicated services">
        <section>
          <div className="hire-dedicated-2 container py-4">
            <div className="pb-4 text-center">
              <h2 className="head-title">Features of Web Design Services</h2>
            </div>
            <div className="row">
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-bars"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">Content management (CMS)</h2>
                      <p className="m-0 py-2">
                        At Mannix Infotech Solutions Pvt.Ltd., we believe in
                        giving our clients as much control over their website as
                        they need or want. All of our websites have a content
                        management opstem (CMS) that allows you or your staff to
                        update or publish new content easily and at no
                        additional cost. Because we tend to are an entire net
                        design and development company, we provide a good sort
                        of CMS solutions.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-bars"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fab fa-wordpress"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">
                        Full Custom Wordpress Drupal Joomlaet more{" "}
                      </h2>
                      <p className="m-0 py-2">
                        During your further needs assessment, we'll verify that
                        options and pages you would like to manage and recommend
                        the proper CMS for your web site desires.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fab fa-wordpress"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-laptop"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">
                        Popular website functionality{" "}
                      </h2>
                      <p className="m-0 py-2">
                        These include most of the standard feature sets and are
                        increasingly expected on modern, resource-rich websites.
                        These features, powered by a CMS for publishing and
                        editing, can include:Blogs,Event calendars,News or
                        newsletters,Portfolio or case studies,Staff
                        directories,Admission forms,Job boards,Product
                        catalogs,Landing pages,etc.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-laptop"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-mobile-alt"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">Mobile websites</h2>
                      <p className="m-0 py-2">
                        Your web visitors view your website from a myriad of
                        devices, from smartwatches to home theater flat screens.
                        All of the websites we produce, from simple brochure
                        sites to feature wealthy eCommerce sites, are totally
                        useful and designed to accommodate any screen size or
                        orientation. They are designed intelligently, so that
                        the layout of the sites is rearranged to provide the
                        ideal layout for each device.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-mobile-alt"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-shopping-cart"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">E-commerce</h2>
                      <p className="m-0 py-2">
                        Professional e-commerce websites are our bread and
                        butter. We create personalized online shopping solutions
                        that are as feature rich and sophisticated as your
                        customers demand. We understand the psychology of online
                        shopping and are experts in conversions. We have
                        developed e-commerce websites for virtually every market
                        segment, from clothing to guns, furniture, food and
                        more. Our custom CMS gives you full control over your
                        product line, fulfillment, inventory and more.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-shopping-cart"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-user-friends"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2"> Internet audience analyzes </h2>
                      <p className="m-0 py-2">
                        Let our web analytics give you an edge in the market.
                        Our feature-rich web analytics will provide you with
                        real-time important information such as: Number of
                        visitors and how they reached your website Engagement,
                        time spent on your website and what pages they visited
                        Search terms they used to find you and on which search
                        engine
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-user-friends"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="company-offer3">
        <div className="container">
          <div className="theme-title">
            {" "}
            <strong>Why Choose Mannix Infotech Solutions Pvt.Ltd.? </strong>
            <ul className="list-style-blt">
              <li>The lowest prices for a professional design.</li>
              <li>Experienced designers to work with directly</li>
              <li>Premium service available for agencies</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ITConsultancy;
