import React, { useState } from "react";
import { Helmet } from "react-helmet";

const DigitalMarketing = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>Drive Growth with a Leading Digital Marketing Company</title>
        <meta
          name="description"
          content="Discover effective digital marketing solutions with our expert team. We offer SEO, PPC, social media management, and content marketing services to boost your online presence and attract more customers"
        />
        <meta
          name="keywords"
          content="Digital marketing services|Social media management|Content marketing solutions|Online marketing agency"
        />

        <link
          rel="canonical"
          href="https://www.mannix.in/digital-marketing-services"
        />
      </Helmet>
      <section className="banner-content-1 pb-5">
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          className="position-absolute top-banner-bg"
          viewBox="0 0 1920 761"
        >
          <g>
            <defs>
              <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
            </defs>
            <clipPath id="SVGID_2_">
              <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
            </clipPath>
            <g className="banner-bg-asw">
              <path
                className="st1"
                d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                            c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
              ></path>
              <path
                className="st2"
                d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                            c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
              ></path>
              <path
                className="st1"
                d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
              ></path>
              <path
                className="st2"
                d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
              ></path>
              <circle className="st1" cx="1536.8" cy="661.3" r="56.2"></circle>
              <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
            </g>
          </g>
        </svg>
        <div className="py-5 container">
          <div className="row align-items-center justify-content-around mt-4">
            <div className="col-lg-6">
              <div className="">
                <h1 className="head-title head-title-without-line m-0 py-3">
                  Digital Marketing Company
                </h1>
                <p className="m-0 py-3">
                  At Mannix Infotech Solutions Pvt.Ltd. , we provide the Best
                  Digital Marketing Agency for their category to our customers.
                  Over the years, we have provided solutions such as the design
                  and development of Web sites, digital marketing, design and
                  development of mobile applications, and 2D and 3D animations
                  following the latest trends. We promise you fast delivery of
                  your project that can create additional value for your
                  business.
                </p>
              </div>
            </div>
            <div className="col-lg-5 prod-section2">
              <div className="">
                <form
                  className="register-form"
                  id="register-form"
                  method="post"
                  action=""
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="form_type"
                    id="form_type"
                    value="Android App Development"
                  />
                  <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                    <div className="d-flex">
                      <div className="w-100 mr-2">
                        <label className="w-100">
                          <span>Name</span>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name *"
                            className="form-control"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div className="w-100">
                        <label className="w-100">
                          <span>Email</span>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email id *"
                            className="form-control"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className="d-flex">
                      <label className="w-100">
                        <span>Contact</span>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Contact No *"
                          className="form-control"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Country</span>
                        <input
                          type="text"
                          name="country"
                          id="country"
                          placeholder="Enter your Country"
                          className="form-control"
                          value={formData.country}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Message</span>
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Enter your message *"
                          className="form-control"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </label>
                    </div>

                    <div className="">
                      <button
                        type="submit"
                        name="submit"
                        className="submit btn w-100 g-recaptcha-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="company-offer2 pt-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-sm-5 col-md-5">
              <div className="team_img">
                {" "}
                <img
                  src="./DigitalMarketing.png"
                  alt="DigitalMarketing"
                  className="img-fluid"
                />{" "}
              </div>
            </div>
            <div className="col-md-7 col-sm-7">
              <div className="theme-title tema-deign-deve  padd_lr padding-top-new">
                <h3>Why choose Mannix Infotech Solutions Pvt.Ltd.?</h3>
                <p className="text-justify">
                  You want trust options and we provide you with the reasons. If
                  you love working with pleasure, we are parents. We build your
                  board with our confidence, then we talk business because we
                  are a long horse. Create your success and make this world a
                  better place to live.
                </p>
                <ul className="list-style-blt" type="disc">
                  <li>
                    Let’s be partners{" "}
                    <p className="text-justify">
                      We do not treat you as our customers but as partners. Help
                      with more care and understanding.
                    </p>
                  </li>
                  <li>
                    An approach to profit{" "}
                    <p className="text-justify">
                      We want you to develop not only as a business owner but
                      also as an influencer. Make it.
                    </p>
                  </li>
                  <li>
                    Creators of value{" "}
                    <p className="text-justify">
                      We work with our clients to create value and establish
                      good work standards.
                    </p>
                  </li>
                  <li>
                    Relax! Let’s manage{" "}
                    <p className="text-justify">
                      We take care of everything; you wait and benefit from your
                      daily progress.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-6">
            <div className="theme-title tema-deign-deve  padd_lr padding-top-new">
              <h3>Who we are?</h3>
              <p className="text-justify">
                At Mannix Infotech Solutions Pvt.Ltd., we provide the best
                solutions for their category to our customers. Over the years,
                we have provided solutions such as the design and development of
                Web sites, Digital Marketing Consultant, design and development
                of mobile applications, and 2D and 3D animations following the
                latest trends. We promise you fast delivery of your project that
                can create additional value for your business.
              </p>
              <ul className="list-style-blt" type="disc">
                <li>
                  Team of experts{" "}
                  <p className="text-justify">
                    Our team is competent and experienced, and we can shape your
                    business for better growth and better revenue.
                  </p>
                </li>
                <li>
                  Research-based results{" "}
                  <p className="text-justify">
                    The results we provide you follow years of market studies
                    and popular trends.
                  </p>
                </li>
                <li>
                  Delivery on time{" "}
                  <p className="text-justify">
                    Time, it’s money and we know it. We respect your time and
                    that’s why we are committed to delivering your project on
                    time or more.
                  </p>
                </li>
                <li>
                  100% customer satisfaction{" "}
                  <p className="text-justify">
                    That which counts the most for us is your growth and your
                    satisfaction, and it is why we strive to give you the best.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="digi-img">
              <img
                src="./DigitalMarketingService.png"
                alt="DigitalMarketingService"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
      <section className="case-study">
        <div className="container">
          <div className="theme-title text-center">
            <h2>Digital Marketing Services </h2>
          </div>
          <div className="content">
            <div className="row">
              <div className="col-sm-4 col-xs-12">
                <div className="single-case" style={{ paddingBottom: " 56px" }}>
                  <h5>Search engine optimization</h5>
                  <p className="text-justify">
                    Do you want to position yourself on the 1st Google page? We
                    know how to do this. Show your internet presence with proven
                    SEO tactics.
                  </p>
                </div>
              </div>
              <div className="col-sm-4 col-xs-12">
                <div className="single-case" style={{ paddingBottom: " 56px" }}>
                  <h5>Social media management</h5>
                  <p className="text-justify">
                    Create your social presence and build yourself a brand.
                    produce a competitive advantage and mark your land.
                  </p>
                </div>
              </div>
              <div className="col-sm-4 col-xs-12">
                <div
                  className="single-case fix"
                  style={{ paddingBottom: " 56px" }}
                >
                  <h5>Email advertising</h5>
                  <p className="text-justify">
                    Want to stay in the inbox or make a place for yourself in
                    their minds? The choice is yours.
                  </p>
                </div>
              </div>
              <div className="col-sm-4 col-xs-12">
                <div
                  className="single-case fix"
                  style={{ paddingBottom: " 56px" }}
                >
                  <h5>Link building</h5>
                  <p className="text-justify">
                    Everything is in the network. Let us help you establish your
                    links with authorized sources.
                  </p>
                </div>
              </div>
              <div className="col-sm-4 col-xs-12">
                <div
                  className="single-case fix"
                  style={{ paddingBottom: "56px" }}
                >
                  <h5>Content Marketing</h5>
                  <p className="text-justify">
                    The content is not a king but the kingdom itself. Let’s take
                    care of it. Great content and great presentation surely do
                    the magic.
                  </p>
                </div>
              </div>
              <div className="col-sm-4 col-xs-12">
                <div
                  className="single-case fix"
                  style={{ paddingBottom: " 56px" }}
                >
                  <h5>Pay with a click</h5>
                  <p className="text-justify">
                    Get the most out of your advertising and reach as many
                    consumers as possible.
                  </p>
                </div>
              </div>
              <div className="col-sm-4 col-xs-12">
                <div
                  className="single-case fix"
                  style={{ paddingBottom: " 56px" }}
                >
                  <h5>E-commerce marketing</h5>
                  <p className="text-justify">
                    Convert your visitors into customers and sell your products
                    to the right audience.
                  </p>
                </div>
              </div>
              <div className="col-sm-4 col-xs-12">
                <div
                  className="single-case fix"
                  style={{ paddingBottom: " 56px" }}
                >
                  <h5>Mobile application development</h5>
                  <p className="text-justify">
                    To be on their mind, you have to be on their screens. And we
                    reign there!
                  </p>
                </div>
              </div>
              <div className="col-sm-4 col-xs-12">
                <div
                  className="single-case fix"
                  style={{ paddingBottom: "56px" }}
                >
                  <h5>Website design and development</h5>
                  <p className="text-justify">
                    GO DIGITAL! It’s all about appearance and softness. Get a
                    perfect website for your business.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default DigitalMarketing;
