import React, { useState } from "react";
import { Helmet } from "react-helmet";

const UIDesign = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>Effective UI/UX Design Company</title>
        <meta
          name="description"
          content="Create beautiful user interfaces with our UI design tool. Collaborate on creations, build prototypes & create seamless workflows all-in-one at mannix infotech solution.
"
        />
        <meta
          name="keywords"
          content="UI/UX Design Services|Interaction Design|Website Design|Visual Design
"
        />
        <link rel="canonical" href="https://www.mannix.in/ui-design-services" />
      </Helmet>
      <section className="banner-content-1 pb-5">
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          className="position-absolute top-banner-bg"
          viewBox="0 0 1920 761"
        >
          <g>
            <defs>
              <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
            </defs>
            <clipPath id="SVGID_2_">
              <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
            </clipPath>
            <g className="banner-bg-asw">
              <path
                className="st1"
                d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                            c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
              ></path>
              <path
                className="st2"
                d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                            c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
              ></path>
              <path
                className="st1"
                d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
              ></path>
              <path
                className="st2"
                d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
              ></path>
              <circle className="st1" cx="1536.8" cy="661.3" r="56.2"></circle>
              <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
            </g>
          </g>
        </svg>
        <div className="py-5 container">
          <div className="row align-items-center justify-content-around mt-4">
            <div className="col-lg-6">
              <div className="">
                <h1 className="head-title head-title-without-line m-0 py-3">
                  UI DESIGN
                </h1>
                <p className="m-0 py-3">
                  Good design solves problems. Great design delivers
                  experiences. Our Design services will give your business the
                  appeal and functionality of the impressive design.Mannix
                  Infotech is a company that offers UI/UX Design with
                  creatively-minded UI and UX designers who can turn vague
                  wireframes into stunning, design-rich interfaces. Mobile apps,
                  cloud apps, websites: our services can meet all your design
                  requirements.
                </p>
              </div>
            </div>
            <div className="col-lg-5 prod-section2">
              <div className="">
                <form
                  className="register-form"
                  id="register-form"
                  method="post"
                  action=""
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="form_type"
                    id="form_type"
                    value="Android App Development"
                  />
                  <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                    <div className="d-flex">
                      <div className="w-100 mr-2">
                        <label className="w-100">
                          <span>Name</span>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name *"
                            className="form-control"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div className="w-100">
                        <label className="w-100">
                          <span>Email</span>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email id *"
                            className="form-control"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className="d-flex">
                      <label className="w-100">
                        <span>Contact</span>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Contact No *"
                          className="form-control"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Country</span>
                        <input
                          type="text"
                          name="country"
                          id="country"
                          placeholder="Enter your Country"
                          className="form-control"
                          value={formData.country}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Message</span>
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Enter your message *"
                          className="form-control"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </label>
                    </div>

                    <div className="">
                      <button
                        type="submit"
                        name="submit"
                        className="submit btn w-100 g-recaptcha-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ar-top-banner mt-5 mb-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7">
              <div className="">
                <p className="fs-1">UI DESIGN</p>
                <p className="text-justify ui-style">
                  <strong>
                    Drive business innovation through Mannix Infotech Solutions
                    Pvt.Ltd. software Designs.
                  </strong>
                </p>

                <p className="text-justify">
                  Good design solves problems. Great design delivers
                  experiences. Our UI UX design services will give your business
                  the appeal and functionality of the impressive design. Mannix
                  Infotech Solutions Pvt.Ltd. is a company that offers UI/UX
                  Design with creatively-minded UI and UX designers who can turn
                  vague wireframes into stunning, design-rich interfaces. Mobile
                  apps, cloud apps, websites: our services can meet all your
                  design requirements.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              {" "}
              <img
                src="UiDesign.png"
                className="img-fluid"
                alt="UiDesign"
              />{" "}
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="hire-dedicated-2 container py-4">
          <div className="pb-4 text-center">
            <h2 className="head-title">
              UI UX design agency solves your design challenges
            </h2>
          </div>
          <div className="row justify-content-center">
            <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fas fa-user-circle"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">UX Strategy and Design</h2>
                    <p className="m-0 py-2">
                      Leverage our UI / UX experience to make a unique strategy
                      that may get the most out of your app information, design
                      aesthetics, and stakeholder requirements.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fas fa-user-circle"></i>
                </div>
              </div>
            </div>
            <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fas fa-cube"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Interaction design</h2>
                    <p className="m-0 py-2">
                      We go beyond the average design to make interaction style
                      elements that may keep users hooked on your apps.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fas fa-cube"></i>
                </div>
              </div>
            </div>
            <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fas fa-chart-area"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Experiment design</h2>
                    <p className="m-0 py-2">
                      Our app designs are designed to deliver impressive
                      experiences that will increase user engagement and time
                      spent on the app.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fas fa-chart-area"></i>
                </div>
              </div>
            </div>
            <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fas fa-palette"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Responsive website</h2>
                    <p className="m-0 py-2">
                      80% of Internet traffic comes from mobile devices. Make
                      your web pages responsive to our responsive web design
                      services.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fas fa-palette"></i>
                </div>
              </div>
            </div>
            <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fas fa-ad"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Mobile application design</h2>
                    <p className="m-0 py-2">
                      Mannix Infotech Solutions Pvt.Ltd. software can help you
                      design great mobile application interfaces, which will
                      allow users to get things done - without having to switch
                      between screens.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fas fa-ad"></i>
                </div>
              </div>
            </div>
            <div className=" col-lg-3 col-md-6 pt-4 hire-fields">
              <div className="parent">
                <div className="normal">
                  <div>
                    <i className="fas fa-icons"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">User interface usability test</h2>
                    <p className="m-0 py-2">
                      Will your app get continuous traffic or get uninstalled
                      quickly? Our UI usability testing will help you measure
                      the effectiveness of your UI and identify areas where it
                      can be improved.
                    </p>
                  </div>
                </div>
                <div className="bg-icon">
                  <i className="fas fa-icons"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cr_features_sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center">
              <div className="section_title">
                <h2>Services</h2>{" "}
              </div>
            </div>
          </div>
          <div className="cr_group_wrap">
            <ul>
              <li>
                <div className="cr_features_item">
                  <div className="icon_img01">
                    {" "}
                    <img src="../Images/crowd1.png" alt="/crowd1" />{" "}
                  </div>
                  <div className="item-heading">
                    <p className="fw-bold fs-6">
                      {" "}
                      Ai-integrated cross-platform applications
                    </p>{" "}
                  </div>
                </div>
                <div className="text_p">
                  <p className="text-justify">
                    We integrate artificial intelligence into our cross-platform
                    applications to improve functionality and user experience.
                    Our developers create perfect apps for our clients.
                  </p>
                </div>
              </li>
              <li>
                <div className="cr_features_item">
                  <div className="icon_img01">
                    {" "}
                    <img src="../Images/crowd2.png" alt="crowd2" />{" "}
                  </div>
                  <div className="item-heading">
                    <p className="fw-bold fs-6">
                      Multiplatform portable applications
                    </p>{" "}
                  </div>
                </div>
                <div className="text_p">
                  <p className="text-justify">
                    Mannix Infotech Solutions Pvt.Ltd. provides hybrid wearable
                    application development services by integrating sensors and
                    cameras to enhance user experience.
                  </p>
                </div>
              </li>
              <li>
                <div className="cr_features_item">
                  <div className="icon_img01">
                    {" "}
                    <img src="../Images/crowd3.png" alt="crowd3" />{" "}
                  </div>
                  <div className="item-heading">
                    <p className="fw-bold fs-6">
                      Multiplatform Web Applications
                    </p>{" "}
                  </div>
                </div>
                <div className="text_p">
                  <p className="text-justify">
                    Don't want an app that overloads users by requiring a
                    download? We got you! We'll help you build world-className
                    web applications.
                  </p>
                </div>
              </li>
              <li>
                <div className="cr_features_item">
                  <div className="icon_img01">
                    {" "}
                    <img src="../Images/crowd4.png" alt="crowd4" />{" "}
                  </div>
                  <div className="item-heading">
                    <p className="fw-bold fs-6">
                      Cross-platform UI / UX design services
                    </p>{" "}
                  </div>
                </div>
                <div className="text_p">
                  <p className="text-justify">
                    Mannix Infotech Solutions Pvt.Ltd. cross-platform apps are
                    known for their excellent user interface (UI / UX) and fast
                    load times. Our designers know the best for your
                    applications.
                  </p>
                </div>
              </li>
              <li>
                <div className="cr_features_item">
                  <div className="icon_img01">
                    {" "}
                    <img
                      src="../Images/crowd5.png"
                      alt="crowd5"
                      className=""
                    />{" "}
                  </div>
                  <div className="item-heading">
                    <p className="fw-bold fs-6">
                      Support and maintenance services
                    </p>{" "}
                  </div>
                </div>
                <div className="text_p">
                  <p className="text-justify">
                    We will never let you dry. We are committed to supporting
                    you even after the delivery of the project.{" "}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="why_choose_online why_choose_blockchain ">
        <div className="container">
          <div
            className="why_choose_title why_choose_us"
            style={{ paddingBottom: "55px" }}
          >
            How our UI UX design services can help you:-
          </div>
          <div className="row justify-content-center">
            <div className="col-sm-4">
              <div className="choose_food choose_food_2">
                <h3>Solve problems</h3>
                <p className="text-justify">
                  The major goal of our design services is to solve user
                  problems. We produce user interfaces with the user’s
                  perspective and process in mind to make problem-solving
                  designs.{" "}
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="choose_food choose_food_2">
                <h3>Provide convenience</h3>
                <p className="text-justify">
                  We strongly believe that an excellent design should be simple,
                  minimal, and require little effort by the user to do
                  something. This should be convenient for the user by
                  eliminating unnecessary steps to perform an action.{" "}
                </p>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="choose_food choose_food_2">
                <h3>Increase engagement</h3>
                <p className="text-justify">
                  Whether it’s a productivity app or a mobile game, or even a
                  business app, the app experience needs to be enhanced by the
                  power of design. We’ll take care of improving user engagement
                  for your apps.
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="choose_food choose_food_2">
                <h3>Encourage innovation</h3>
                <p className="text-justify">
                  Our designers approach a problem statement with a “what if”
                  approach. It helps us find radical solutions to seemingly
                  impossible challenges. We will help you drive innovation in
                  digital solutions by simplifying the challenges.
                </p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="choose_food choose_food_2">
                <h3>Achieve competitiveness</h3>
                <p className="text-justify">
                  What sets you apart from your competitor would be an excellent
                  design. We will help you create an impressive design for any
                  digital surface that will outsmart the competition and grab
                  the attention of your users. Our design will give you this
                  competitive advantage.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default UIDesign;
