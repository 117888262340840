import React, { useState } from "react";
import { Helmet } from "react-helmet";

const IOSAppDevelopment = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>high quality ios App development services </title>
        <meta
          name="description"
          content="We are a top iOS app development company specializing in creating high-quality mobile apps. Let us help you bring your app idea to life.
"
        />
        <meta
          name="keywords"
          content="best ios app development company|iphone app solutions|custom ios app development
"
        />
        <link
          rel="canonical"
          href="https://www.mannix.in/ios-app-development-services"
        />
      </Helmet>
      <div className="hire-dedicated services mt-0">
        <section className="banner-content-1">
          <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            className="position-absolute top-banner-bg"
            viewBox="0 0 1920 761"
          >
            <g>
              <defs>
                <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
              </defs>
              <clipPath id="SVGID_2_">
                <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
              </clipPath>
              <g className="banner-bg-asw">
                <path
                  className="st1"
                  d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                        c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
                ></path>
                <path
                  className="st2"
                  d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                        c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
                ></path>
                <path
                  className="st1"
                  d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
                ></path>
                <path
                  className="st2"
                  d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
                ></path>
                <circle
                  className="st1"
                  cx="1536.8"
                  cy="661.3"
                  r="56.2"
                ></circle>
                <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
              </g>
            </g>
          </svg>
          <div className="py-5 container">
            <div className="row align-items-center justify-content-around mt-4">
              <div className="col-lg-6">
                <div className="">
                  <h1 className="head-title head-title-without-line m-0 py-3">
                    IOS APP DEVELOPMENT SERVICES
                  </h1>
                  <p className="m-0 py-3">
                    Mobile applications that seamlessly function and should
                    proffer amazing functionalities are pre-eminent. Android App
                    Development Company is a futuristic friendly way of weaving
                    technology for mobile app development that boosts the
                    omnipresence of the audience. Mannix Infotech Solutions
                    Pvt.Ltd. experience and skilled developers can create
                    robust, engaging, high-performance, android app solutions
                    that come with all advanced features and technologies. We
                    have in-house best talents from the industry who proffer
                    end-to-end customization and integration services to bring
                    ideas into reality.{" "}
                  </p>
                  <p className="m-0 py-3">
                    With years of experience in diverse industries, we can craft
                    and implement engaging, scalable, and feature-rich mobile
                    apps that can easily run across multiple platforms.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 prod-section2">
                <div className="">
                  <form
                    className="register-form"
                    id="register-form"
                    method="post"
                    action=""
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="hidden"
                      name="form_type"
                      id="form_type"
                      value="Android App Development"
                    />
                    <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                      <div className="d-flex">
                        <div className="w-100 mr-2">
                          <label className="w-100">
                            <span>Name</span>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Name *"
                              className="form-control"
                              value={formData.name}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                        <div className="w-100">
                          <label className="w-100">
                            <span>Email</span>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Email id *"
                              className="form-control"
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <label className="w-100">
                          <span>Contact</span>
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            placeholder="Contact No *"
                            className="form-control"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Country</span>
                          <input
                            type="text"
                            name="country"
                            id="country"
                            placeholder="Enter your Country"
                            className="form-control"
                            value={formData.country}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Message</span>
                          <textarea
                            name="message"
                            id="message"
                            placeholder="Enter your message *"
                            className="form-control"
                            value={formData.message}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </label>
                      </div>

                      <div className="">
                        <button
                          type="submit"
                          name="submit"
                          className="submit btn w-100 g-recaptcha-btn"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="android-app- mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="android-app-img">
                <video
                  className="main-video resource-retina img-fluid"
                  src="../Images/WhatsApp Video 2.mp4"
                  type="video/mp4"
                  loop="true"
                  autoplay="true"
                  muted="true"
                  playsinline="true"
                ></video>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="android-app-text pt-5">
                <h2>IOS APP DEVELOPMENT SERVICES</h2>
                <br />
                <p className="text-justify">
                  Mobile applications that seamlessly function and should
                  proffer amazing functionalities are pre-eminent. Android App
                  Development is a futuristic friendly way of weaving technology
                  for&nbsp; mobile app development that boosts the omnipresence
                  of the audience. Mannix Infotech Solutions Pvt.Ltd. experience
                  and skilled developers can create robust, engaging,
                  high-performance, android app solutions that come with all
                  advanced features and technologies. We have in-house best
                  talents from the industry who proffer end-to-end customization
                  and integration services to bring ideas into reality.&nbsp;
                  <br />
                  With years of experience in diverse industries, we can craft
                  and implement engaging, scalable, and feature-rich mobile apps
                  that can easily run across multiple platforms.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="services-android-app">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="services-android-app-title pt-5">
                <div className="section-title pb-5">
                  <h2 className="android_title">
                    IOS APP DEVELOPMENT SERVICES-
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-sm-12">
              <ul className="services-android_list">
                <li>
                  <div className="services-app-item">
                    <div className="socal-icons">
                      <img src="../Images/applogo4.png" alt="applogo4" />
                    </div>
                    <div className="app-item-text">
                      <h3>STRATEGY AND ADVICE</h3>
                      <p className="text-justify">
                        Let’s start by transforming your business idea into a
                        workable and practical concept of a product that will
                        win the market and the audience.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="services-app-item">
                    <div className="socal-icons">
                      <img src="../Images/applogo1.png" alt="applogo1" />
                    </div>
                    <div className="app-item-text">
                      <h3>DESIGN AND UX</h3>
                      <p className="text-justify">
                        We provide iOS app design services in line with the
                        latest market trends and create seamless experiences and
                        user expectations.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="services-app-item">
                    <div className="socal-icons">
                      <img src="../Images/applogo2.png" alt="applogo2" />
                    </div>
                    <div className="app-item-text">
                      <h3>ENGINEERING AND DELIVERY</h3>
                      <p className="text-justify">
                        By working with our engineers, architects, and managers
                        to get fast delivery, quality code, and an excellent
                        position in the Apple Store.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="services-app-item">
                    <div className="socal-icons">
                      <img src="../Images/applogo3.png" alt="applogo3" />
                    </div>
                    <div className="app-item-text">
                      <h3>QA AND OPTIMIZATION</h3>
                      <p className="text-justify">
                        We use proper environments and devices to test your
                        product and real usage data to offer improvements,
                        optimization, and scaling.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="services-app-item">
                    <div className="socal-icons">
                      <img src="../Images/applogo4.png" alt="applogo4" />
                    </div>
                    <div className="app-item-text">
                      <h3>Approved IOS Developers</h3>
                      <p className="text-justify">
                        We have a team of experienced developers specializing in
                        iOS development and designated for your project.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="services-app-item">
                    <div className="socal-icons">
                      <img src="../Images/applogo1.png" alt="applogo1" />
                    </div>
                    <div className="app-item-text">
                      <h3>End-to-end service</h3>
                      <p className="text-justify">
                        Our iOS development services include consulting, product
                        strategy, UX / UI development, testing, and technical
                        delivery.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="services-app-item">
                    <div className="socal-icons">
                      <img src="../Images/applogo2.png" alt="applogo2" />
                    </div>
                    <div className="app-item-text">
                      <h3>Deployment of the App Store</h3>
                      <p className="text-justify">
                        Mannix Infotech Solutions Pvt.Ltd. Mobile handles the
                        entire App Store submission process, including listing,
                        description, assets, and more.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className="serve-for-section pb_90">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="services-android-app-title pt-5">
                <div className="section-title pb-5">
                  <h2 className="android_title text-center">
                    Industries we Serve for
                  </h2>
                  <p className="android_sub_title text-center">
                    We help you to leverage the latest technologies of the
                    market.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="Serve-we-boxing">
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <div className="service_left_list">
                  <ul className="text-md-right">
                    <li className="row_reverse d-flex">
                      <div className="text">
                        <h3>Construction</h3>
                        <p className="text-justify">
                          Remote cooperation,Hazard detection, and other handy
                          AR features.
                        </p>
                      </div>
                      <div className="icon">
                        {" "}
                        <i className="innovate_icon1"></i>{" "}
                      </div>
                    </li>

                    <li className="row_reverse d-flex">
                      <div className="text">
                        <h3>Assurance</h3>
                        <p className="text-justify">
                          Management of complaints, alerts, AI, cloud solutions,
                          SOS video, and more.
                        </p>
                      </div>
                      <div className="icon">
                        {" "}
                        <i className="innovate_icon2"></i>{" "}
                      </div>
                    </li>
                    <li className="row_reverse d-flex">
                      <div className="text">
                        <h3>Travel &amp; Hospitality</h3>
                        <p className="text-justify">
                          Smart room opstems, hotel applications, AR &amp; VR,
                          Big Data solutions.
                        </p>
                      </div>
                      <div className="icon">
                        {" "}
                        <i className="innovate_icon3 "></i>{" "}
                      </div>
                    </li>
                    <li className="row_reverse d-flex">
                      <div className="text">
                        <h3>FinTech </h3>
                        <p className="text-justify">
                          Mobile banking, wallet apps, real-time fraud
                          detection, AI chatbots.{" "}
                        </p>
                      </div>
                      <div className="icon">
                        {" "}
                        <i className="innovate_icon4"></i>{" "}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 d-none d-md-block">
                <div className="mobile_screen">
                  {" "}
                  <img src="../Images/iosphone.png" alt="iosphone" />{" "}
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="service_left_list right-02">
                  <ul className="text-md-right">
                    <li className="row_reverse d-flex">
                      <div className="icon">
                        {" "}
                        <i className="innovate_icon5"></i>{" "}
                      </div>
                      <div className="text">
                        <h3>Health care</h3>
                        <p className="text-justify">
                          Notifications, planning, patient monitoring, Big Data
                          solutions, etc.
                        </p>
                      </div>
                    </li>

                    <li className="row_reverse d-flex">
                      <div className="text">
                        <div className="icon">
                          {" "}
                          <i className="innovate_icon6"></i>{" "}
                        </div>
                        <h3>Retail and e-commerce</h3>
                        <p className="text-justify">
                          Gamification, AR, navigation, Big Data, e-commerce
                          platforms.
                        </p>
                      </div>
                    </li>
                    <li className="row_reverse d-flex">
                      <div className="text">
                        <div className="icon">
                          {" "}
                          <i className="innovate_icon7 "></i>{" "}
                        </div>
                        <h3>Sports teams</h3>
                        <p className="text-justify">
                          Smart room opstems, hotel applications, AR & VR, Big
                          Data solutions.
                        </p>
                      </div>
                    </li>
                    <li className="row_reverse d-flex">
                      <div className="text">
                        <div className="icon">
                          {" "}
                          <i className="innovate_icon8"></i>{" "}
                        </div>
                        <h3>IoT applications </h3>
                        <p className="text-justify">
                          Industry-specific mobile solutions that operate in
                          ecoopstems with smart devices.{" "}
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      ;
    </div>
  );
};

export default IOSAppDevelopment;
