import React, { useState } from "react";
import { Helmet } from "react-helmet";

const WordPressDevlopment = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>Best WordPress Development Company in Ahmedabad</title>
        <meta
          name="description"
          content="mannix infotech solution gives trusted WordPress website development solutions  and delivering customized web development solutions to businesses worldwide.
"
        />
        <meta
          name="keywords"
          content="top wordpress development services|advance wordpress development|custom wordpress website|professional wordpress services
"
        />
        <link
          rel="canonical"
          href="https://www.mannix.in/wordpress-website-development-services"
        />
      </Helmet>
      <div className="hire-dedicated services mt-0">
        <section className="banner-content-1">
          <svg
            version="1.1"
            id="Layer_1"
            x="0px"
            y="0px"
            className="position-absolute top-banner-bg"
            viewBox="0 0 1920 761"
          >
            <g>
              <defs>
                <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
              </defs>
              <clipPath id="SVGID_2_">
                <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
              </clipPath>
              <g className="banner-bg-asw">
                <path
                  className="st1"
                  d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                        c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
                ></path>
                <path
                  className="st2"
                  d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                        c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
                ></path>
                <path
                  className="st1"
                  d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
                ></path>
                <path
                  className="st2"
                  d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
                ></path>
                <circle
                  className="st1"
                  cx="1536.8"
                  cy="661.3"
                  r="56.2"
                ></circle>
                <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
              </g>
            </g>
          </svg>
          <div className="py-5 container">
            <div className="row align-items-center justify-content-around mt-4">
              <div className="col-lg-6">
                <div className="">
                  <h1 className="head-title head-title-without-line m-0 py-3">
                    Wordpress Website Development
                  </h1>
                  <p className="m-0 py-3">
                    About 30 to 35% of websites on the Internet, including
                    dozens of Fortune 500 companies. The powerful website
                    builder and robust content management opstem make the
                    platform optimal source so that about 54 out of 100
                    companies of Inc.5000 have Wordpress integrated websites.
                    Whether your goal is to leverage your SEO social media
                    strategies or open-source platform for greater flexibility,
                    freedom and overall higher quality development. Easy to
                    customize, it is an open-source program that dominates over
                    20% of self-hosted websites. Wordpress receives about 20
                    billion pages every month and is still expanding. We
                    implement best practices and build feature-rich, highly
                    functional websites that help owners leverage their business
                    to new heights. From small startups, large corporations,
                    B2B, B2C, we provide Wordpress solutions that are fast and
                    optimized for top-notch performances. Our result drives
                    project management is capable of offering seamless
                    communication and yield advantages in the advanced
                    corporate, internet-facing and hybrid environments.
                  </p>
                </div>
              </div>
              <div className="col-lg-5 prod-section2">
                <div className="">
                  <form
                    className="register-form"
                    id="register-form"
                    method="post"
                    action=""
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="hidden"
                      name="form_type"
                      id="form_type"
                      value="Android App Development"
                    />
                    <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                      <div className="d-flex">
                        <div className="w-100 mr-2">
                          <label className="w-100">
                            <span>Name</span>
                            <input
                              type="text"
                              name="name"
                              id="name"
                              placeholder="Name *"
                              className="form-control"
                              value={formData.name}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                        <div className="w-100">
                          <label className="w-100">
                            <span>Email</span>
                            <input
                              type="email"
                              name="email"
                              id="email"
                              placeholder="Email id *"
                              className="form-control"
                              value={formData.email}
                              onChange={handleChange}
                              required
                            />
                          </label>
                        </div>
                      </div>
                      <div className="d-flex">
                        <label className="w-100">
                          <span>Contact</span>
                          <input
                            type="text"
                            name="phone"
                            id="phone"
                            placeholder="Contact No *"
                            className="form-control"
                            value={formData.phone}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Country</span>
                          <input
                            type="text"
                            name="country"
                            id="country"
                            placeholder="Enter your Country"
                            className="form-control"
                            value={formData.country}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div>
                        <label className="w-100">
                          <span>Message</span>
                          <textarea
                            name="message"
                            id="message"
                            placeholder="Enter your message *"
                            className="form-control"
                            value={formData.message}
                            onChange={handleChange}
                            required
                          ></textarea>
                        </label>
                      </div>

                      <div className="">
                        <button
                          type="submit"
                          name="submit"
                          className="submit btn w-100 g-recaptcha-btn"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="hire-dedicated services pt-5">
        <section className="pt_90 pb-90">
          <div className="hire-dedicated-3 container">
            <div className="pb-5 text-center">
              <h2 className="head-title">
                Our Website Development Team also deliver Web portals, such as
              </h2>
            </div>
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-laptop-code"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">E-learning Portals</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process ">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-shopping-cart"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">E-commerce Portals</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-address-card"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Customer Portals</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-user-shield"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Vendor Portals</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-hand-holding-usd"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">e-booking portals</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-university"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Government Portals</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-user-md"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Healthcare Portal</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="pt-5 pb-90">
          <div className="hire-dedicated-2 container">
            <div className="pb-5 text-center">
              <h2 className="head-title">
                Advantages of using our Website Development Services
              </h2>
            </div>
            <div className="row">
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-bars"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">Dedicated Wordpress Agency</h2>
                      <p className="m-0 py-2">
                        We have a highly qualified, dedicated team that focuses
                        on a single platform and ensures that you get optimal
                        results. We have hand-on-experience with Wordpress and
                        have been creating beautiful, intuitive and integrating
                        it since 2008.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-bars"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fab fa-searchengin"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">Follow Industry Standards</h2>
                      <p className="m-0 py-2">
                        We have hands-on latest technologies and methodologies
                        that ensure that clients get scalable and highly
                        adaptive solutions to meet your business goals, company
                        and customer needs.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fab fa-searchengin"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-child"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">No pre-designed templates</h2>
                      <p className="m-0 py-2">
                        Though off-the-shell apps are appealing, these
                        pre-created templates don't fit everyone. We help you
                        build new capabilities and capacity with off-the-shelf
                        solutions and innovations that drive more ingenious
                        approaches for your business growth.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-child"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-chart-line"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">Seasoned Developers</h2>
                      <p className="m-0 py-2">
                        We have highly experienced developers who have in-depth
                        knowledge and understandings of the platform. Our team
                        can accomplish every project with utter perfection
                        irrespective of complexity.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-chart-line"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-ad"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2">
                        Brand Marketing & Advertising
                      </h2>
                      <p className="m-0 py-2">
                        Our Website Development Team takes care of branding and
                        marketing your business logo and uses various techniques
                        like social media to advertise your brand to the
                        targeted customers. They constantly analyze your
                        competitive brands and ensure your brand availability to
                        your customers.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-ad"></i>
                  </div>
                </div>
              </div>
              <div className=" col-lg-4 col-md-6 pt-4 hire-fields">
                <div className="parent">
                  <div className="normal">
                    <div>
                      <i className="fas fa-user-friends"></i>
                    </div>
                    <div>
                      <h2 className="m-0 py-2"> Committed to Quality</h2>
                      <p className="m-0 py-2">
                        We are committed to delivering professionalism,
                        innovation, passion, and dedication to transform
                        best-quality websites high in performance. Our
                        developers adhere to meet industry standards and develop
                        innovative solutions for clients.
                      </p>
                    </div>
                  </div>
                  <div className="bg-icon">
                    <i className="fas fa-user-friends"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className=" pt-5">
          <div className="hire-dedicated-3 container ">
            <div className="pb-4 text-center">
              <h2 className="head-title">Industries We Serve</h2>
            </div>
            <div className="row pb_90">
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-hiking"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Tours & Travels</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process ">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-book"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Education </h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-utensils"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Food Services</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-taxi"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Taxi Services</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-hospital"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Healthcare Services</h2>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-4 col-sm-6 pt-4 hire-process">
                <div className="d-flex align-items-center justify-content-sm-start">
                  <div>
                    <i className="fas fa-hotel"></i>
                  </div>
                  <div>
                    <h2 className="m-0 py-2">Hotel Business</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>{" "}
      *
    </div>
  );
};

export default WordPressDevlopment;
