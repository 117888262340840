import React, { useState } from "react";
import { Helmet } from "react-helmet";

const CRMSoftwareDevelopment = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    country: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const whatsappUrl = `https://api.whatsapp.com/send?phone=919664053983&text=${encodeURIComponent(
      `Name: ${formData.name}\nEmail: ${formData.email}\nPhone: ${formData.phone}\nCity: ${formData.city}\nJob: ${formData.job}\nCompany: ${formData.company}\nExperience: ${formData.experience}\nExpected CTC: ${formData.ctc}`
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <div>
      <Helmet>
        <title>
          Top CRM Software Development in Ahmedabad | CRM Software in India
        </title>
        <meta
          name="description"
          content=" Streamline customer relationships with Mannix Infotech's custom CRM software development services in Ahmedabad. Our experts design and develop tailored CRM solutions to boost sales, productivity, and customer satisfaction."
        />
        <meta
          name="keywords"
          content="Top CRM Software Development Company  in India |Best CRM Software Development Company in Gujarat |Best CRM Software Development Company in Ahmedabad | List of company CRM Software Development services in Ahmedabad |CRM Software Development services near me |CRM Software Development company near by|CRM Software Development company near me| CRM Software Development"
        />

        <link
          rel="canonical"
          href="https://www.mannix.in/crm-software-development-services"
        />
      </Helmet>
      <section className="banner-content-1">
        <svg
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          className="position-absolute top-banner-bg"
          viewBox="0 0 1920 761"
        >
          <g>
            <defs>
              <rect id="SVGID_1_" y="0.5" width="1920" height="760"></rect>
            </defs>
            <clipPath id="SVGID_2_">
              <use href="#SVGID_1_" style={{ overflow: "visible" }}></use>
            </clipPath>
            <g className="banner-bg-asw">
              <path
                className="st1"
                d="M108.6,241.9l-194.9-79.4c-23.7-9.7-35.1-36.7-25.4-60.4l79.4-194.9c9.7-23.7,36.7-35.1,60.4-25.4L223-38.8
                            c23.7,9.7,35.1,36.7,25.4,60.4L169,216.5C159.4,240.2,132.3,251.6,108.6,241.9z"
              ></path>
              <path
                className="st2"
                d="M94.1,180.4l-69.2,82.8c-19.7,23.5-54.7,26.6-78.2,7l-82.8-69.2c-23.5-19.7-26.6-54.7-7-78.2l69.2-82.8
                            c19.7-23.5,54.7-26.6,78.2-7l82.8,69.2C110.7,121.8,113.8,156.9,94.1,180.4z"
              ></path>
              <path
                className="st1"
                d="M1560.1,0.6l-42,381c0,0-6,61.5,52.5,81l354,132l10.5-603L1560.1,0.6z"
              ></path>
              <path
                className="st2"
                d="M1706.5-2.9l-25.6,232.2c0,0-3.7,37.5,32,49.4l215.7,80.4L1935-8.4L1706.5-2.9z"
              ></path>
              <circle className="st1" cx="1536.8" cy="661.3" r="56.2"></circle>
              <circle className="st2" cx="1406.6" cy="743.1" r="16"></circle>
            </g>
          </g>
        </svg>
        <div className="py-5 container">
          <div className="row align-items-center justify-content-around mt-4">
            <div className="col-lg-6">
              <div className="">
                <h1 className="head-title head-title-without-line m-0 py-3">
                  CRM Software Development
                </h1>
                <p className="m-0 py-3">
                  Mannix Infotech Solutions Pvt.Ltd. focuses on the
                  implementation, development, migration, and support of
                  personalised and scalable customer relationship management
                  solutions. By leveraging CRM software solutions, our clients
                  are able to process customer data and interactions, enjoy a
                  robust workflow within a complex business structure, harness
                  the potential of large-scale automation and to manage the
                  performance of employees and resources.
                  <br />
                  With your critical goals and development needs focused, Mannix
                  Infotech Solutions Pvt.Ltd. consultants and software engineers
                  can implement a custom CRM solution to help you execute even
                  the most ambitious strategies within your organization. In
                  order to help you achieve measurable business results with CRM
                  Development Company and implementation services, we are
                  constantly improving our expertise and skills in CRM solutions
                  for various sectors and industries including pharmacy, finance
                  and healthcare.
                </p>
              </div>
            </div>
            <div className="col-lg-5 prod-section2">
              <div className="">
                <form
                  className="register-form"
                  id="register-form"
                  method="post"
                  action=""
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="form_type"
                    id="form_type"
                    value="Android App Development"
                  />
                  <div className="p-xl-5 px-lg-3 px-sm-5 p-2 py-4 bg-white">
                    <div className="d-flex">
                      <div className="w-100 mr-2">
                        <label className="w-100">
                          <span>Name</span>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            placeholder="Name *"
                            className="form-control"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                      <div className="w-100">
                        <label className="w-100">
                          <span>Email</span>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            placeholder="Email id *"
                            className="form-control"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </label>
                      </div>
                    </div>
                    <div className="d-flex">
                      <label className="w-100">
                        <span>Contact</span>
                        <input
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Contact No *"
                          className="form-control"
                          value={formData.phone}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Country</span>
                        <input
                          type="text"
                          name="country"
                          id="country"
                          placeholder="Enter your Country"
                          className="form-control"
                          value={formData.country}
                          onChange={handleChange}
                          required
                        />
                      </label>
                    </div>
                    <div>
                      <label className="w-100">
                        <span>Message</span>
                        <textarea
                          name="message"
                          id="message"
                          placeholder="Enter your message *"
                          className="form-control"
                          value={formData.message}
                          onChange={handleChange}
                          required
                        ></textarea>
                      </label>
                    </div>

                    <div className="">
                      <button
                        type="submit"
                        name="submit"
                        className="submit btn w-100 g-recaptcha-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="what-we-do mt-5 mb-5" style={{ padding: "0px 15px" }}>
        <div className="container">
          <div className="theme-title text-left">
            <p className="text-justify">
              Customer relationship management (CRM) opstems are used to
              compile, manage, and analyze information about all customer
              interactions across multiple channels in order to personalize the
              customer experience, improve business relationships with
              customers, and enhance customer relationships. Increase the
              efficiency of business operations.
            </p>
          </div>
        </div>
      </section>
      <section className="what-we-do">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 col-sm-7">
              <div className="theme-title text-left">
                <h2>
                  Mannix Infotech Solutions Pvt.Ltd.- custom CRM development
                  service
                </h2>
                <p className="text-justify">
                  Mannix Infotech Solutions Pvt.Ltd. focuses on the
                  implementation, development, migration, and support of
                  personalised and scalable customer relationship management
                  solutions. By leveraging CRM software solutions, our clients
                  are able to process customer data and interactions, enjoy a
                  robust workflow within a complex business structure, harness
                  the potential of large-scale automation and to manage the
                  performance of employees and resources. With your critical
                  goals and development needs focused, Mannix Infotech Solutions
                  Pvt.Ltd. consultants and software engineers can implement a
                  custom CRM solution to help you execute even the most
                  ambitious strategies within your organization. In order to
                  help you achieve measurable business results with CRM
                  development and implementation services, we are constantly
                  improving our expertise and skills in CRM solutions for
                  various sectors and industries including pharmacy, finance and
                  healthcare.
                </p>
                <br />
                <h2>Pharmaceutical CRM</h2>
                <p className="text-justify">
                  CRM for the pharmaceutical industry boosts your success in the
                  dynamic market with the customer-centric services you desire.
                  Pharma CRM streamlines the development of your latest medicine
                  and coverings, organizes optimum method productivity, tracks
                  investments, and aligns with regulatory compliance.
                </p>
                <br />
                <h2>CRM Healthcare</h2>
                <p className="text-justify">
                  Healthcare CRM solutions ensure a seamless experience
                  throughout the customer service lifecycle, from very first
                  contact to subscription, sales and marketing, registration,
                  service delivery. healthcare and customer loyalty.
                </p>
              </div>
            </div>
            <div className="col-sm-5 col-md-5">
              <div className=" imgae-1">
                {" "}
                <img
                  src="../Images/CRMdevelopmentservice.png"
                  alt="CRMdevelopmentservice"
                  className="img-fluid"
                />{" "}
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="company-offer3">
        <div className="container">
          <div className="theme-title">
            <strong>Customer relationship management for finance</strong>
            <p className="text-justify mt-2">
              CRM software for finance permits your organization to
              revolutionize the way it handles all of its important information
              and improve client engagement by applying a personalised approach,
              information integration services and marketing solutions ranging
              from retail investment and insurance business sectors.
            </p>
            <p className="text-justify">
              Benefits of Adopting Custom CRM Development Services.
            </p>
            <ul className="list-style-blt">
              <li>
                Your business will stand out from the crowd along with your
                distinctive solutions.
              </li>
              <li>
                It provides the ability to upgrade or change your business needs
                at any time.
              </li>
              <li>You can scale your opstem with growing business demands.</li>
              <li>
                A personalized customer management solution allows you to pay
                for what you need and use.
              </li>
              <li>
                You need less training on the platform because you already know
                what is in it.
              </li>
              <li>
                Customized customer relationship management platforms are more
                secure because you can apply all the high-end security
                mechanisms you want.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="company-offer3 ">
        <div className="container">
          <div className="theme-title">
            <strong>
              How Does Custom CRM Software Fit Your Business Needs?
            </strong>
            <ul className="list-style-blt">
              <li>
                The CRM opstem is a software tool that conforms to your unique
                needs for customer management, controlling business operations
                and maintaining a solid return on investment (ROI).
              </li>
              <li>
                We develop CRM software applications that organize entire
                volumes of information inside a organisation and so translate it
                into profitable interactions.. A custom CRM opstem connects all
                data sources together to meet customer expectations with an
                emphasis on analytical information when closing deals and
                estimating revenue growth.
              </li>
              <li>
                A Mannix Infotech Solutions Pvt.Ltd. personalised CRM
                development service dedicated to your privacy and most maximum
                security, therefore we offer extremely reliable solutions you'll
                trust.
              </li>
              <li>
                Our customers enjoy enhanced productivity and added efficiency
                benefits through custom CRM software solutions when paired with
                advanced real-time analytics and mobile capabilities.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CRMSoftwareDevelopment;
